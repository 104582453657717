import { useTranslations } from '@vocab/react';
import {
  Heading,
  Rating,
  Stack,
  Strong,
  Text,
  Loader,
} from 'braid-design-system';

import translations from './.vocab';
import { type CompanyProfileRating, formatRating } from '@seek/libs-shared';

type RatingSummaryModel = CompanyProfileRating;

interface Props {
  model?: RatingSummaryModel;
  loading?: boolean;
}

export const RatingSummary = ({ model, loading }: Props) => {
  const { t } = useTranslations(translations);
  if (loading || !model) {
    return <Loader />;
  }
  const value = model.average;
  const numberOfReviews = model.count;
  return (
    <Stack space="medium" align="center">
      <Heading level="1">{formatRating(value, 1)}</Heading>
      <Rating rating={value} variant="starsOnly" size="large" />
      <Text size="small">
        <Strong>{numberOfReviews}</Strong> {t('ratings in total')}
      </Text>
    </Stack>
  );
};
