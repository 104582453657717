
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IkludmFsaWQgd29yayBoaXN0b3J5IiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlNlbGVjdCBhIGN1cnJlbmN5IGFuZCBhbW91bnQiLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiLCJFbnRlciBhIG1vbnRoIGFuZCB5ZWFyIjoiRW50ZXIgYSBtb250aCBhbmQgeWVhciJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IlvDj%2BC4geC4teC5ieG5vcSDxIPEg8aaw6zDrMOsxowgxbXDtsO2w7bFmcS3IOG4qcOsw6zDrMWh4bmvw7bDtsO2xZnDvcO9w71dIiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlvFoOG6veG6veG6vcaa4bq94bq94bq9w6fhua8gxIPEg8SDIMOnx5rHmseaxZnFmeG6veG6veG6veC4geC4teC5icOnw73DvcO9IMSDxIPEg%2BC4geC4teC5icaMIMSDxIPEg23MgsO2w7bDtseax5rHmuC4geC4teC5ieG5r10iLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJbxpHDrMOsw6zguIHguLXguYnDrMOsw6zFoeG4qSDGjMSDxIPEg%2BG5r%2BG6veG6veG6vSBtzILHmseax5rFoeG5ryDDn%2BG6veG6veG6vSDEg8SDxIPGkuG5r%2BG6veG6veG6vcWZIMWh4bmvxIPEg8SDxZnhua8gxozEg8SDxIPhua%2Fhur3hur3hur1dIiwiRW50ZXIgYSBtb250aCBhbmQgeWVhciI6IlvDi%2BC4geC4teC5ieG5r%2BG6veG6veG6vcWZIMSDxIPEgyBtzILDtsO2w7bguIHguLXguYnhua%2FhuKkgxIPEg8SD4LiB4Li14LmJxowgw73DvcO94bq94bq94bq9xIPEg8SDxZldIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJbnZhbGlkIHdvcmsgaGlzdG9yeSI6IlvDj%2BC4geC4teC5ieG5vcSDxIPEg8aaw6zDrMOsxowgxbXDtsO2w7bFmcS3IOG4qcOsw6zDrMWh4bmvw7bDtsO2xZnDvcO9w71dIiwiU2VsZWN0IGEgY3VycmVuY3kgYW5kIGFtb3VudCI6IlvFoOG6veG6veG6vcaa4bq94bq94bq9w6fhua8gxIPEg8SDIMOnx5rHmseaxZnFmeG6veG6veG6veC4geC4teC5icOnw73DvcO9IMSDxIPEg%2BC4geC4teC5icaMIMSDxIPEg23MgsO2w7bDtseax5rHmuC4geC4teC5ieG5r10iLCJGaW5pc2ggZGF0ZSBtdXN0IGJlIGFmdGVyIHN0YXJ0IGRhdGUiOiJbxpHDrMOsw6zguIHguLXguYnDrMOsw6zFoeG4qSDGjMSDxIPEg%2BG5r%2BG6veG6veG6vSBtzILHmseax5rFoeG5ryDDn%2BG6veG6veG6vSDEg8SDxIPGkuG5r%2BG6veG6veG6vcWZIMWh4bmvxIPEg8SDxZnhua8gxozEg8SDxIPhua%2Fhur3hur3hur1dIiwiRW50ZXIgYSBtb250aCBhbmQgeWVhciI6IlvDi%2BC4geC4teC5ieG5r%2BG6veG6veG6vcWZIMSDxIPEgyBtzILDtsO2w7bguIHguLXguYnhua%2FhuKkgxIPEg8SD4LiB4Li14LmJxowgw73DvcO94bq94bq94bq9xIPEg8SDxZldIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    