import {
  Box,
  ButtonLink,
  Heading,
  Inline,
  Stack,
  Text,
} from 'braid-design-system';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import { SuccessHiFiveImage } from './SuccessHiFiveImage';
import {
  buildCandidateCompanyProfilesURI,
  buildCandidateCompanyProfileURI,
  useConfig,
} from '@seek/libs-shared';

export const SubmitSuccessPage = ({
  companyName = '',
  companyId,
}: {
  companyName: string;
  companyId?: string;
}) => {
  const { t } = useTranslations(translations);
  const { environment, country, language } = useConfig();

  const url = companyId
    ? buildCandidateCompanyProfileURI({
        companyName,
        companyId,
        country,
        language,
        isStaging: environment === 'staging',
      })
    : buildCandidateCompanyProfilesURI({
        country,
        language,
        isStaging: environment === 'staging',
      });

  const handleClick = () => {
    // TODO: track event
  };

  return (
    <Box
      data={{ testid: 'submit-success-page' }}
      paddingTop={{
        tablet: 'xxlarge',
        mobile: 'large',
      }}
    >
      <Stack space="xlarge" align="center">
        <Heading level="1">{t('Thank you for your review')}</Heading>
        <Text size="standard">
          {t(
            'Youve helped other people understand what its like to work at {companyName} by sharing your experience.',
            { companyName },
          )}
        </Text>

        <SuccessHiFiveImage />

        <Inline space="medium" collapseBelow="tablet">
          {companyId ? (
            <ButtonLink
              variant="solid"
              tone="formAccent"
              onClick={handleClick}
              href={url}
              data={{ testid: 'go-to-company-profile' }}
            >
              {t('Go to company profile')}
            </ButtonLink>
          ) : (
            <ButtonLink
              variant="solid"
              tone="formAccent"
              onClick={handleClick}
              href={url}
              data={{ testid: 'go-to-company-profiles' }}
            >
              {t('Go to company profiles')}
            </ButtonLink>
          )}
        </Inline>
      </Stack>
    </Box>
  );
};
