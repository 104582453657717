import { Dropdown } from 'braid-design-system';
import type { QuestionProps } from '../../type';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';

export const WorkTypeQuestion = ({
  value = '',
  message = '',
  tone,
  onChange,
}: QuestionProps) => {
  const { t } = useTranslations(translations);
  return (
    <Dropdown
      id="workType"
      label={t('Work Type')}
      value={value}
      onChange={(event) => onChange(event.currentTarget.value)}
      tone={tone}
      message={message}
      reserveMessageSpace={true}
    >
      <option value="Full Time">{t('Full time')}</option>
      <option value="Part Time">{t('Part time')}</option>
      <option value="Contract / Temporary">{t('Contract/Temp')}</option>
      <option value="Volunteer / Intern">{t('Volunteer/Intern')}</option>
    </Dropdown>
  );
};
