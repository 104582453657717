
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJGdWxsIHRpbWUiLCJQYXJ0IHRpbWUiOiJQYXJ0IHRpbWUiLCJDb250cmFjdC9UZW1wIjoiQ29udHJhY3QvVGVtcCIsIlZvbHVudGVlci9JbnRlcm4iOiJWb2x1bnRlZXIvSW50ZXJuIiwiVGhlIGNoYWxsZW5nZXMiOiJUaGUgY2hhbGxlbmdlcyIsIkRlc2NyaWJlIHdoYXQgeW91IGRvbid0IGxpa2UiOiJEZXNjcmliZSB3aGF0IHlvdSBkb24ndCBsaWtlIiwiVGhlIGdvb2QgdGhpbmdzIjoiVGhlIGdvb2QgdGhpbmdzIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IkRlc2NyaWJlIHdoYXQgeW91IGxpa2UgdGhlIG1vc3QiLCJTdW1tYXJpc2UgeW91ciBleHBlcmllbmNlIGluIG9uZSBzZW50ZW5jZSI6IlN1bW1hcmlzZSB5b3VyIGV4cGVyaWVuY2UgaW4gb25lIHNlbnRlbmNlIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IiwiV29yayBUeXBlIjoiV29yayBUeXBlIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJbxpHHmseax5rGmsaaIOG5r8Osw6zDrG3MguG6veG6veG6vV0iLCJQYXJ0IHRpbWUiOiJbxqTEg8SDxIPFmeG5ryDhua%2FDrMOsw6xtzILhur3hur3hur1dIiwiQ29udHJhY3QvVGVtcCI6IlvDh8O2w7bDtuC4geC4teC5ieG5r8WZxIPEg8SDw6fhua8v4bmu4bq94bq94bq9bcyCxqVdIiwiVm9sdW50ZWVyL0ludGVybiI6IlvhubzDtsO2w7bGmseax5rHmuC4geC4teC5ieG5r%2BG6veG6veG6veG6veG6veG6vcWZL8OP4LiB4Li14LmJ4bmv4bq94bq94bq9xZnguIHguLXguYldIiwiVGhlIGNoYWxsZW5nZXMiOiJb4bmu4bip4bq94bq94bq9IMOn4bipxIPEg8SDxprGmuG6veG6veG6veC4geC4teC5icSj4bq94bq94bq9xaFdIiwiRGVzY3JpYmUgd2hhdCB5b3UgZG9uJ3QgbGlrZSI6IlvhuIrhur3hur3hur3FocOnxZnDrMOsw6zDn%2BG6veG6veG6vSDFteG4qcSDxIPEg%2BG5ryDDvcO9w73DtsO2w7bHmseax5ogxozDtsO2w7bguIHguLXguYkn4bmvIMaaw6zDrMOsxLfhur3hur3hur1dIiwiVGhlIGdvb2QgdGhpbmdzIjoiW%2BG5ruG4qeG6veG6veG6vSDEo8O2w7bDtsO2w7bDtsaMIOG5r%2BG4qcOsw6zDrOC4geC4teC5icSjxaFdIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IlvhuIrhur3hur3hur3FocOnxZnDrMOsw6zDn%2BG6veG6veG6vSDFteG4qcSDxIPEg%2BG5ryDDvcO9w73DtsO2w7bHmseax5ogxprDrMOsw6zEt%2BG6veG6veG6vSDhua%2FhuKnhur3hur3hur0gbcyCw7bDtsO2xaHhua9dIiwiU3VtbWFyaXNlIHlvdXIgZXhwZXJpZW5jZSBpbiBvbmUgc2VudGVuY2UiOiJbxaDHmseabcyCbcyCxIPEg8WZw6zDrMWh4bq94bq9IMO9w73DtsO2x5rHmsWZIOG6veG6veqtlcal4bq94bq9xZnDrMOs4bq94bq94LiB4Li14LmJw6fhur3hur0gw6zDrOC4geC4teC5iSDDtsO24LiB4Li14LmJ4bq94bq9IMWh4bq94bq94LiB4Li14LmJ4bmv4bq94bq94LiB4Li14LmJw6fhur3hur1dIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiW%2BG5ruG4qcOsw6zDrMWhIMW1w6zDrMOsxprGmiDDn%2BG6veG6veG6vSDHmseax5rFoeG6veG6veG6vcaMIMSDxIPEg8WhIOG5r%2BG4qeG6veG6veG6vSDhua%2FDrMOsw6zhua%2FGmuG6veG6veG6vSDDtsO2w7bGkiDDvcO9w73DtsO2w7bHmseax5rFmSDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1XSIsIldvcmsgVHlwZSI6IlvFtMO2w7bDtsWZxLcg4bmuw73DvcO9xqXhur3hur3hur1dIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJGdWxsIHRpbWUiOiJbxpHHmseax5rGmsaaIOG5r8Osw6zDrG3MguG6veG6veG6vV0iLCJQYXJ0IHRpbWUiOiJbxqTEg8SDxIPFmeG5ryDhua%2FDrMOsw6xtzILhur3hur3hur1dIiwiQ29udHJhY3QvVGVtcCI6IlvDh8O2w7bDtuC4geC4teC5ieG5r8WZxIPEg8SDw6fhua8v4bmu4bq94bq94bq9bcyCxqVdIiwiVm9sdW50ZWVyL0ludGVybiI6IlvhubzDtsO2w7bGmseax5rHmuC4geC4teC5ieG5r%2BG6veG6veG6veG6veG6veG6vcWZL8OP4LiB4Li14LmJ4bmv4bq94bq94bq9xZnguIHguLXguYldIiwiVGhlIGNoYWxsZW5nZXMiOiJb4bmu4bip4bq94bq94bq9IMOn4bipxIPEg8SDxprGmuG6veG6veG6veC4geC4teC5icSj4bq94bq94bq9xaFdIiwiRGVzY3JpYmUgd2hhdCB5b3UgZG9uJ3QgbGlrZSI6IlvhuIrhur3hur3hur3FocOnxZnDrMOsw6zDn%2BG6veG6veG6vSDFteG4qcSDxIPEg%2BG5ryDDvcO9w73DtsO2w7bHmseax5ogxozDtsO2w7bguIHguLXguYkn4bmvIMaaw6zDrMOsxLfhur3hur3hur1dIiwiVGhlIGdvb2QgdGhpbmdzIjoiW%2BG5ruG4qeG6veG6veG6vSDEo8O2w7bDtsO2w7bDtsaMIOG5r%2BG4qcOsw6zDrOC4geC4teC5icSjxaFdIiwiRGVzY3JpYmUgd2hhdCB5b3UgbGlrZSB0aGUgbW9zdCI6IlvhuIrhur3hur3hur3FocOnxZnDrMOsw6zDn%2BG6veG6veG6vSDFteG4qcSDxIPEg%2BG5ryDDvcO9w73DtsO2w7bHmseax5ogxprDrMOsw6zEt%2BG6veG6veG6vSDhua%2FhuKnhur3hur3hur0gbcyCw7bDtsO2xaHhua9dIiwiU3VtbWFyaXNlIHlvdXIgZXhwZXJpZW5jZSBpbiBvbmUgc2VudGVuY2UiOiJbxaDHmseabcyCbcyCxIPEg8WZw6zDrMWh4bq94bq9IMO9w73DtsO2x5rHmsWZIOG6veG6veqtlcal4bq94bq9xZnDrMOs4bq94bq94LiB4Li14LmJw6fhur3hur0gw6zDrOC4geC4teC5iSDDtsO24LiB4Li14LmJ4bq94bq9IMWh4bq94bq94LiB4Li14LmJ4bmv4bq94bq94LiB4Li14LmJw6fhur3hur1dIiwiVGhpcyB3aWxsIGJlIHVzZWQgYXMgdGhlIHRpdGxlIG9mIHlvdXIgcmV2aWV3IjoiW%2BG5ruG4qcOsw6zDrMWhIMW1w6zDrMOsxprGmiDDn%2BG6veG6veG6vSDHmseax5rFoeG6veG6veG6vcaMIMSDxIPEg8WhIOG5r%2BG4qeG6veG6veG6vSDhua%2FDrMOsw6zhua%2FGmuG6veG6veG6vSDDtsO2w7bGkiDDvcO9w73DtsO2w7bHmseax5rFmSDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1XSIsIldvcmsgVHlwZSI6IlvFtMO2w7bDtsWZxLcg4bmuw73DvcO9xqXhur3hur3hur1dIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    