import { Helmet } from 'react-helmet';
import { mapToJsonLd } from './mapper';
import { ReviewRowModel } from '@seek/libs-shared';

export interface SeoStructuredData {
  name: string;
  ratingCount: number;
  ratingValue: number;
  url?: string;
  logo?: string;
  location?: string;
  reviews?: ReviewRowModel[];
}
interface SeoStructuredDataProps {
  data?: SeoStructuredData;
}

export const SeoStructuredData = ({ data }: SeoStructuredDataProps) => {
  if (!data) {
    return null;
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(mapToJsonLd(data))}
      </script>
    </Helmet>
  );
};
