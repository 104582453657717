import type { RoleService, RoleTitle } from './roleService';

export const createRoleServiceMock = (): RoleService => {
  const getRoleTitles = async (
    query: string,
    _sessionId: string,
  ): Promise<RoleTitle[]> => {
    return [
      {
        id: '0',
        normalisedText: query,
      },
      {
        id: '1',
        normalisedText: 'Software Engineer',
      },
      {
        id: '2',
        normalisedText: 'Software Developer',
      },
      {
        id: '3',
        normalisedText: 'Software Architect',
      },
    ];
  };

  return { getRoleTitles };
};
