
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IuC5gOC4guC4teC4ouC4meC4o%2BC4teC4p%2BC4tOC4pyIsInRvdGFsIHJhdGluZyBmcm9tIjoi4LiE4Liw4LmB4LiZ4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiU4LiI4Liy4LiBIiwicmV2aWV3cyI6IuC4o%2BC4teC4p%2BC4tOC4pyJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IuC5gOC4guC4teC4ouC4meC4o%2BC4teC4p%2BC4tOC4pyIsInRvdGFsIHJhdGluZyBmcm9tIjoi4LiE4Liw4LmB4LiZ4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiU4LiI4Liy4LiBIiwicmV2aWV3cyI6IuC4o%2BC4teC4p%2BC4tOC4pyJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IlR1bGlzIHVsYXNhbiIsInRvdGFsIHJhdGluZyBmcm9tIjoidG90YWwgcGVuaWxhaWFuIGRhcmkiLCJyZXZpZXdzIjoidWxhc2FuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IlR1bGlzIHVsYXNhbiIsInRvdGFsIHJhdGluZyBmcm9tIjoidG90YWwgcGVuaWxhaWFuIGRhcmkiLCJyZXZpZXdzIjoidWxhc2FuIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IuC5gOC4guC4teC4ouC4meC4o%2BC4teC4p%2BC4tOC4pyIsInRvdGFsIHJhdGluZyBmcm9tIjoi4LiE4Liw4LmB4LiZ4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiU4LiI4Liy4LiBIiwicmV2aWV3cyI6IuC4o%2BC4teC4p%2BC4tOC4pyJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IuC5gOC4guC4teC4ouC4meC4o%2BC4teC4p%2BC4tOC4pyIsInRvdGFsIHJhdGluZyBmcm9tIjoi4LiE4Liw4LmB4LiZ4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiU4LiI4Liy4LiBIiwicmV2aWV3cyI6IuC4o%2BC4teC4p%2BC4tOC4pyJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IlR1bGlzIHVsYXNhbiIsInRvdGFsIHJhdGluZyBmcm9tIjoidG90YWwgcGVuaWxhaWFuIGRhcmkiLCJyZXZpZXdzIjoidWxhc2FuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IlR1bGlzIHVsYXNhbiIsInRvdGFsIHJhdGluZyBmcm9tIjoidG90YWwgcGVuaWxhaWFuIGRhcmkiLCJyZXZpZXdzIjoidWxhc2FuIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IldyaXRlIGEgcmV2aWV3IiwidG90YWwgcmF0aW5nIGZyb20iOiJ0b3RhbCByYXRpbmcgZnJvbSIsInJldmlld3MiOiJyZXZpZXdzIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IlvFtMWZw6zDrOG5r%2BG6veG6vSDEg8SDIMWZ4bq94bq94bm9w6zDrOG6veG6vcW1XSIsInRvdGFsIHJhdGluZyBmcm9tIjoiW%2BG5r8O2w7bDtuG5r8SDxIPEg8aaIMWZxIPEg8SD4bmvw6zDrMOs4LiB4Li14LmJxKMgxpLFmcO2w7bDtm3Mgl0iLCJyZXZpZXdzIjoiW8WZ4bq94bq94bm9w6zDrOG6veG6vcW1xaFdIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXcml0ZSBhIHJldmlldyI6IlvFtMWZw6zDrOG5r%2BG6veG6vSDEg8SDIMWZ4bq94bq94bm9w6zDrOG6veG6vcW1XSIsInRvdGFsIHJhdGluZyBmcm9tIjoiW%2BG5r8O2w7bDtuG5r8SDxIPEg8aaIMWZxIPEg8SD4bmvw6zDrMOs4LiB4Li14LmJxKMgxpLFmcO2w7bDtm3Mgl0iLCJyZXZpZXdzIjoiW8WZ4bq94bq94bm9w6zDrOG6veG6vcW1xaFdIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    