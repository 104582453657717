
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5Ijoi4LmA4Lir4LmH4LiZ4Lig4Liy4Lie4Lij4Lin4Lih4LiX4Li14LmI4LiE4Lij4Lia4Liq4Lih4Lia4Li54Lij4LiT4LmM4LiB4LmI4Lit4LiZ4Liq4Lih4Lix4LiE4LijIiwiQ3VsdHVyZSBhbmQgdmFsdWVzIjoi4Lin4Lix4LiS4LiZ4LiY4Lij4Lij4Lih4LmB4Lil4Liw4LiE4LmI4Liy4LiZ4Li04Lii4LihIiwiRmluZCBvdXQgYWJvdXQgdGhlIGNvbXBhbnkgY3VsdHVyZSI6IuC4lOC4ueC4guC5ieC4reC4oeC4ueC4peC5gOC4geC4teC5iOC4ouC4p%2BC4geC4seC4muC4p%2BC4seC4kuC4meC4mOC4o%2BC4o%2BC4oeC4muC4o%2BC4tOC4qeC4seC4lyIsIlJhdGluZ3MgYW5kIHJldmlld3MiOiLguIHguLLguKPguYPguKvguYnguITguLDguYHguJnguJnguYHguKXguLDguKPguLXguKfguLTguKciLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiLguK3guYjguLLguJnguKPguLXguKfguLTguKfguIjguLLguIHguJ7guJnguLHguIHguIfguLLguJkiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiLguJzguKXguJvguKPguLDguYLguKLguIrguJnguYzguYDguJ7guLTguYjguKHguYDguJXguLTguKHguYHguKXguLDguKrguLTguJfguJjguLTguJvguKPguLDguYLguKLguIrguJnguYwiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IuC4hOC5ieC4meC4q%2BC4suC4nOC4peC4leC4reC4muC5geC4l%2BC4meC5gOC4nuC4tOC5iOC4oeC5gOC4leC4tOC4oeC4l%2BC4teC5iOC4oeC4teC4hOC4p%2BC4suC4oeC4q%2BC4oeC4suC4ouC4quC4s%2BC4q%2BC4o%2BC4seC4muC4hOC4uOC4kyIsIkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiOiLguILguYnguK3guKHguLnguKXguKrguLPguKvguKPguLHguJrguJnguLLguKLguIjguYnguLLguIciLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiLguJTguLnguYHguJnguKfguJfguLLguIfguJvguI%2FguLTguJrguLHguJXguLTguILguK3guIfguIrguLjguKHguIrguJkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5Ijoi4LmA4Lir4LmH4LiZ4Lig4Liy4Lie4Lij4Lin4Lih4LiX4Li14LmI4LiE4Lij4Lia4Liq4Lih4Lia4Li54Lij4LiT4LmM4LiB4LmI4Lit4LiZ4Liq4Lih4Lix4LiE4LijIiwiQ3VsdHVyZSBhbmQgdmFsdWVzIjoi4Lin4Lix4LiS4LiZ4LiY4Lij4Lij4Lih4LmB4Lil4Liw4LiE4LmI4Liy4LiZ4Li04Lii4LihIiwiRmluZCBvdXQgYWJvdXQgdGhlIGNvbXBhbnkgY3VsdHVyZSI6IuC4lOC4ueC4guC5ieC4reC4oeC4ueC4peC5gOC4geC4teC5iOC4ouC4p%2BC4geC4seC4muC4p%2BC4seC4kuC4meC4mOC4o%2BC4o%2BC4oeC4muC4o%2BC4tOC4qeC4seC4lyIsIlJhdGluZ3MgYW5kIHJldmlld3MiOiLguIHguLLguKPguYPguKvguYnguITguLDguYHguJnguJnguYHguKXguLDguKPguLXguKfguLTguKciLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiLguK3guYjguLLguJnguKPguLXguKfguLTguKfguIjguLLguIHguJ7guJnguLHguIHguIfguLLguJkiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiLguJzguKXguJvguKPguLDguYLguKLguIrguJnguYzguYDguJ7guLTguYjguKHguYDguJXguLTguKHguYHguKXguLDguKrguLTguJfguJjguLTguJvguKPguLDguYLguKLguIrguJnguYwiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IuC4hOC5ieC4meC4q%2BC4suC4nOC4peC4leC4reC4muC5geC4l%2BC4meC5gOC4nuC4tOC5iOC4oeC5gOC4leC4tOC4oeC4l%2BC4teC5iOC4oeC4teC4hOC4p%2BC4suC4oeC4q%2BC4oeC4suC4ouC4quC4s%2BC4q%2BC4o%2BC4seC4muC4hOC4uOC4kyIsIkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiOiLguILguYnguK3guKHguLnguKXguKrguLPguKvguKPguLHguJrguJnguLLguKLguIjguYnguLLguIciLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiLguJTguLnguYHguJnguKfguJfguLLguIfguJvguI%2FguLTguJrguLHguJXguLTguILguK3guIfguIrguLjguKHguIrguJkifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiRGFwYXRrYW4gZ2FtYmFyYW4geWFuZyBqZWxhcyBzZWJlbHVtIG1lbGFtYXIiLCJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJCdWRheWEgZGFuIG5pbGFpIiwiRmluZCBvdXQgYWJvdXQgdGhlIGNvbXBhbnkgY3VsdHVyZSI6IkNhcmkgdGFodSB0ZW50YW5nIGJ1ZGF5YSBwZXJ1c2FoYWFuIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlBlbmlsYWlhbiBkYW4gdWxhc2FuIiwiUmVhZCByZXZpZXdzIGZyb20gZW1wbG95ZWVzIjoiQmFjYSB1bGFzYW4gZGFyaSBrYXJ5YXdhbiIsIlBlcmtzIGFuZCBiZW5lZml0cyI6IlR1bmphbmdhbiBkYW4ga2V1bnR1bmdhbiIsIkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IjoiVGVtdWthbiBrZXVudHVuZ2FuIHlhbmcgcGVudGluZyBiYWdpIEFuZGEiLCJJbmZvcm1hdGlvbiBmb3IgZW1wbG95ZXJzIjoiSW5mb3JtYXNpIHVudHVrIHBlcnVzYWhhYW4iLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJMaWhhdCBwZWRvbWFuIGtvbXVuaXRhcyJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiRGFwYXRrYW4gZ2FtYmFyYW4geWFuZyBqZWxhcyBzZWJlbHVtIG1lbGFtYXIiLCJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJCdWRheWEgZGFuIG5pbGFpIiwiRmluZCBvdXQgYWJvdXQgdGhlIGNvbXBhbnkgY3VsdHVyZSI6IkNhcmkgdGFodSB0ZW50YW5nIGJ1ZGF5YSBwZXJ1c2FoYWFuIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlBlbmlsYWlhbiBkYW4gdWxhc2FuIiwiUmVhZCByZXZpZXdzIGZyb20gZW1wbG95ZWVzIjoiQmFjYSB1bGFzYW4gZGFyaSBrYXJ5YXdhbiIsIlBlcmtzIGFuZCBiZW5lZml0cyI6IlR1bmphbmdhbiBkYW4ga2V1bnR1bmdhbiIsIkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IjoiVGVtdWthbiBrZXVudHVuZ2FuIHlhbmcgcGVudGluZyBiYWdpIEFuZGEiLCJJbmZvcm1hdGlvbiBmb3IgZW1wbG95ZXJzIjoiSW5mb3JtYXNpIHVudHVrIHBlcnVzYWhhYW4iLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJMaWhhdCBwZWRvbWFuIGtvbXVuaXRhcyJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5Ijoi4LmA4Lir4LmH4LiZ4Lig4Liy4Lie4Lij4Lin4Lih4LiX4Li14LmI4LiE4Lij4Lia4Liq4Lih4Lia4Li54Lij4LiT4LmM4LiB4LmI4Lit4LiZ4Liq4Lih4Lix4LiE4LijIiwiQ3VsdHVyZSBhbmQgdmFsdWVzIjoi4Lin4Lix4LiS4LiZ4LiY4Lij4Lij4Lih4LmB4Lil4Liw4LiE4LmI4Liy4LiZ4Li04Lii4LihIiwiRmluZCBvdXQgYWJvdXQgdGhlIGNvbXBhbnkgY3VsdHVyZSI6IuC4lOC4ueC4guC5ieC4reC4oeC4ueC4peC5gOC4geC4teC5iOC4ouC4p%2BC4geC4seC4muC4p%2BC4seC4kuC4meC4mOC4o%2BC4o%2BC4oeC4muC4o%2BC4tOC4qeC4seC4lyIsIlJhdGluZ3MgYW5kIHJldmlld3MiOiLguIHguLLguKPguYPguKvguYnguITguLDguYHguJnguJnguYHguKXguLDguKPguLXguKfguLTguKciLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiLguK3guYjguLLguJnguKPguLXguKfguLTguKfguIjguLLguIHguJ7guJnguLHguIHguIfguLLguJkiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiLguJzguKXguJvguKPguLDguYLguKLguIrguJnguYzguYDguJ7guLTguYjguKHguYDguJXguLTguKHguYHguKXguLDguKrguLTguJfguJjguLTguJvguKPguLDguYLguKLguIrguJnguYwiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IuC4hOC5ieC4meC4q%2BC4suC4nOC4peC4leC4reC4muC5geC4l%2BC4meC5gOC4nuC4tOC5iOC4oeC5gOC4leC4tOC4oeC4l%2BC4teC5iOC4oeC4teC4hOC4p%2BC4suC4oeC4q%2BC4oeC4suC4ouC4quC4s%2BC4q%2BC4o%2BC4seC4muC4hOC4uOC4kyIsIkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiOiLguILguYnguK3guKHguLnguKXguKrguLPguKvguKPguLHguJrguJnguLLguKLguIjguYnguLLguIciLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiLguJTguLnguYHguJnguKfguJfguLLguIfguJvguI%2FguLTguJrguLHguJXguLTguILguK3guIfguIrguLjguKHguIrguJkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5Ijoi4LmA4Lir4LmH4LiZ4Lig4Liy4Lie4Lij4Lin4Lih4LiX4Li14LmI4LiE4Lij4Lia4Liq4Lih4Lia4Li54Lij4LiT4LmM4LiB4LmI4Lit4LiZ4Liq4Lih4Lix4LiE4LijIiwiQ3VsdHVyZSBhbmQgdmFsdWVzIjoi4Lin4Lix4LiS4LiZ4LiY4Lij4Lij4Lih4LmB4Lil4Liw4LiE4LmI4Liy4LiZ4Li04Lii4LihIiwiRmluZCBvdXQgYWJvdXQgdGhlIGNvbXBhbnkgY3VsdHVyZSI6IuC4lOC4ueC4guC5ieC4reC4oeC4ueC4peC5gOC4geC4teC5iOC4ouC4p%2BC4geC4seC4muC4p%2BC4seC4kuC4meC4mOC4o%2BC4o%2BC4oeC4muC4o%2BC4tOC4qeC4seC4lyIsIlJhdGluZ3MgYW5kIHJldmlld3MiOiLguIHguLLguKPguYPguKvguYnguITguLDguYHguJnguJnguYHguKXguLDguKPguLXguKfguLTguKciLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiLguK3guYjguLLguJnguKPguLXguKfguLTguKfguIjguLLguIHguJ7guJnguLHguIHguIfguLLguJkiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiLguJzguKXguJvguKPguLDguYLguKLguIrguJnguYzguYDguJ7guLTguYjguKHguYDguJXguLTguKHguYHguKXguLDguKrguLTguJfguJjguLTguJvguKPguLDguYLguKLguIrguJnguYwiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IuC4hOC5ieC4meC4q%2BC4suC4nOC4peC4leC4reC4muC5geC4l%2BC4meC5gOC4nuC4tOC5iOC4oeC5gOC4leC4tOC4oeC4l%2BC4teC5iOC4oeC4teC4hOC4p%2BC4suC4oeC4q%2BC4oeC4suC4ouC4quC4s%2BC4q%2BC4o%2BC4seC4muC4hOC4uOC4kyIsIkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiOiLguILguYnguK3guKHguLnguKXguKrguLPguKvguKPguLHguJrguJnguLLguKLguIjguYnguLLguIciLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiLguJTguLnguYHguJnguKfguJfguLLguIfguJvguI%2FguLTguJrguLHguJXguLTguILguK3guIfguIrguLjguKHguIrguJkifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiRGFwYXRrYW4gZ2FtYmFyYW4geWFuZyBqZWxhcyBzZWJlbHVtIG1lbGFtYXIiLCJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJCdWRheWEgZGFuIG5pbGFpIiwiRmluZCBvdXQgYWJvdXQgdGhlIGNvbXBhbnkgY3VsdHVyZSI6IkNhcmkgdGFodSB0ZW50YW5nIGJ1ZGF5YSBwZXJ1c2FoYWFuIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlBlbmlsYWlhbiBkYW4gdWxhc2FuIiwiUmVhZCByZXZpZXdzIGZyb20gZW1wbG95ZWVzIjoiQmFjYSB1bGFzYW4gZGFyaSBrYXJ5YXdhbiIsIlBlcmtzIGFuZCBiZW5lZml0cyI6IlR1bmphbmdhbiBkYW4ga2V1bnR1bmdhbiIsIkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IjoiVGVtdWthbiBrZXVudHVuZ2FuIHlhbmcgcGVudGluZyBiYWdpIEFuZGEiLCJJbmZvcm1hdGlvbiBmb3IgZW1wbG95ZXJzIjoiSW5mb3JtYXNpIHVudHVrIHBlcnVzYWhhYW4iLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJMaWhhdCBwZWRvbWFuIGtvbXVuaXRhcyJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiRGFwYXRrYW4gZ2FtYmFyYW4geWFuZyBqZWxhcyBzZWJlbHVtIG1lbGFtYXIiLCJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJCdWRheWEgZGFuIG5pbGFpIiwiRmluZCBvdXQgYWJvdXQgdGhlIGNvbXBhbnkgY3VsdHVyZSI6IkNhcmkgdGFodSB0ZW50YW5nIGJ1ZGF5YSBwZXJ1c2FoYWFuIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlBlbmlsYWlhbiBkYW4gdWxhc2FuIiwiUmVhZCByZXZpZXdzIGZyb20gZW1wbG95ZWVzIjoiQmFjYSB1bGFzYW4gZGFyaSBrYXJ5YXdhbiIsIlBlcmtzIGFuZCBiZW5lZml0cyI6IlR1bmphbmdhbiBkYW4ga2V1bnR1bmdhbiIsIkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IjoiVGVtdWthbiBrZXVudHVuZ2FuIHlhbmcgcGVudGluZyBiYWdpIEFuZGEiLCJJbmZvcm1hdGlvbiBmb3IgZW1wbG95ZXJzIjoiSW5mb3JtYXNpIHVudHVrIHBlcnVzYWhhYW4iLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJMaWhhdCBwZWRvbWFuIGtvbXVuaXRhcyJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiR2V0IHRoZSBmdWxsIHBpY3R1cmUgYmVmb3JlIHlvdSBhcHBseSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IkN1bHR1cmUgYW5kIHZhbHVlcyIsIkZpbmQgb3V0IGFib3V0IHRoZSBjb21wYW55IGN1bHR1cmUiOiJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIiwiUmF0aW5ncyBhbmQgcmV2aWV3cyI6IlJhdGluZ3MgYW5kIHJldmlld3MiLCJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiOiJSZWFkIHJldmlld3MgZnJvbSBlbXBsb3llZXMiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IkZpbmQgcGVya3MgdGhhdCBtYXR0ZXIgdG8geW91IiwiSW5mb3JtYXRpb24gZm9yIGVtcGxveWVycyI6IkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiLCJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMiOiJTZWUgY29tbXVuaXR5IGd1aWRlbGluZXMifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiW8em4bq94bq94bq94bmvIOG5r%2BG4qeG6veG6veG6vSDGkseax5rHmsaaxpogxqXDrMOsw6zDp%2BG5r8eax5rHmsWZ4bq94bq94bq9IMOf4bq94bq94bq9xpLDtsO2w7bFmeG6veG6veG6vSDDvcO9w73DtsO2w7bHmseax5ogxIPEg8SDxqXGpcaaw73DvcO9XSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IlvDh8eax5rHmsaa4bmvx5rHmseaxZnhur3hur3hur0gxIPEg8SD4LiB4Li14LmJxowg4bm9xIPEg8SDxprHmseax5rhur3hur3hur3FoV0iLCJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIjoiW8aRw6zDrMOs4LiB4Li14LmJxowgw7bDtsO2x5rHmsea4bmvIMSDxIPEg8Ofw7bDtsO2x5rHmsea4bmvIOG5r%2BG4qeG6veG6veG6vSDDp8O2w7bDtm3MgsalxIPEg8SD4LiB4Li14LmJw73DvcO9IMOnx5rHmseaxprhua%2FHmseax5rFmeG6veG6veG6vV0iLCJSYXRpbmdzIGFuZCByZXZpZXdzIjoiW8WYxIPEg8SD4bmvw6zDrMOs4LiB4Li14LmJxKPFoSDEg8SDxIPguIHguLXguYnGjCDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1xaFdIiwiUmVhZCByZXZpZXdzIGZyb20gZW1wbG95ZWVzIjoiW8WY4bq94bq9xIPEg8aMIMWZ4bq94bq94bm9w6zDrOG6veG6vcW1xaEgxpLFmcO2w7ZtzIIg4bq94bq9bcyCxqXGmsO2w7bDvcO94bq94bq94bq94bq9xaFdIiwiUGVya3MgYW5kIGJlbmVmaXRzIjoiW8ak4bq94bq94bq9xZnEt8WhIMSDxIPEg%2BC4geC4teC5icaMIMOf4bq94bq94bq94LiB4Li14LmJ4bq94bq94bq9xpLDrMOsw6zhua%2FFoV0iLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IlvGkcOsw6zDrOC4geC4teC5icaMIMal4bq94bq94bq9xZnEt8WhIOG5r%2BG4qcSDxIPEg%2BG5ryBtzILEg8SDxIPhua%2Fhua%2Fhur3hur3hur3FmSDhua%2FDtsO2w7Ygw73DvcO9w7bDtsO2x5rHmseaXSIsIkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiOiJbw4%2FguIHguLXguYnGksO2w7bDtsWZbcyCxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIMaSw7bDtsO2xZkg4bq94bq94bq9bcyCxqXGmsO2w7bDtsO9w73DveG6veG6veG6vcWZxaFdIiwiU2VlIGNvbW11bml0eSBndWlkZWxpbmVzIjoiW8Wg4bq94bq94bq94bq9IMOnw7bDtm3Mgm3Mgseax5rguIHguLXguYnDrMOs4bmvw73DvSDEo8eax5rDrMOsxozhur3hur3GmsOsw6zguIHguLXguYnhur3hur3FoV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgdGhlIGZ1bGwgcGljdHVyZSBiZWZvcmUgeW91IGFwcGx5IjoiW8em4bq94bq94bq94bmvIOG5r%2BG4qeG6veG6veG6vSDGkseax5rHmsaaxpogxqXDrMOsw6zDp%2BG5r8eax5rHmsWZ4bq94bq94bq9IMOf4bq94bq94bq9xpLDtsO2w7bFmeG6veG6veG6vSDDvcO9w73DtsO2w7bHmseax5ogxIPEg8SDxqXGpcaaw73DvcO9XSIsIkN1bHR1cmUgYW5kIHZhbHVlcyI6IlvDh8eax5rHmsaa4bmvx5rHmseaxZnhur3hur3hur0gxIPEg8SD4LiB4Li14LmJxowg4bm9xIPEg8SDxprHmseax5rhur3hur3hur3FoV0iLCJGaW5kIG91dCBhYm91dCB0aGUgY29tcGFueSBjdWx0dXJlIjoiW8aRw6zDrMOs4LiB4Li14LmJxowgw7bDtsO2x5rHmsea4bmvIMSDxIPEg8Ofw7bDtsO2x5rHmsea4bmvIOG5r%2BG4qeG6veG6veG6vSDDp8O2w7bDtm3MgsalxIPEg8SD4LiB4Li14LmJw73DvcO9IMOnx5rHmseaxprhua%2FHmseax5rFmeG6veG6veG6vV0iLCJSYXRpbmdzIGFuZCByZXZpZXdzIjoiW8WYxIPEg8SD4bmvw6zDrMOs4LiB4Li14LmJxKPFoSDEg8SDxIPguIHguLXguYnGjCDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1xaFdIiwiUmVhZCByZXZpZXdzIGZyb20gZW1wbG95ZWVzIjoiW8WY4bq94bq9xIPEg8aMIMWZ4bq94bq94bm9w6zDrOG6veG6vcW1xaEgxpLFmcO2w7ZtzIIg4bq94bq9bcyCxqXGmsO2w7bDvcO94bq94bq94bq94bq9xaFdIiwiUGVya3MgYW5kIGJlbmVmaXRzIjoiW8ak4bq94bq94bq9xZnEt8WhIMSDxIPEg%2BC4geC4teC5icaMIMOf4bq94bq94bq94LiB4Li14LmJ4bq94bq94bq9xpLDrMOsw6zhua%2FFoV0iLCJGaW5kIHBlcmtzIHRoYXQgbWF0dGVyIHRvIHlvdSI6IlvGkcOsw6zDrOC4geC4teC5icaMIMal4bq94bq94bq9xZnEt8WhIOG5r%2BG4qcSDxIPEg%2BG5ryBtzILEg8SDxIPhua%2Fhua%2Fhur3hur3hur3FmSDhua%2FDtsO2w7Ygw73DvcO9w7bDtsO2x5rHmseaXSIsIkluZm9ybWF0aW9uIGZvciBlbXBsb3llcnMiOiJbw4%2FguIHguLXguYnGksO2w7bDtsWZbcyCxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIMaSw7bDtsO2xZkg4bq94bq94bq9bcyCxqXGmsO2w7bDtsO9w73DveG6veG6veG6vcWZxaFdIiwiU2VlIGNvbW11bml0eSBndWlkZWxpbmVzIjoiW8Wg4bq94bq94bq94bq9IMOnw7bDtm3Mgm3Mgseax5rguIHguLXguYnDrMOs4bmvw73DvSDEo8eax5rDrMOsxozhur3hur3GmsOsw6zguIHguLXguYnhur3hur3FoV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    