import type { Country, Language } from '@seek/melways-sites';

export const getLanguagesByCountryCode = (
  countryCode: Uppercase<Country>,
): //* We Do Not support 'zh' language from Melways yet.
Exclude<Language, 'zh'>[] => {
  switch (countryCode) {
    case 'TH':
      return ['en', 'th'];
    case 'ID':
      return ['en', 'id'];
    default:
      return ['en'];
  }
};
