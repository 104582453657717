export type PaginationType = 'next' | 'previous' | 'pageNumber';

export const EventNames = {
  COMPANY_CONNECT_PRESSED: 'company_connect_pressed',
  COMPANY_PROFILE_DISPLAYED: 'company_profile_displayed',
  COMPANY_PROFILE_LINK_PRESSED: 'company_profile_link_pressed',
  COMPANY_PROFILE_WRITE_REVIEW_PRESSED: 'company_profile_write_review_pressed',
  NAVIGATION_PRESSED: 'navigation_pressed',
  REGISTER_PRESSED: 'register_pressed',
  REVIEWS_PRESSED: 'reviews_pressed',
  SEARCH_RESULTS_DISPLAYED: 'search_results_displayed',
  SIGN_IN_PRESSED: 'sign_in_pressed',
  SORT_LIST_PRESSED: 'sort_list_pressed',
};

export const LinkActions = {
  TOP_COMPANY_CLICK: 'top_company click',
  RANDOM_COMPANY_CLICK: 'random_company click',
};

export const CurrentPage = {
  COMPANY_PROFILE: 'company profile',
  COMPANY_OVERVIEW: 'company overview',
  COMPANY_OVERVIEW_SEARCH_RESULTS: 'company overview search results',
};

export const SiteSection = {
  COMPANY_PROFILE: 'company profile',
  DISCOVER: 'discover',
};

export const CurrentPageSection = {
  HOME: 'home',
  ABOUT: 'about',
  CULTURE: 'culture',
  JOBS: 'jobs',
  REVIEWS: 'reviews',
};

export type CurrentPageSectionType =
  | 'home'
  | 'about'
  | 'culture'
  | 'jobs'
  | 'reviews';

export type SortOrder = 'most recent' | 'most helpful';
