import { Textarea } from 'braid-design-system';
import type { QuestionProps } from '../../type';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';

export const TheTitleQuestion = ({
  value = '',
  message = '',
  tone,
  onChange,
}: QuestionProps) => {
  const { t } = useTranslations(translations);
  return (
    <Textarea
      id={'title'}
      label={t('Summarise your experience in one sentence')}
      description={t("This will be used as the title of your review")}
      onChange={(event) => onChange(event.currentTarget.value)}
      value={value}
      tone={tone}
      message={message}
    />
  );
};
