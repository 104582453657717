import {
  Box,
  Dialog,
  Stack,
  Text,
  Column,
  Hidden,
  IconChevron,
  Columns,
  ButtonIcon,
} from 'braid-design-system';
import dayjs from 'dayjs';
import { useState } from 'react';
import * as styles from './PhotoGaleryModal.css';
import type { GalleryPhoto } from '../../../../models/CompanyProfile';

type DialogProps = React.ComponentProps<typeof Dialog>;

interface PhotoGalleryModalProps extends Omit<DialogProps, 'children' | 'id'> {
  currentPhotoIndex: number;
  photos: GalleryPhoto[];
}

const TOUCH_MOVE_LIMIT = 75;

export const PhotoGalleryModal = ({
  currentPhotoIndex,
  photos,
  ...modalProps
}: PhotoGalleryModalProps) => {
  const [photoIndex, setPhotoIndex] = useState(currentPhotoIndex);
  const photo = photos[photoIndex];
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const handleTouchStart = (e: React.TouchEvent<HTMLElement>) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLElement>) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (
      touchStart - touchEnd > TOUCH_MOVE_LIMIT &&
      photoIndex < photos.length - 1
    ) {
      setPhotoIndex(photoIndex + 1);
    }
    if (touchEnd - touchStart > TOUCH_MOVE_LIMIT && photoIndex > 0) {
      setPhotoIndex(photoIndex - 1);
    }
  };

  const handleLeftArrowClick = () => {
    if (photoIndex > 0) {
      setPhotoIndex(photoIndex - 1);
    }
  };

  const handleRightArrowClick = () => {
    if (photoIndex < photos.length - 1) {
      setPhotoIndex(photoIndex + 1);
    }
  };

  return (
    <Dialog {...modalProps} id="photoGalleryModal" width="large">
      <Box
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
      >
        <Columns space="small" alignY="center">
          <Hidden below="tablet">
            <Column width="content">
              <Box className={photoIndex === 0 ? styles.hideOccupySpace : null}>
                <ButtonIcon
                  icon={<IconChevron direction="left" />}
                  onClick={handleLeftArrowClick}
                  id="PreviousButton"
                  label="Previous"
                  size="large"
                />
              </Box>
            </Column>
          </Hidden>

          <Column>
            <Stack space="medium">
              <Box className={styles.imageBox}>
                <img src={photo.link} className={styles.image} />
              </Box>
              <Box className={styles.textBox}>
                <Columns space="small">
                  <Column>
                    <Stack space={['medium', 'small']}>
                      {photo.title ? (
                        <Text weight="medium" maxLines={1}>
                          {photo.title}
                        </Text>
                      ) : null}
                      {photo.date ? (
                        <Text tone="secondary">
                          {dayjs.unix(photo.date).format('DD MMMM, YYYY')}
                        </Text>
                      ) : null}
                    </Stack>
                  </Column>
                  <Column width="content">
                    <Text tone="neutral">
                      {`${photoIndex + 1}/${photos.length}`}
                    </Text>
                  </Column>
                </Columns>
              </Box>
            </Stack>
          </Column>

          <Hidden below="tablet">
            <Column width="content">
              <Box
                className={
                  photoIndex === photos.length - 1
                    ? styles.hideOccupySpace
                    : null
                }
              >
                <ButtonIcon
                  icon={<IconChevron direction="right" />}
                  onClick={handleRightArrowClick}
                  id="NextButton"
                  label="Next"
                  size="large"
                />
              </Box>
            </Column>
          </Hidden>
        </Columns>
      </Box>
    </Dialog>
  );
};
