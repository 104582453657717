import { useEffect, useState } from 'react';
import { useRouteLoaderData } from 'react-router';
import { AUTHENTICATION_TYPES } from '@seek/apac-candidate-header-footer/types';
import { useService, type FlagReviewPayload } from '../../../';
import {
  PageLoader,
  type ReviewUpvotes,
  type Reviews,
  type SortOrder,
} from '@seek/libs-shared';

import { useAuth } from '../../../shared/providers/authProvider';
import { CompanyProfilesPage } from '../../../paths';
import { ReviewsPage } from './ReviewsPage';
import type { ReviewsTabModel } from './reviewsTabModel';
import { ReviewReplyNotice } from '../../components/reviewReplyNotice/ReviewReplyNotice';
import { SeoStructuredData } from '../../components/seoStructuredData/SeoStructuredData';
import { mapToSeoData } from '../../components/seoStructuredData/mapper';

export function ReviewsPageContainer({
  showReviewReplyNotice,
  trackWriteAReviewClicked,
  trackReviewsPaginationClicked,
  trackSortOrderChanged,
  trackSignInClicked,
  trackRegisterClicked,
}: {
  showReviewReplyNotice: boolean;
  trackWriteAReviewClicked: () => void;
  trackReviewsPaginationClicked: ({
    pageNumber,
  }: {
    pageNumber: number;
  }) => void;
  trackSortOrderChanged: ({ sortOrder }: { sortOrder: SortOrder }) => void;
  trackSignInClicked: () => void;
  trackRegisterClicked: () => void;
}) {
  const model = useRouteLoaderData(
    CompanyProfilesPage.Reviews,
  ) as ReviewsTabModel;

  const { companyProfileService } = useService();
  const { authenticationStatus, ssoLogin } = useAuth();
  const [newModel, setNewModel] = useState<ReviewsTabModel>(model);
  const getReviews = async ({
    companyId,
    page,
    sortOrder,
    perPage,
  }: {
    companyId: string;
    page: number;
    sortOrder: SortOrder;
    perPage?: number;
  }): Promise<Reviews> => {
    const resp = await companyProfileService.getReviews({
      companyId,
      page,
      sortOrder,
      perPage,
    });
    return resp;
  };

  const upvoteReview = async ({
    reviewId,
    companyId,
  }: {
    reviewId: string;
    companyId: string;
  }): Promise<void> => {
    if (
      authenticationStatus === AUTHENTICATION_TYPES.UNAUTHENTICATED &&
      typeof ssoLogin === 'function'
    ) {
      ssoLogin();
    }
    await companyProfileService.postUpvoteReview({
      companyId,
      reviewId,
    });
  };

  const flagReview = async (
    companyId: string,
    reviewId: string,
    payload: FlagReviewPayload,
  ): Promise<boolean> => {
    if (
      authenticationStatus === AUTHENTICATION_TYPES.UNAUTHENTICATED &&
      typeof ssoLogin === 'function'
    ) {
      ssoLogin();
    }

    const response = await companyProfileService.postFlagReview({
      companyId,
      reviewId,
      details: payload.details,
      reason: payload.reason,
    });
    return response?.success || false;
  };

  useEffect(() => {
    const getUpvoteIds = async (companyId: string): Promise<ReviewUpvotes> => {
      let upvotedIds = {} as ReviewUpvotes;

      try {
        upvotedIds = await companyProfileService.getUpvoteIds({
          companyId,
        });
      } catch {
        /* empty */
      }
      return upvotedIds;
    };

    const loadUpvoteIds = async () => {
      const reviews = model?.reviews;
      if (
        authenticationStatus === AUTHENTICATION_TYPES.AUTHENTICATED &&
        reviews
      ) {
        const companyId = reviews.companyId;
        const upvotedIds = await getUpvoteIds(companyId);
        reviews.upvotedIds = upvotedIds;
        setNewModel({ ...model, reviews });
      }
    };
    loadUpvoteIds();
  }, [authenticationStatus, companyProfileService, model]);

  if (showReviewReplyNotice) {
    return <ReviewReplyNotice />;
  }

  return model || newModel ? (
    <>
      <SeoStructuredData data={mapToSeoData(newModel || model)} />
      <ReviewsPage
        model={newModel || model}
        showReviewReplyNotice={showReviewReplyNotice}
        getReviews={getReviews}
        upvoteReview={upvoteReview}
        flagReview={flagReview}
        trackWriteAReviewClicked={trackWriteAReviewClicked}
        trackReviewsPaginationClicked={trackReviewsPaginationClicked}
        trackSortOrderChanged={trackSortOrderChanged}
        trackSignInClicked={trackSignInClicked}
        trackRegisterClicked={trackRegisterClicked}
      />
    </>
  ) : (
    <PageLoader />
  );
}
