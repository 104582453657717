import type { AppConfig } from '../../config';

export interface SitemapService {
  getSitemapFileContent: (sitemapFileName: string) => Promise<{
    content: string;
    status: number;
  }>;
}

export const createSitemapService = ({
  config,
}: {
  config: AppConfig;
}): SitemapService => {
  const { sitemapBucketName } = config;
  const getSitemapFileContent = async (sitemapFileName: string) => {
    const response = await fetch(
      `https://${sitemapBucketName}.s3.ap-southeast-2.amazonaws.com/${sitemapFileName}`,
    );
    return {
      content: response.status === 200 ? await response.text() : '',
      status: response.status,
    };
  };

  return {
    getSitemapFileContent,
  };
};
