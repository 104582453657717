import {
  CurrentPage,
  CurrentPageSection,
  EventNames,
  LinkActions,
  sendLinkEvent,
  SiteSection,
  type BaseConfig,
  type CurrentPageSectionType,
  type SortOrder,
} from '@seek/libs-shared';

interface CommonInputType {
  config: BaseConfig;
  context: AppContext;
}
interface SendEventInputType extends CommonInputType {
  eventName: string;
  data: Record<string, unknown>;
}

interface AppContext {
  userId?: string;
  advertiserId?: string;
  companyId?: string;
  companyName?: string;
  isLoggedIn?: boolean;
}

const sendEvent = ({
  config,
  context,
  eventName,
  data,
}: SendEventInputType) => {
  sendLinkEvent(eventName, config, {
    ...data,
    isLoggedIn: Boolean(context.userId),
    ...(context.userId ? { loginId: context.userId } : {}),
  });
};

interface ActionOriginInputType extends CommonInputType {
  actionOrigin: CurrentPageSectionType;
}

interface CompanyProfileLinkInputType extends CommonInputType {
  linkedCompanyId: string;
}

const trackWriteAReviewButtonClicked = ({
  config,
  context,
  actionOrigin,
}: ActionOriginInputType) => {
  sendEvent({
    config,
    context,
    eventName: EventNames.COMPANY_PROFILE_WRITE_REVIEW_PRESSED,
    data: {
      companyId: context.companyId,
      companyName: context.companyName,
      actionOrigin,
    },
  });
};

const trackReviewsClicked = ({
  config,
  context,
  actionOrigin,
}: ActionOriginInputType) => {
  sendEvent({
    config,
    context,
    eventName: EventNames.REVIEWS_PRESSED,
    data: {
      currentPage: CurrentPage.COMPANY_PROFILE,
      currentPageSection: actionOrigin,
      companyId: context.companyId,
      companyName: context.companyName,
      linkName: EventNames.REVIEWS_PRESSED,
      linkPosition: 'reviews',
      actionOrigin,
    },
  });
};

interface PaginationClickedInputType extends CommonInputType {
  currentPageSection: 'jobs' | 'reviews' | 'culture';
}

const trackPaginationClicked =
  ({ config, context, currentPageSection }: PaginationClickedInputType) =>
  ({
    searchTotalCount,
    pageNumber,
  }: {
    searchTotalCount?: number;
    pageNumber: number;
  }) => {
    sendEvent({
      config,
      context,
      eventName: EventNames.NAVIGATION_PRESSED,
      data: {
        currentPage: CurrentPage.COMPANY_PROFILE,
        currentPageSection,
        companyId: context.companyId,
        companyName: context.companyName,
        linkName: EventNames.NAVIGATION_PRESSED,
        linkPosition: currentPageSection,
        searchTotalCount,
        linkText: pageNumber,
      },
    });
  };

const trackSortOrderChanged =
  ({ config, context }: CommonInputType) =>
  ({ sortOrder }: { sortOrder: SortOrder }) => {
    sendEvent({
      config,
      context,
      eventName: EventNames.SORT_LIST_PRESSED,
      data: {
        currentPage: CurrentPage.COMPANY_PROFILE,
        currentPageSection: CurrentPageSection.REVIEWS,
        siteSection: SiteSection.COMPANY_PROFILE,
        companyId: context.companyId,
        companyName: context.companyName,
        linkName: EventNames.SORT_LIST_PRESSED,
        sortMode: sortOrder,
      },
    });
  };

const trackSeeAllPhotosButtonClicked = ({
  config,
  context,
}: CommonInputType) => {
  sendEvent({
    config,
    context,
    eventName: EventNames.COMPANY_CONNECT_PRESSED,
    data: {
      currentPage: CurrentPage.COMPANY_PROFILE,
      currentPageSection: CurrentPageSection.ABOUT,
      companyId: context.companyId,
      companyName: context.companyName,
      siteSection: SiteSection.COMPANY_PROFILE,
      linkName: EventNames.COMPANY_CONNECT_PRESSED,
      connectType: 'photo',
      linkPosition: 'photo',
    },
  });
};

const trackWebsiteClicked = ({ config, context }: CommonInputType) => {
  sendEvent({
    config,
    context,
    eventName: EventNames.COMPANY_CONNECT_PRESSED,
    data: {
      currentPage: CurrentPage.COMPANY_PROFILE,
      currentPageSection: CurrentPageSection.ABOUT,
      companyId: context.companyId,
      companyName: context.companyName,
      siteSection: SiteSection.COMPANY_PROFILE,
      linkName: EventNames.COMPANY_CONNECT_PRESSED,
      connectType: 'website',
      linkPosition: 'overview',
    },
  });
};

const trackSignInClicked = ({ config, context }: CommonInputType) => {
  sendEvent({
    config,
    context,
    eventName: EventNames.SIGN_IN_PRESSED,
    data: {
      currentPage: CurrentPage.COMPANY_PROFILE,
      currentPageSection: CurrentPageSection.REVIEWS,
      companyId: context.companyId,
      companyName: context.companyName,
      siteSection: SiteSection.COMPANY_PROFILE,
      linkName: EventNames.SIGN_IN_PRESSED,
    },
  });
};

const trackRegisterClicked = ({ config, context }: CommonInputType) => {
  sendEvent({
    config,
    context,
    eventName: EventNames.REGISTER_PRESSED,
    data: {
      currentPage: CurrentPage.COMPANY_PROFILE,
      currentPageSection: CurrentPageSection.REVIEWS,
      companyId: context.companyId,
      companyName: context.companyName,
      siteSection: SiteSection.COMPANY_PROFILE,
      linkName: EventNames.REGISTER_PRESSED,
    },
  });
};

const trackTopCompanyProfileLinkClicked = ({
  config,
  context,
  linkedCompanyId,
}: CompanyProfileLinkInputType) => {
  sendEvent({
    config,
    context,
    eventName: EventNames.COMPANY_PROFILE_LINK_PRESSED,
    data: {
      currentPage: CurrentPage.COMPANY_OVERVIEW,
      siteSection: SiteSection.COMPANY_PROFILE,
      linkAction: LinkActions.TOP_COMPANY_CLICK,
      linkText: `linked company id ${linkedCompanyId}`,
    },
  });
};

const trackRandomCompanyProfileLinkClicked = ({
  config,
  context,
  linkedCompanyId,
}: CompanyProfileLinkInputType) => {
  sendEvent({
    config,
    context,
    eventName: EventNames.COMPANY_PROFILE_LINK_PRESSED,
    data: {
      currentPage: CurrentPage.COMPANY_PROFILE,
      currentPageSection: CurrentPageSection.ABOUT,
      companyId: context.companyId,
      companyName: context.companyName,
      siteSection: SiteSection.COMPANY_PROFILE,
      linkAction: LinkActions.RANDOM_COMPANY_CLICK,
      linkText: `linked company id ${linkedCompanyId}`,
    },
  });
};

export const trackLinkEvents = {
  trackPaginationClicked,
  trackRandomCompanyProfileLinkClicked,
  trackRegisterClicked,
  trackReviewsClicked,
  trackSeeAllPhotosButtonClicked,
  trackSignInClicked,
  trackSortOrderChanged,
  trackTopCompanyProfileLinkClicked,
  trackWebsiteClicked,
  trackWriteAReviewButtonClicked,
};
