import { useState } from 'react';
import { WriteAReviewPage } from './WriteAReviewPage';
import { v4 as uuid } from 'uuid';
import { useService } from '../../shared/providers/serviceProvider';
import type { RoleTitle } from '../../shared/services/roleService/roleService';

export const WriteAReviewContainer = () => {
  const [roleTitleSessionId, setRoleTitleSessionId] = useState<string>(uuid());
  const [locationSessionId] = useState<string>(uuid());

  const { roleService, companyProfileService, locationService } = useService();

  const getRoleTitles = async (query: string): Promise<RoleTitle[]> => {
    // If text is cleared, generate a new session id
    let currentSessionId = roleTitleSessionId;
    if (query === '') {
      currentSessionId = uuid();
      setRoleTitleSessionId(currentSessionId);
    }

    return await roleService.getRoleTitles(query, currentSessionId);
  };

  const searchCompanyProfiles = async (query: string) => {
    return await companyProfileService.getCompanyProfilesByName(query);
  };

  const searchLocations = async (query: string) => {
    return await locationService.getLocations(query, locationSessionId);
  };

  return (
    <WriteAReviewPage
      getRoleTitles={getRoleTitles}
      searchCompanyProfiles={searchCompanyProfiles}
      searchLocations={searchLocations}
    />
  );
};
