export const RatingsAndReviewsIcon = () => (
  <svg
    width="128"
    height="128"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M100.918 94.6719C107.836 86.3541 112 75.6643 112 64C112 37.4902 90.5096 16 64 16C37.4904 16 16 37.4902 16 64C16 90.5098 37.4904 112 64 112C72.2191 112 79.9536 109.931 86.7169 106.29L103.457 109.213L100.918 94.6719Z"
      fill="#EE399E"
    />
    <path
      d="M65.0407 47.278L66.9309 51.1079L71.1575 51.722C72.1097 51.8604 72.4899 53.0306 71.8009 53.7022L68.7425 56.6834L69.4645 60.8929C69.6271 61.8412 68.6317 62.5644 67.78 62.1167L63.9997 60.1292L60.2194 62.1167C59.3677 62.5644 58.3723 61.8412 58.5349 60.8929L59.2569 56.6834L56.1985 53.7022C55.5095 53.0306 55.8897 51.8604 56.8419 51.722L61.0685 51.1079L62.9586 47.278C63.3845 46.4151 64.6149 46.4151 65.0407 47.278Z"
      fill="#FDD651"
    />
    <path
      d="M44.6697 47.278L46.5598 51.1079L50.7864 51.722C51.7386 51.8604 52.1188 53.0306 51.4298 53.7022L48.3714 56.6834L49.0934 60.8929C49.256 61.8412 48.2606 62.5644 47.4089 62.1167L43.6286 60.1292L39.8483 62.1167C38.9966 62.5644 38.0012 61.8412 38.1638 60.8929L38.8858 56.6834L35.8274 53.7022C35.1384 53.0306 35.5186 51.8604 36.4708 51.722L40.6974 51.1079L42.5876 47.278C43.0134 46.4151 44.2438 46.4151 44.6697 47.278Z"
      fill="#FDD651"
    />
    <path
      d="M85.4128 47.278L87.303 51.1079L91.5295 51.722C92.4817 51.8604 92.862 53.0306 92.1729 53.7022L89.1146 56.6834L89.8365 60.8929C89.9992 61.8412 89.0038 62.5644 88.1521 62.1167L84.3718 60.1292L80.5914 62.1167C79.7397 62.5644 78.7443 61.8412 78.907 60.8929L79.629 56.6834L76.5706 53.7022C75.8816 53.0306 76.2618 51.8604 77.214 51.722L81.4405 51.1079L83.3307 47.278C83.7566 46.4151 84.987 46.4151 85.4128 47.278Z"
      fill="#FDD651"
    />
    <path d="M84.6426 72.2109H43.3564V75.1445H84.6426V72.2109Z" fill="white" />
    <path d="M81.1416 82.4688H46.8594V85.4023H81.1416V82.4688Z" fill="white" />
  </svg>
);
