import { Autosuggest, IconSearch } from 'braid-design-system';
import type { QuestionProps } from '../../../type';
import type { SearchCompanyProfileResultItem } from '../../../../shared/services/companyProfileService/types';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';

export interface CompanyNameValue {
  companyName?: string;
  companyId?: string;
  organisationId?: string;
}

export interface CompanyNameQuestionProps
  extends QuestionProps<CompanyNameValue> {
  text?: string;
  searchCompanyProfiles: (
    query: string,
  ) => Promise<SearchCompanyProfileResultItem[]>;
}

interface AutosuggestValue {
  text: string;
  description?: string;
  value?: string;
  organisationId?: string;
}

export const CompanyNameQuestion = ({
  message = '',
  value = {},
  tone,
  onChange,
  searchCompanyProfiles,
}: CompanyNameQuestionProps) => {
  const MINIMUM_CHARACTERS_SEARCH = 3;
  const [suggestions, setSuggestions] = useState<AutosuggestValue[]>([]);
  const [searchTerm, setSearchTerm] = useState<AutosuggestValue>({
    value: value.companyId,
    text: value.companyName || '',
  });
  const [debouncedSearchTerm] = useDebounce(searchTerm, 400);
  const { t } = useTranslations(translations);

  const handleOnChangeAutosuggest = (event: AutosuggestValue) => {
    setSearchTerm(event);
    if (!event.text || event.text.length < MINIMUM_CHARACTERS_SEARCH) {
      setSuggestions([]);
      onChange({});
      return;
    }
    if (event.value) {
      onChange({
        companyName: event.text,
        companyId: event.value,
        organisationId: suggestions.find(
          (option) => option.value === event.value,
        )?.organisationId,
      });
    } else {
      onChange({});
    }
  };

  const onClear = () => {
    setSearchTerm({ value: undefined, text: '' });
    onChange({});
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      const companiesList = await searchCompanyProfiles(
        debouncedSearchTerm.text,
      );
      const cannotFindCompany: AutosuggestValue = {
        text: debouncedSearchTerm.text,
        value: 'orphan',
        organisationId: undefined,
      };
      setSuggestions(
        companiesList
          ?.map(
            (company) =>
              ({
                text: company.data.name,
                value: company.companyId,
                organisationId: company.organisationId,
              }) as AutosuggestValue,
          )
          .concat(cannotFindCompany) || [cannotFindCompany],
      );
    };

    if (debouncedSearchTerm.text.trim().length >= MINIMUM_CHARACTERS_SEARCH) {
      fetchCompanies();
    }
  }, [debouncedSearchTerm]);

  return (
    <Autosuggest
      id={'companyName'}
      type="text"
      label={t('Company Name')}
      aria-label={t('Company Name')}
      icon={<IconSearch />}
      onChange={handleOnChangeAutosuggest}
      description={t('Well add this company to your SEEK Profile')}
      suggestions={suggestions}
      onClear={onClear}
      value={searchTerm}
      tone={tone}
      message={message}
      suggestionHighlight={'matching'}
      automaticSelection={true}
      reserveMessageSpace={true}
    />
  );
};
