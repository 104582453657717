import { ReviewPageLayout } from '../components/ReviewPageLayout/ReviewPageLayout';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import type { PageProps, ReviewPayload } from '../type';
import { TheTitleQuestion } from '../questions/SimpleQuestions/TheTitleQuestion';
import { TheGoodThingsQuestion } from '../questions/SimpleQuestions/TheGoodThingsQuestion';
import { validationRules } from '../validation/validationRules';
import { useConfig, useValidation, type Rules } from '@seek/libs-shared';
import { TheChallengesQuestion } from '../questions/SimpleQuestions/TheChallengesQuestion';
import { Stack } from 'braid-design-system';

export const YourExperiencePage = ({
  companyProfile,
  review,
  setPage,
  setter,
  pageNumber,
}: PageProps) => {
  const { t } = useTranslations(translations);

  const { language } = useConfig();

  const fields = {
    theGoodThings: review?.theGoodThings,
    theChallenges: review?.theChallenges,
    title: review?.title,
  };

  const { theGoodThings, theChallenges, title } = validationRules(language);

  const rules = {
    theGoodThings,
    theChallenges,
    title,
  } as Rules<typeof fields>;

  const { validate, getTone, getMessage, handleValueChangeWithValidation } =
    useValidation<ReviewPayload>(fields, rules);

  const handleOnContinue = () => {
    const isValid = validate();
    if (!isValid) {
      return;
    }
    setPage(pageNumber + 1);
  };

  const handleOnBack = () => {
    setPage(pageNumber - 1);
  };

  return (
    <ReviewPageLayout
      progress={pageNumber}
      companyName={companyProfile?.name}
      subtitle={t(
        'A great review shares details about what works well and what doesnt work well so someone understands the company culture.',
      )}
      onContinue={handleOnContinue}
      onBack={handleOnBack}
      testid="your-experience-page"
    >
      <Stack space="small">
        <TheGoodThingsQuestion
          onChange={handleValueChangeWithValidation({
            setter,
            validatorProperty: 'theGoodThings',
            previousValue: review,
          })}
          value={review?.theGoodThings}
          tone={getTone('theGoodThings')}
          message={getMessage('theGoodThings')}
        />
        <TheChallengesQuestion
          onChange={handleValueChangeWithValidation({
            setter,
            validatorProperty: 'theChallenges',
            previousValue: review,
          })}
          value={review?.theChallenges}
          tone={getTone('theChallenges')}
          message={getMessage('theChallenges')}
        />
        <TheTitleQuestion
          onChange={handleValueChangeWithValidation({
            setter,
            validatorProperty: 'title',
            previousValue: review,
          })}
          value={review?.title}
          tone={getTone('title')}
          message={getMessage('title')}
        />
      </Stack>
    </ReviewPageLayout>
  );
};
