
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IkhvdyB3YXMgeW91ciBvdmVyYWxsIGV4cGVyaWVuY2U%2FIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJDYXJlZXIgZGV2ZWxvcG1lbnQgb3Bwb3J0dW5pdGllcyIsIldvcmsvbGlmZSBiYWxhbmNlIjoiV29yay9saWZlIGJhbGFuY2UiLCJXb3JraW5nIGVudmlyb25tZW50IjoiV29ya2luZyBlbnZpcm9ubWVudCIsIkRpdmVyc2l0eSBhbmQgZXF1YWwgb3Bwb3J0dW5pdHkiOiJEaXZlcnNpdHkgYW5kIGVxdWFsIG9wcG9ydHVuaXR5IiwiTWFuYWdlbWVudCI6Ik1hbmFnZW1lbnQiLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJQZXJrcyBhbmQgYmVuZWZpdHMiLCJPcHRpb25hbCI6Ik9wdGlvbmFsIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IlvhuKjDtsO2xbUgxbXEg8SDxaEgw73DvcO2w7bHmseaxZkgw7bDtuG5veG6veG6vcWZxIPEg8aaxpog4bq94bq96q2VxqXhur3hur3FmcOsw6zhur3hur3guIHguLXguYnDp%2BG6veG6vT9dIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJbw4fEg8SDxZnhur3hur3hur3hur3FmSDGjOG6veG6veG5veG6veG6vcaaw7bDtsalbcyC4bq94bq94LiB4Li14LmJ4bmvIMO2w7bGpcalw7bDtsWZ4bmvx5rHmuC4geC4teC5icOsw6zhua%2FDrMOs4bq94bq9xaFdIiwiV29yay9saWZlIGJhbGFuY2UiOiJbxbTDtsO2w7bFmcS3L8aaw6zDrMOsxpLhur3hur3hur0gw5%2FEg8SDxIPGmsSDxIPEg%2BC4geC4teC5icOn4bq94bq94bq9XSIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJbxbTDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMg4bq94bq94bq94LiB4Li14LmJ4bm9w6zDrMOsxZnDtsO2w7bguIHguLXguYltzILhur3hur3hur3guIHguLXguYnhua9dIiwiRGl2ZXJzaXR5IGFuZCBlcXVhbCBvcHBvcnR1bml0eSI6IlvhuIrDrMOs4bm94bq94bq9xZnFocOsw6zhua%2FDvcO9IMSDxIPguIHguLXguYnGjCDhur3hur1xzIfHmseaxIPEg8aaIMO2w7bGpcalw7bDtsWZ4bmvx5rHmuC4geC4teC5icOsw6zhua%2FDvcO9XSIsIk1hbmFnZW1lbnQiOiJb4bmCxIPEg%2BC4geC4teC5icSDxIPEo%2BG6veG6vW3MguG6veG6veC4geC4teC5ieG5r10iLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJbxqThur3hur3hur3FmcS3xaEgxIPEg8SD4LiB4Li14LmJxowgw5%2Fhur3hur3hur3guIHguLXguYnhur3hur3hur3GksOsw6zDrOG5r8WhXSIsIk9wdGlvbmFsIjoiW8OWxqXhua%2FDrMOsw6zDtsO2w7bguIHguLXguYnEg8SDxIPGml0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJIb3cgd2FzIHlvdXIgb3ZlcmFsbCBleHBlcmllbmNlPyI6IlvhuKjDtsO2xbUgxbXEg8SDxaEgw73DvcO2w7bHmseaxZkgw7bDtuG5veG6veG6vcWZxIPEg8aaxpog4bq94bq96q2VxqXhur3hur3FmcOsw6zhur3hur3guIHguLXguYnDp%2BG6veG6vT9dIiwiQ2FyZWVyIGRldmVsb3BtZW50IG9wcG9ydHVuaXRpZXMiOiJbw4fEg8SDxZnhur3hur3hur3hur3FmSDGjOG6veG6veG5veG6veG6vcaaw7bDtsalbcyC4bq94bq94LiB4Li14LmJ4bmvIMO2w7bGpcalw7bDtsWZ4bmvx5rHmuC4geC4teC5icOsw6zhua%2FDrMOs4bq94bq9xaFdIiwiV29yay9saWZlIGJhbGFuY2UiOiJbxbTDtsO2w7bFmcS3L8aaw6zDrMOsxpLhur3hur3hur0gw5%2FEg8SDxIPGmsSDxIPEg%2BC4geC4teC5icOn4bq94bq94bq9XSIsIldvcmtpbmcgZW52aXJvbm1lbnQiOiJbxbTDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMg4bq94bq94bq94LiB4Li14LmJ4bm9w6zDrMOsxZnDtsO2w7bguIHguLXguYltzILhur3hur3hur3guIHguLXguYnhua9dIiwiRGl2ZXJzaXR5IGFuZCBlcXVhbCBvcHBvcnR1bml0eSI6IlvhuIrDrMOs4bm94bq94bq9xZnFocOsw6zhua%2FDvcO9IMSDxIPguIHguLXguYnGjCDhur3hur1xzIfHmseaxIPEg8aaIMO2w7bGpcalw7bDtsWZ4bmvx5rHmuC4geC4teC5icOsw6zhua%2FDvcO9XSIsIk1hbmFnZW1lbnQiOiJb4bmCxIPEg%2BC4geC4teC5icSDxIPEo%2BG6veG6vW3MguG6veG6veC4geC4teC5ieG5r10iLCJQZXJrcyBhbmQgYmVuZWZpdHMiOiJbxqThur3hur3hur3FmcS3xaEgxIPEg8SD4LiB4Li14LmJxowgw5%2Fhur3hur3hur3guIHguLXguYnhur3hur3hur3GksOsw6zDrOG5r8WhXSIsIk9wdGlvbmFsIjoiW8OWxqXhua%2FDrMOsw6zDtsO2w7bguIHguLXguYnEg8SDxIPGml0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    