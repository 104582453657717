import { Box, Notice, Stack, Text } from 'braid-design-system';
import { Star } from './Star';
import { useState } from 'react';

const RATING_VALUES = ['1', '2', '3', '4', '5'] as const;

export type Rating = (typeof RATING_VALUES)[number];

type AreStarsSelected = {
  [key in Rating]: boolean;
};

export const StarSelector = ({
  value,
  message,
  onSelected,
}: {
  value?: Rating;
  message?: string;
  onSelected: (rating: Rating) => void;
}) => {
  const getStarsStatusByRating = (
    rating: Rating | undefined,
  ): AreStarsSelected => {
    const status = {
      '1': false,
      '2': false,
      '3': false,
      '4': false,
      '5': false,
    };

    RATING_VALUES.map((starRating) => {
      status[starRating] = starRating <= (rating ?? '0');
    });

    return status;
  };

  const [areStarsSelected, setAreStarsSelected] = useState<AreStarsSelected>(
    getStarsStatusByRating(value),
  );

  const handleOnClick = (rating: Rating) => () => {
    updateStars(rating);
    onSelected(rating);
  };

  const onMouseEnter = (rating: Rating) => () => {
    updateStars(rating);
  };

  const onMouseLeave = () => {
    updateStars(value);
  };

  const updateStars = (rating: Rating | undefined) => {
    const newAreStarsSelected = getStarsStatusByRating(rating);
    setAreStarsSelected(newAreStarsSelected);
  };

  return (
    <Stack space="small">
      <Box display="flex">
        <Star
          isSelected={areStarsSelected['1']}
          onClick={handleOnClick('1')}
          onMouseEnter={onMouseEnter('1')}
          onMouseLeave={onMouseLeave}
        />
        <Star
          isSelected={areStarsSelected['2']}
          onClick={handleOnClick('2')}
          onMouseEnter={onMouseEnter('2')}
          onMouseLeave={onMouseLeave}
        />
        <Star
          isSelected={areStarsSelected['3']}
          onClick={handleOnClick('3')}
          onMouseEnter={onMouseEnter('3')}
          onMouseLeave={onMouseLeave}
        />
        <Star
          isSelected={areStarsSelected['4']}
          onClick={handleOnClick('4')}
          onMouseEnter={onMouseEnter('4')}
          onMouseLeave={onMouseLeave}
        />
        <Star
          isSelected={areStarsSelected['5']}
          onClick={handleOnClick('5')}
          onMouseEnter={onMouseEnter('5')}
          onMouseLeave={onMouseLeave}
        />
      </Box>
      {message ? (
        <Notice tone="critical">
          <Text size="small">{message}</Text>
        </Notice>
      ) : null}
    </Stack>
  );
};
