import { createValidator } from '@seek/validators-js';
import translations from './.vocab';
import type { MonthPickerValue } from '../../type';

export const testWorkHistoryRequired = (value: MonthPickerValue) =>
  Boolean(value?.year && value?.month);

export const { workHistoryRequired } = createValidator({
  test: ({ value }: { value: MonthPickerValue }) =>
    testWorkHistoryRequired(value),
  formatErrorMessages: (messages) => () =>
    messages['Enter a month and year'].format(),
  translations,
  validatorName: 'workHistoryRequired',
});
