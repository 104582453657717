import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import { RatingQuestion } from './RatingQuestion';
import type { QuestionProps } from '../../type';
import type { Rating } from '../../components/StarSelector/StarSelector';

export const OverallRatingQuestion = (props: QuestionProps<Rating>) => {
  const { t } = useTranslations(translations);
  return (
    <RatingQuestion
      {...props}
      isRequired={true}
      title={t('How was your overall experience?')}
    />
  );
};
