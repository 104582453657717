import {
  Autosuggest,
  Box,
  Column,
  Columns,
  IconSearch,
} from 'braid-design-system';
import { useConfig } from '@seek/libs-shared';
import { useDebounce } from 'use-debounce';
import { useNavigate } from 'react-router';
import { useState, useEffect, type Dispatch, type SetStateAction } from 'react';
import { useTranslations } from '@vocab/react';
import { useMelwaysLink } from '@seek/melways-react';

import { useService } from '../../../shared/providers/serviceProvider';
import { CompanyProfilesPage, ROOT_PATH } from '../../../paths';
import type { AppConfig } from '../../../shared/config';

import translations from './.vocab';

interface AutosuggestValue<Value> {
  text: string;
  description?: string;
  value?: Value;
}

export function CompanySearchBar({
  compactMode = false,
  setActiveTab,
}: {
  compactMode?: boolean;
  setActiveTab?: Dispatch<SetStateAction<string | undefined>>;
}) {
  const { t } = useTranslations(translations);
  const { companyProfileService } = useService();
  const config = useConfig<AppConfig>();
  const navigate = useNavigate();
  const { language } = config;
  const [searchTerm, setSearchTerm] = useState<AutosuggestValue<string>>({
    text: '',
  });
  const [companySuggestions, setCompanySuggestions] = useState<
    AutosuggestValue<string>[]
  >([]);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 200);
  const [isSearching, setIsSearching] = useState(false);
  const [hasHighlightedAutosuggestValue, setHasHighlightedAutosuggestValue] =
    useState(false);

  const localize = useMelwaysLink();

  const DEFAULT_TAB = CompanyProfilesPage.About;
  const MINIMUM_CHARACTERS_SEARCH = 3;

  const searchForCompany = async () => {
    if (searchTerm.text.length) {
      const queryParams = new URLSearchParams({
        term: searchTerm.text,
      });

      navigate(`/companies/search?${queryParams.toString()}`);
    }
  };

  const clearCompanySuggestions = () => {
    setCompanySuggestions([]);
    setHasHighlightedAutosuggestValue(false);
  };

  const navigateToCompanyProfileUrl = async (
    autosuggestValue: AutosuggestValue<string>,
  ) => {
    if (autosuggestValue.value) {
      if (setActiveTab) {
        setActiveTab(DEFAULT_TAB);
      }
      setSearchTerm({ text: '' });
      clearCompanySuggestions();
      navigate(
        `${localize({
          path: `/${ROOT_PATH}/${encodeURIComponent(autosuggestValue.value)}`,
          language,
        })}`,
      );
    }
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      const companiesList = await companyProfileService.searchCompanies({
        searchTerm: debouncedSearchTerm.text,
      });
      setCompanySuggestions(
        companiesList?.companies.map((company) => ({
          text: company.name,
          value: company.slug,
        })) || [],
      );
      setIsSearching(false);
    };

    if (debouncedSearchTerm.text.trim().length >= MINIMUM_CHARACTERS_SEARCH) {
      fetchCompanies();
    }
  }, [companyProfileService, debouncedSearchTerm]);

  return (
    <Box
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !hasHighlightedAutosuggestValue) {
          setHasHighlightedAutosuggestValue(false);
          searchForCompany();
        }
        if (
          companySuggestions.length > 0 &&
          (e.key === 'ArrowDown' || e.key === 'ArrowUp')
        )
          setHasHighlightedAutosuggestValue(true);
      }}
    >
      <Columns
        space="small"
        align={compactMode ? 'right' : 'left'}
        collapseBelow="tablet"
      >
        <Column width={compactMode ? '1/3' : undefined}>
          <Autosuggest
            id="Company Search bar"
            placeholder={t('Search by company name')}
            icon={<IconSearch />}
            aria-label="Search by company name"
            value={searchTerm}
            onChange={(e) => {
              if (!e.value && e.text.length >= MINIMUM_CHARACTERS_SEARCH) {
                setIsSearching(true);
              }
              setSearchTerm(e);
              if (e.text.length < MINIMUM_CHARACTERS_SEARCH) {
                clearCompanySuggestions();
              }
              if (e.value && !isSearching) {
                navigateToCompanyProfileUrl(e);
              }
            }}
            onClear={() => {
              setSearchTerm({ text: '' });
              clearCompanySuggestions();
            }}
            suggestions={companySuggestions}
          />
        </Column>
      </Columns>
    </Box>
  );
}
