import {
  Box,
  Inline,
  Rating,
  Stack,
  Text,
  Tiles,
  Loader,
} from 'braid-design-system';
import { useTranslations } from '@vocab/react';

import { formatRating } from '../../../utils/rating';
import { mapRatingsBreakDown } from '../../../utils/mapRatingsBreakDown';

import type { CompanyReviewSummaryBreakdown } from '../../../models/CompanyProfile';
import translations from './.vocab';

interface Props {
  model?: CompanyReviewSummaryBreakdown[];
}

export const ReviewSummaryBreakdown = ({ model }: Props) => {
  const { t } = useTranslations(translations);

  if (!model) {
    return <Loader />;
  }
  const reviewSummaryBreakdowns = model;
  const ratings = mapRatingsBreakDown(reviewSummaryBreakdowns);
  return (
    <Stack space="large">
      <Tiles columns={[1, 3]} space={['xlarge', 'large']}>
        {ratings &&
          ratings.map((rating, index) => (
            <Box key={`rating-${index}`}>
              {rating && (
                <Stack space="small">
                  <Text align="center">{t(rating.description)}</Text>
                  <Inline space="small" align="center">
                    <Text weight="strong">
                      {formatRating(rating.rating, 1)}
                    </Text>
                    <Rating rating={rating.rating} variant="starsOnly" />
                  </Inline>
                </Stack>
              )}
            </Box>
          ))}
      </Tiles>
    </Stack>
  );
};
