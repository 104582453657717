import dayjs from 'dayjs';
import translations from './.vocab';
import type { Language } from '@seek/melways-sites';
import { getTranslations } from './translations';

const t = getTranslations(translations);

export const fromNow = (dateRaw: string, language: Language) => {
  const now = dayjs();
  const comparingDate = new Date(dateRaw);
  const minutes = now.diff(comparingDate, 'minutes');
  const hours = now.diff(comparingDate, 'hours');
  const days = now.diff(comparingDate, 'days');

  if (minutes < 0) {
    return '';
  }

  if (minutes === 0) {
    return t(language, '1m ago');
  }

  if (minutes < 60) {
    return t(language, '{minutes}m ago', { minutes: minutes.toString() });
  }

  if (hours < 24) {
    return t(language, '{hours}h ago', { hours: hours.toString() });
  }

  if (days <= 30) {
    return t(language, '{days}d ago', { days: days.toString() });
  }

  return t(language, '30d+ ago');
};
