import assert from 'assert';

interface PageInfo<T> {
  items: T[];
  pageNumber: number;
  pagesCount: number;
}

export const toPageInfo = <T>(
  allItems: T[],
  pageSize: number,
  pageNumber: number,
): PageInfo<T> => {
  const pagesCount = Math.ceil(allItems.length / pageSize) || 1;

  assert(pageSize > 0, 'Page size must be greater than 0');
  assert(
    pageNumber > 0 && pageNumber <= pagesCount,
    `Page number must be between 1 and ${pagesCount} (inclusive)`,
  );

  return {
    items: allItems.slice(pageSize * (pageNumber - 1), pageSize * pageNumber),
    pageNumber,
    pagesCount,
  };
};
