import { Box } from 'braid-design-system';

import * as styles from './AspectRatioBox.css';
import { AspectRatio } from '../../../types';

interface AspectRatioBoxProps {
  aspectRatio?: AspectRatio;
}

/**
 * Creates responsive box with fixed aspect ratio.
 *
 * See, https://alistapart.com/article/creating-intrinsic-ratios-for-video/ and
 * https://css-tricks.com/aspect-ratio-boxes/
 *
 * @param aspectRatio - aspect ratio of the responsive box
 * @param children - content of the responsive box
 */
export const AspectRatioBox = ({
  aspectRatio = '1:1',
  children,
}: React.PropsWithChildren<AspectRatioBoxProps>) => (
  <Box className={[styles.outerBox, styles.aspectRatio[aspectRatio]]}>
    <Box className={styles.innerBox}>{children}</Box>
  </Box>
);
