import type { MelwaysZone } from '@seek/melways-sites';
import type { LocationKind } from '@seek/location-data';

export const isTargetLocation = ({
  locationKind,
  locationZone,
}: {
  locationKind: LocationKind;
  locationZone: MelwaysZone;
}): boolean => {
  // JobsDB HK & TH
  if (locationZone === 'asia-1' || locationZone === 'asia-3') {
    return locationKind === 'State';
  }

  // JobStreet MY
  if (locationZone === 'asia-5') {
    return locationKind === 'SEEKLocation' || locationKind === 'State';
  }

  // The rest of the zones
  return locationKind === 'SEEKLocation';
};
