import { Box, Pagination, Stack, Text } from 'braid-design-system';
import { useTranslations } from '@vocab/react';

import translations from './.vocab';

import {
  type Reviews,
  type SortOrder,
  useConfig,
  Section,
  SHOW_REVIEWS_LIST_MINIMUM_REVIEWS,
} from '@seek/libs-shared';
import { useEffect, useRef, useState } from 'react';
import { ReviewsList } from '../reviewsList/ReviewsList';
import {
  ReviewsEmptyState,
  ReviewsEmptyStateType,
} from '../reviewsEmptyState/ReviewsEmptyState';
import { type FlagReviewPayload } from '../../../../';
import { useFeatureFlags } from '../../../../shared/providers/featureFlagProvider';

interface Props {
  mode: 'view' | 'edit';
  model: Reviews;
  getReviews: ({
    companyId,
    page,
    sortOrder,
    perPage,
  }: {
    companyId: string;
    page: number;
    sortOrder: SortOrder;
    perPage?: number;
  }) => Promise<Reviews>;
  upvoteReview: ({
    reviewId,
    companyId,
  }: {
    reviewId: string;
    companyId: string;
  }) => Promise<void>;
  flagReview: (
    companyId: string,
    reviewId: string,
    payload: FlagReviewPayload,
  ) => Promise<boolean>;
  trackWriteAReviewClicked: () => void;
  trackReviewsPaginationClicked: ({
    pageNumber,
  }: {
    pageNumber: number;
  }) => void;
  trackSortOrderChanged: ({ sortOrder }: { sortOrder: SortOrder }) => void;
  trackSignInClicked: () => void;
  trackRegisterClicked: () => void;
}

export function ReviewsSection({
  mode,
  model,
  getReviews,
  upvoteReview,
  flagReview,
  trackWriteAReviewClicked,
  trackReviewsPaginationClicked,
  trackSortOrderChanged,
  trackSignInClicked,
  trackRegisterClicked,
}: Props) {
  const { t } = useTranslations(translations);
  const config = useConfig();
  const { brand } = config;
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState<SortOrder>('most recent');
  const [reviewsModel, setReviewsModel] = useState<Reviews>(model);
  const scrollRef = useRef<HTMLInputElement>(null);
  const [showReviewsList, setShowReviewsList] = useState(
    false || Number(model?.paging?.total) >= SHOW_REVIEWS_LIST_MINIMUM_REVIEWS,
  );

  const REVIEWS_PER_PAGE = 10;

  const scrollToRef = () =>
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });

  const totalPages = reviewsModel?.paging?.totalPages || 1;
  const reviewsCount = reviewsModel?.paging?.total || 0;

  const [reviewsLoaded, setReviewsLoaded] = useState(false);

  const { getFeatureFlag } = useFeatureFlags();
  const shouldProtectReviews: boolean =
    getFeatureFlag('protectReviews') ?? true;

  useEffect(() => {
    async function loadReviews() {
      try {
        if (model.companyId && !shouldProtectReviews) {
          const reviews = await getReviews({
            companyId: model.companyId,
            page: currentPage,
            sortOrder,
            perPage: REVIEWS_PER_PAGE,
          });

          setReviewsModel({ ...reviews, upvotedIds: model.upvotedIds });
          setReviewsLoaded(true);
        }
      } catch (error) {
        setReviewsModel(model);
      }
    }
    loadReviews();
  }, [currentPage, getReviews, model, sortOrder, shouldProtectReviews]);

  const handleSortOrderChanged = (value: SortOrder) => {
    setSortOrder(value);
    trackSortOrderChanged({ sortOrder });
    setCurrentPage(1);
  };

  useEffect(() => {
    if (reviewsCount >= SHOW_REVIEWS_LIST_MINIMUM_REVIEWS) {
      setShowReviewsList(true);
    }
  }, [reviewsCount]);

  const onPaginationLinkClick =
    (pageNumber: number) =>
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      trackReviewsPaginationClicked({ pageNumber });
      setCurrentPage(pageNumber);
      setTimeout(scrollToRef, 300);
    };

  if (!showReviewsList)
    return (
      <Box paddingY="xxlarge">
        <ReviewsEmptyState
          companyName={model.name}
          type={ReviewsEmptyStateType.NO_LIST}
          trackWriteAReviewClicked={trackWriteAReviewClicked}
        />
      </Box>
    );

  return (
    <Section mode={mode} paddingBottom="none">
      <Box ref={scrollRef}>
        <Stack space="gutter">
          {showReviewsList && (
            <Stack space="xlarge">
              <ReviewsList
                model={reviewsModel}
                upvoteReview={upvoteReview}
                flagReview={flagReview}
                sortOrder={sortOrder}
                onSortOrderChange={handleSortOrderChanged}
                shouldProtectReviews={shouldProtectReviews}
                reviewsLoaded={reviewsLoaded}
                trackSignInClicked={trackSignInClicked}
                trackRegisterClicked={trackRegisterClicked}
              />
              {totalPages > 1 &&
              !shouldProtectReviews &&
              reviewsCount > REVIEWS_PER_PAGE ? (
                <Pagination
                  page={currentPage}
                  total={totalPages}
                  linkProps={({ page }) => ({
                    href: `#${page}`,
                    onClick: onPaginationLinkClick(page),
                  })}
                  label={t('Reviews List Pagination')}
                  nextLabel={t('Next')}
                  previousLabel={t('Previous')}
                />
              ) : null}

              <Text tone="secondary" size="small">
                {t('Reviews disclaimer', {
                  brand:
                    brand === 'seek'
                      ? 'SEEK'
                      : brand.charAt(0).toUpperCase() + brand.slice(1),
                })}
              </Text>
            </Stack>
          )}
        </Stack>
      </Box>
    </Section>
  );
}
