import { ReviewPageLayout } from '../components/ReviewPageLayout/ReviewPageLayout';
import type { PageProps, ReviewPayload } from '../type';
import { Stack } from 'braid-design-system';

import type { Rating } from '../components/StarSelector/StarSelector';
import { CareerOpportunityRatingQuestion } from '../questions/RatingQuesions/CareerOpportunityRatingQuestion';
import { WorkLifeBalanceRatingQuestion } from '../questions/RatingQuesions/WorkLifeBalanceRatingQuestion';
import { WorkEnvironmentRatingQuestion } from '../questions/RatingQuesions/WorkEnvironmentRatingQuestion';
import { DiversityRatingQuestion } from '../questions/RatingQuesions/DiversityRatingQuestion';
import { PerksAndBenefitsRatingQuestion } from '../questions/RatingQuesions/PerksAndBenefitsRatingQuestion';
import { ExecutiveManagementRatingQuestion } from '../questions/RatingQuesions/ExecutiveManagementRatingQuestion';

export const YourFeedbackPage = ({
  companyProfile,
  review,
  setter,
  setPage,
  onSubmit,
  pageNumber,
}: PageProps) => {
  const handleOnChange =
    (updateProperty: keyof ReviewPayload) => (value: Rating) => {
      setter({
        ...review,
        [updateProperty]: value,
      });
    };

  const handleOnContinue = () => {
    if (onSubmit) onSubmit();
  };

  const handleOnBack = () => {
    setPage(pageNumber - 1);
  };

  return (
    <ReviewPageLayout
      progress={pageNumber}
      companyName={companyProfile?.name}
      onContinue={handleOnContinue}
      onBack={handleOnBack}
      testid="ratings-page"
    >
      <Stack space="large">
        <CareerOpportunityRatingQuestion
          onChange={handleOnChange('careerOpportunityRating')}
          value={review?.careerOpportunityRating}
        />
        <WorkLifeBalanceRatingQuestion
          onChange={handleOnChange('workLifeBalanceRating')}
          value={review?.workLifeBalanceRating}
        />
        <WorkEnvironmentRatingQuestion
          onChange={handleOnChange('workEnvironmentRating')}
          value={review?.workEnvironmentRating}
        />
        <ExecutiveManagementRatingQuestion
          onChange={handleOnChange('executiveManagementRating')}
          value={review?.executiveManagementRating}
        />
        <PerksAndBenefitsRatingQuestion
          onChange={handleOnChange('perksAndBenefitsRating')}
          value={review?.perksAndBenefitsRating}
        />
        <DiversityRatingQuestion
          onChange={handleOnChange('diversityRating')}
          value={review?.diversityRating}
        />
      </Stack>
    </ReviewPageLayout>
  );
};
