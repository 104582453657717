
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiLguJ7guLHguJnguJjguIHguLTguIjguILguK3guIfguYDguKPguLIiLCJDdXN0b20gc3RhdGVtZW50Ijoi4Lie4Lix4LiZ4LiY4LiB4Li04LiI4LmA4LiJ4Lie4Liy4Liw4Liq4Liz4Lir4Lij4Lix4Lia4Lit4LiH4LiE4LmM4LiB4Lij4LiE4Li44LiTIiwiQ3VzdG9tIHN0YXRlbWVudCB1cGRhdGVkIjoi4Lie4Lix4LiZ4LiY4LiB4Li04LiI4LmA4LiJ4Lie4Liy4Liw4Liq4Liz4Lir4Lij4Lix4Lia4Lit4LiH4LiE4LmM4LiB4Lij4LiE4Li44LiT4LmE4LiU4LmJ4Lit4Lix4Lib4LmA4LiU4LiV4LmB4Lil4LmJ4LinIiwiTWlzc2lvbiBzdGF0ZW1lbnQgdXBkYXRlZCI6IuC4nuC4seC4meC4mOC4geC4tOC4iOC5hOC4lOC5ieC4reC4seC4m%2BC5gOC4lOC4leC5geC4peC5ieC4pyIsIkhpZ2hsaWdodCB3aGF0IHNldHMgeW91ciBjb21wYW55IGFwYXJ0IGZyb20gb3RoZXJzLiI6IuC5hOC4ruC5hOC4peC4l%2BC5jOC4quC4tOC5iOC4h%2BC4l%2BC4teC5iOC4l%2BC4s%2BC5g%2BC4q%2BC5ieC4reC4h%2BC4hOC5jOC4geC4o%2BC4guC4reC4h%2BC4hOC4uOC4k%2BC5guC4lOC4lOC5gOC4lOC5iOC4mSIsIkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iOiLguYTguK7guYTguKXguJfguYzguYDguJvguYnguLLguKvguKHguLLguKLguILguK3guIfguK3guIfguITguYzguIHguKPguITguLjguJMiLCJTaG93IG1vcmUiOiLguYHguKrguJTguIfguKHguLLguIHguILguLbguYnguJkiLCJTaG93IGxlc3MiOiLguYHguKrguJTguIfguJnguYnguK3guKLguKXguIcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiLguJ7guLHguJnguJjguIHguLTguIjguILguK3guIfguYDguKPguLIiLCJDdXN0b20gc3RhdGVtZW50Ijoi4Lie4Lix4LiZ4LiY4LiB4Li04LiI4LmA4LiJ4Lie4Liy4Liw4Liq4Liz4Lir4Lij4Lix4Lia4Lit4LiH4LiE4LmM4LiB4Lij4LiE4Li44LiTIiwiQ3VzdG9tIHN0YXRlbWVudCB1cGRhdGVkIjoi4Lie4Lix4LiZ4LiY4LiB4Li04LiI4LmA4LiJ4Lie4Liy4Liw4Liq4Liz4Lir4Lij4Lix4Lia4Lit4LiH4LiE4LmM4LiB4Lij4LiE4Li44LiT4LmE4LiU4LmJ4Lit4Lix4Lib4LmA4LiU4LiV4LmB4Lil4LmJ4LinIiwiTWlzc2lvbiBzdGF0ZW1lbnQgdXBkYXRlZCI6IuC4nuC4seC4meC4mOC4geC4tOC4iOC5hOC4lOC5ieC4reC4seC4m%2BC5gOC4lOC4leC5geC4peC5ieC4pyIsIkhpZ2hsaWdodCB3aGF0IHNldHMgeW91ciBjb21wYW55IGFwYXJ0IGZyb20gb3RoZXJzLiI6IuC5hOC4ruC5hOC4peC4l%2BC5jOC4quC4tOC5iOC4h%2BC4l%2BC4teC5iOC4l%2BC4s%2BC5g%2BC4q%2BC5ieC4reC4h%2BC4hOC5jOC4geC4o%2BC4guC4reC4h%2BC4hOC4uOC4k%2BC5guC4lOC4lOC5gOC4lOC5iOC4mSIsIkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iOiLguYTguK7guYTguKXguJfguYzguYDguJvguYnguLLguKvguKHguLLguKLguILguK3guIfguK3guIfguITguYzguIHguKPguITguLjguJMiLCJTaG93IG1vcmUiOiLguYHguKrguJTguIfguKHguLLguIHguILguLbguYnguJkiLCJTaG93IGxlc3MiOiLguYHguKrguJTguIfguJnguYnguK3guKLguKXguIcifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJNaXNpIHBlcnVzYWhhYW4ga2FtaSIsIkN1c3RvbSBzdGF0ZW1lbnQiOiJQZXJueWF0YWFuIGt1c3RvbSIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IlBlcm55YXRhYW4ga3VzdG9tIGRpcGVyYmFydWkiLCJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIjoiUGVybnlhdGFhbiBtaXNpIGRpcGVyYmFydWkiLCJIaWdobGlnaHQgd2hhdCBzZXRzIHlvdXIgY29tcGFueSBhcGFydCBmcm9tIG90aGVycy4iOiJUb25qb2xrYW4gYXBhIHlhbmcgbWVtYnVhdCBwZXJ1c2FoYWFuIEFuZGEgYmVyYmVkYSBkYXJpIHlhbmcgbGFpbiIsIkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iOiJUb25qb2xrYW4gdHVqdWFuIHBlcnVzYWhhYW4gQW5kYSIsIlNob3cgbW9yZSI6IlRhbXBpbGthbiBsZWJpaCBiYW55YWsiLCJTaG93IGxlc3MiOiJUYW1waWxrYW4gbGViaWggc2VkaWtpdCJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJNaXNpIHBlcnVzYWhhYW4ga2FtaSIsIkN1c3RvbSBzdGF0ZW1lbnQiOiJQZXJueWF0YWFuIGt1c3RvbSIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IlBlcm55YXRhYW4ga3VzdG9tIGRpcGVyYmFydWkiLCJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIjoiUGVybnlhdGFhbiBtaXNpIGRpcGVyYmFydWkiLCJIaWdobGlnaHQgd2hhdCBzZXRzIHlvdXIgY29tcGFueSBhcGFydCBmcm9tIG90aGVycy4iOiJUb25qb2xrYW4gYXBhIHlhbmcgbWVtYnVhdCBwZXJ1c2FoYWFuIEFuZGEgYmVyYmVkYSBkYXJpIHlhbmcgbGFpbiIsIkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iOiJUb25qb2xrYW4gdHVqdWFuIHBlcnVzYWhhYW4gQW5kYSIsIlNob3cgbW9yZSI6IlRhbXBpbGthbiBsZWJpaCBiYW55YWsiLCJTaG93IGxlc3MiOiJUYW1waWxrYW4gbGViaWggc2VkaWtpdCJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiLguJ7guLHguJnguJjguIHguLTguIjguILguK3guIfguYDguKPguLIiLCJDdXN0b20gc3RhdGVtZW50Ijoi4Lie4Lix4LiZ4LiY4LiB4Li04LiI4LmA4LiJ4Lie4Liy4Liw4Liq4Liz4Lir4Lij4Lix4Lia4Lit4LiH4LiE4LmM4LiB4Lij4LiE4Li44LiTIiwiQ3VzdG9tIHN0YXRlbWVudCB1cGRhdGVkIjoi4Lie4Lix4LiZ4LiY4LiB4Li04LiI4LmA4LiJ4Lie4Liy4Liw4Liq4Liz4Lir4Lij4Lix4Lia4Lit4LiH4LiE4LmM4LiB4Lij4LiE4Li44LiT4LmE4LiU4LmJ4Lit4Lix4Lib4LmA4LiU4LiV4LmB4Lil4LmJ4LinIiwiTWlzc2lvbiBzdGF0ZW1lbnQgdXBkYXRlZCI6IuC4nuC4seC4meC4mOC4geC4tOC4iOC5hOC4lOC5ieC4reC4seC4m%2BC5gOC4lOC4leC5geC4peC5ieC4pyIsIkhpZ2hsaWdodCB3aGF0IHNldHMgeW91ciBjb21wYW55IGFwYXJ0IGZyb20gb3RoZXJzLiI6IuC5hOC4ruC5hOC4peC4l%2BC5jOC4quC4tOC5iOC4h%2BC4l%2BC4teC5iOC4l%2BC4s%2BC5g%2BC4q%2BC5ieC4reC4h%2BC4hOC5jOC4geC4o%2BC4guC4reC4h%2BC4hOC4uOC4k%2BC5guC4lOC4lOC5gOC4lOC5iOC4mSIsIkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iOiLguYTguK7guYTguKXguJfguYzguYDguJvguYnguLLguKvguKHguLLguKLguILguK3guIfguK3guIfguITguYzguIHguKPguITguLjguJMiLCJTaG93IG1vcmUiOiLguYHguKrguJTguIfguKHguLLguIHguILguLbguYnguJkiLCJTaG93IGxlc3MiOiLguYHguKrguJTguIfguJnguYnguK3guKLguKXguIcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiLguJ7guLHguJnguJjguIHguLTguIjguILguK3guIfguYDguKPguLIiLCJDdXN0b20gc3RhdGVtZW50Ijoi4Lie4Lix4LiZ4LiY4LiB4Li04LiI4LmA4LiJ4Lie4Liy4Liw4Liq4Liz4Lir4Lij4Lix4Lia4Lit4LiH4LiE4LmM4LiB4Lij4LiE4Li44LiTIiwiQ3VzdG9tIHN0YXRlbWVudCB1cGRhdGVkIjoi4Lie4Lix4LiZ4LiY4LiB4Li04LiI4LmA4LiJ4Lie4Liy4Liw4Liq4Liz4Lir4Lij4Lix4Lia4Lit4LiH4LiE4LmM4LiB4Lij4LiE4Li44LiT4LmE4LiU4LmJ4Lit4Lix4Lib4LmA4LiU4LiV4LmB4Lil4LmJ4LinIiwiTWlzc2lvbiBzdGF0ZW1lbnQgdXBkYXRlZCI6IuC4nuC4seC4meC4mOC4geC4tOC4iOC5hOC4lOC5ieC4reC4seC4m%2BC5gOC4lOC4leC5geC4peC5ieC4pyIsIkhpZ2hsaWdodCB3aGF0IHNldHMgeW91ciBjb21wYW55IGFwYXJ0IGZyb20gb3RoZXJzLiI6IuC5hOC4ruC5hOC4peC4l%2BC5jOC4quC4tOC5iOC4h%2BC4l%2BC4teC5iOC4l%2BC4s%2BC5g%2BC4q%2BC5ieC4reC4h%2BC4hOC5jOC4geC4o%2BC4guC4reC4h%2BC4hOC4uOC4k%2BC5guC4lOC4lOC5gOC4lOC5iOC4mSIsIkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iOiLguYTguK7guYTguKXguJfguYzguYDguJvguYnguLLguKvguKHguLLguKLguILguK3guIfguK3guIfguITguYzguIHguKPguITguLjguJMiLCJTaG93IG1vcmUiOiLguYHguKrguJTguIfguKHguLLguIHguILguLbguYnguJkiLCJTaG93IGxlc3MiOiLguYHguKrguJTguIfguJnguYnguK3guKLguKXguIcifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJNaXNpIHBlcnVzYWhhYW4ga2FtaSIsIkN1c3RvbSBzdGF0ZW1lbnQiOiJQZXJueWF0YWFuIGt1c3RvbSIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IlBlcm55YXRhYW4ga3VzdG9tIGRpcGVyYmFydWkiLCJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIjoiUGVybnlhdGFhbiBtaXNpIGRpcGVyYmFydWkiLCJIaWdobGlnaHQgd2hhdCBzZXRzIHlvdXIgY29tcGFueSBhcGFydCBmcm9tIG90aGVycy4iOiJUb25qb2xrYW4gYXBhIHlhbmcgbWVtYnVhdCBwZXJ1c2FoYWFuIEFuZGEgYmVyYmVkYSBkYXJpIHlhbmcgbGFpbiIsIkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iOiJUb25qb2xrYW4gdHVqdWFuIHBlcnVzYWhhYW4gQW5kYSIsIlNob3cgbW9yZSI6IlRhbXBpbGthbiBsZWJpaCBiYW55YWsiLCJTaG93IGxlc3MiOiJUYW1waWxrYW4gbGViaWggc2VkaWtpdCJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJNaXNpIHBlcnVzYWhhYW4ga2FtaSIsIkN1c3RvbSBzdGF0ZW1lbnQiOiJQZXJueWF0YWFuIGt1c3RvbSIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IlBlcm55YXRhYW4ga3VzdG9tIGRpcGVyYmFydWkiLCJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIjoiUGVybnlhdGFhbiBtaXNpIGRpcGVyYmFydWkiLCJIaWdobGlnaHQgd2hhdCBzZXRzIHlvdXIgY29tcGFueSBhcGFydCBmcm9tIG90aGVycy4iOiJUb25qb2xrYW4gYXBhIHlhbmcgbWVtYnVhdCBwZXJ1c2FoYWFuIEFuZGEgYmVyYmVkYSBkYXJpIHlhbmcgbGFpbiIsIkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iOiJUb25qb2xrYW4gdHVqdWFuIHBlcnVzYWhhYW4gQW5kYSIsIlNob3cgbW9yZSI6IlRhbXBpbGthbiBsZWJpaCBiYW55YWsiLCJTaG93IGxlc3MiOiJUYW1waWxrYW4gbGViaWggc2VkaWtpdCJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiLCJDdXN0b20gc3RhdGVtZW50IjoiQ3VzdG9tIHN0YXRlbWVudCIsIkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCI6IkN1c3RvbSBzdGF0ZW1lbnQgdXBkYXRlZCIsIk1pc3Npb24gc3RhdGVtZW50IHVwZGF0ZWQiOiJNaXNzaW9uIHN0YXRlbWVudCB1cGRhdGVkIiwiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIjoiSGlnaGxpZ2h0IHdoYXQgc2V0cyB5b3VyIGNvbXBhbnkgYXBhcnQgZnJvbSBvdGhlcnMuIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IkhpZ2hsaWdodCB0aGUgb3ZlcmFsbCBwdXJwb3NlIG9mIHlvdXIgY29tcGFueS4iLCJTaG93IG1vcmUiOiJTaG93IG1vcmUiLCJTaG93IGxlc3MiOiJTaG93IGxlc3MifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJbw5bHmseax5rFmSBtzILDrMOsw6zFocWhw6zDrMOsw7bDtsO24LiB4Li14LmJIMWh4bmvxIPEg8SD4bmv4bq94bq94bq9bcyC4bq94bq94bq94LiB4Li14LmJ4bmvXSIsIkN1c3RvbSBzdGF0ZW1lbnQiOiJbw4fHmseax5rFoeG5r8O2w7bDtm3MgiDFoeG5r8SDxIPEg%2BG5r%2BG6veG6veG6vW3MguG6veG6veG6veC4geC4teC5ieG5r10iLCJDdXN0b20gc3RhdGVtZW50IHVwZGF0ZWQiOiJbw4fHmseax5rFoeG5r8O2w7bDtm3MgiDFoeG5r8SDxIPEg%2BG5r%2BG6veG6veG6vW3MguG6veG6veG6veC4geC4teC5ieG5ryDHmseax5rGpcaMxIPEg8SD4bmv4bq94bq94bq9xoxdIiwiTWlzc2lvbiBzdGF0ZW1lbnQgdXBkYXRlZCI6IlvhuYLDrMOsw6zFocWhw6zDrMOsw7bDtsO24LiB4Li14LmJIMWh4bmvxIPEg8SD4bmv4bq94bq94bq9bcyC4bq94bq94bq94LiB4Li14LmJ4bmvIMeax5rHmsalxozEg8SDxIPhua%2Fhur3hur3hur3GjF0iLCJIaWdobGlnaHQgd2hhdCBzZXRzIHlvdXIgY29tcGFueSBhcGFydCBmcm9tIG90aGVycy4iOiJb4biow6zDrMOsxKPhuKnGmsOsw6zDrMSj4bip4bmvIMW14bipxIPEg8SD4bmvIMWh4bq94bq94bq94bmvxaEgw73DvcO9w7bDtsO2x5rHmseaxZkgw6fDtsO2w7ZtzILGpcSDxIPEg%2BC4geC4teC5icO9w73DvSDEg8SDxIPGpcSDxIPEg8WZ4bmvIMaSxZnDtsO2w7ZtzIIgw7bDtsO24bmv4bip4bq94bq94bq9xZnFoS5dIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IlvhuKjDrMOsw6zEo%2BG4qcaaw6zDrMOsxKPhuKnhua8g4bmv4bip4bq94bq94bq9IMO2w7bDtuG5veG6veG6veG6vcWZxIPEg8SDxprGmiDGpceax5rHmsWZxqXDtsO2w7bFoeG6veG6veG6vSDDtsO2w7bGkiDDvcO9w73DtsO2w7bHmseax5rFmSDDp8O2w7bDtm3MgsalxIPEg8SD4LiB4Li14LmJw73DvcO9Ll0iLCJTaG93IG1vcmUiOiJbxaDhuKnDtsO2w7bFtSBtzILDtsO2w7bFmeG6veG6veG6vV0iLCJTaG93IGxlc3MiOiJbxaDhuKnDtsO2w7bFtSDGmuG6veG6veG6vcWhxaFdIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJPdXIgbWlzc2lvbiBzdGF0ZW1lbnQiOiJbw5bHmseax5rFmSBtzILDrMOsw6zFocWhw6zDrMOsw7bDtsO24LiB4Li14LmJIMWh4bmvxIPEg8SD4bmv4bq94bq94bq9bcyC4bq94bq94bq94LiB4Li14LmJ4bmvXSIsIkN1c3RvbSBzdGF0ZW1lbnQiOiJbw4fHmseax5rFoeG5r8O2w7bDtm3MgiDFoeG5r8SDxIPEg%2BG5r%2BG6veG6veG6vW3MguG6veG6veG6veC4geC4teC5ieG5r10iLCJDdXN0b20gc3RhdGVtZW50IHVwZGF0ZWQiOiJbw4fHmseax5rFoeG5r8O2w7bDtm3MgiDFoeG5r8SDxIPEg%2BG5r%2BG6veG6veG6vW3MguG6veG6veG6veC4geC4teC5ieG5ryDHmseax5rGpcaMxIPEg8SD4bmv4bq94bq94bq9xoxdIiwiTWlzc2lvbiBzdGF0ZW1lbnQgdXBkYXRlZCI6IlvhuYLDrMOsw6zFocWhw6zDrMOsw7bDtsO24LiB4Li14LmJIMWh4bmvxIPEg8SD4bmv4bq94bq94bq9bcyC4bq94bq94bq94LiB4Li14LmJ4bmvIMeax5rHmsalxozEg8SDxIPhua%2Fhur3hur3hur3GjF0iLCJIaWdobGlnaHQgd2hhdCBzZXRzIHlvdXIgY29tcGFueSBhcGFydCBmcm9tIG90aGVycy4iOiJb4biow6zDrMOsxKPhuKnGmsOsw6zDrMSj4bip4bmvIMW14bipxIPEg8SD4bmvIMWh4bq94bq94bq94bmvxaEgw73DvcO9w7bDtsO2x5rHmseaxZkgw6fDtsO2w7ZtzILGpcSDxIPEg%2BC4geC4teC5icO9w73DvSDEg8SDxIPGpcSDxIPEg8WZ4bmvIMaSxZnDtsO2w7ZtzIIgw7bDtsO24bmv4bip4bq94bq94bq9xZnFoS5dIiwiSGlnaGxpZ2h0IHRoZSBvdmVyYWxsIHB1cnBvc2Ugb2YgeW91ciBjb21wYW55LiI6IlvhuKjDrMOsw6zEo%2BG4qcaaw6zDrMOsxKPhuKnhua8g4bmv4bip4bq94bq94bq9IMO2w7bDtuG5veG6veG6veG6vcWZxIPEg8SDxprGmiDGpceax5rHmsWZxqXDtsO2w7bFoeG6veG6veG6vSDDtsO2w7bGkiDDvcO9w73DtsO2w7bHmseax5rFmSDDp8O2w7bDtm3MgsalxIPEg8SD4LiB4Li14LmJw73DvcO9Ll0iLCJTaG93IG1vcmUiOiJbxaDhuKnDtsO2w7bFtSBtzILDtsO2w7bFmeG6veG6veG6vV0iLCJTaG93IGxlc3MiOiJbxaDhuKnDtsO2w7bFtSDGmuG6veG6veG6vcWhxaFdIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    