
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoi4Lij4Li14Lin4Li04LinIiwiU2VlIGFsbCByZXZpZXdzIjoi4LiU4Li54Lij4Li14Lin4Li04Lin4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSI6IuC4o%2BC5iOC4p%2BC4oeC4h%2BC4suC4meC4geC4seC4miB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IuC5g%2BC4q%2BC5ieC4hOC4sOC5geC4meC4meC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4meC4p%2BC5iOC4suC4reC4ouC4ueC5iOC5g%2BC4meC4o%2BC4sOC4lOC4seC4muC4quC4ueC4h%2BC4q%2BC4o%2BC4t%2BC4reC4m%2BC4suC4meC4geC4peC4suC4hyIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoi4Lie4LiZ4Lix4LiB4LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4Liz4Lia4Lij4Li04Lip4Lix4LiX4LiZ4Li14LmJ4LmD4Lir4LmJ4LiB4Lix4Lia4LmA4Lie4Li34LmI4Lit4LiZ4LiC4Lit4LiH4Lie4Lin4LiB4LmA4LiC4LiyIiwiWW91ciB0cnVzdCBpcyBvdXIgbWFpbiBjb25jZXJuIHNvIHRoZXNlIHJhdGluZ3MgZm9yIHtjb21wYW55X25hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciI6IuC4hOC4p%2BC4suC4oeC5hOC4p%2BC5ieC4p%2BC4suC4h%2BC5g%2BC4iOC4guC4reC4h%2BC4hOC4uOC4k%2BC4hOC4t%2BC4reC4guC5ieC4reC4geC4seC4h%2BC4p%2BC4peC4q%2BC4peC4seC4geC4guC4reC4h%2BC5gOC4o%2BC4siDguJTguLHguIfguJnguLHguYnguJnguIjguLbguIfguKHguLXguIHguLLguKPguYHguIrguKPguYzguIHguLLguKPguYPguKvguYnguITguLDguYHguJnguJnguYDguKvguKXguYjguLLguJnguLXguYnguKrguLPguKvguKPguLHguJoge2NvbXBhbnlfbmFtZX0g4LiV4Liy4Lih4Liq4Lig4Liy4Lie4LiI4Lij4Li04LiH4LiI4Liy4LiB4Lie4LiZ4Lix4LiB4LiH4Liy4LiZ4LiX4Li14LmI4Liq4Lit4LiU4LiE4Lil4LmJ4Lit4LiHIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiLguYHguJnguKfguJfguLLguIfguJvguI%2FguLTguJrguLHguJXguLTguILguK3guIfguIrguLjguKHguIrguJkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoi4Lij4Li14Lin4Li04LinIiwiU2VlIGFsbCByZXZpZXdzIjoi4LiU4Li54Lij4Li14Lin4Li04Lin4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSI6IuC4o%2BC5iOC4p%2BC4oeC4h%2BC4suC4meC4geC4seC4miB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IuC5g%2BC4q%2BC5ieC4hOC4sOC5geC4meC4meC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4meC4p%2BC5iOC4suC4reC4ouC4ueC5iOC5g%2BC4meC4o%2BC4sOC4lOC4seC4muC4quC4ueC4h%2BC4q%2BC4o%2BC4t%2BC4reC4m%2BC4suC4meC4geC4peC4suC4hyIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoi4Lie4LiZ4Lix4LiB4LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4Liz4Lia4Lij4Li04Lip4Lix4LiX4LiZ4Li14LmJ4LmD4Lir4LmJ4LiB4Lix4Lia4LmA4Lie4Li34LmI4Lit4LiZ4LiC4Lit4LiH4Lie4Lin4LiB4LmA4LiC4LiyIiwiWW91ciB0cnVzdCBpcyBvdXIgbWFpbiBjb25jZXJuIHNvIHRoZXNlIHJhdGluZ3MgZm9yIHtjb21wYW55X25hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciI6IuC4hOC4p%2BC4suC4oeC5hOC4p%2BC5ieC4p%2BC4suC4h%2BC5g%2BC4iOC4guC4reC4h%2BC4hOC4uOC4k%2BC4hOC4t%2BC4reC4guC5ieC4reC4geC4seC4h%2BC4p%2BC4peC4q%2BC4peC4seC4geC4guC4reC4h%2BC5gOC4o%2BC4siDguJTguLHguIfguJnguLHguYnguJnguIjguLbguIfguKHguLXguIHguLLguKPguYHguIrguKPguYzguIHguLLguKPguYPguKvguYnguITguLDguYHguJnguJnguYDguKvguKXguYjguLLguJnguLXguYnguKrguLPguKvguKPguLHguJoge2NvbXBhbnlfbmFtZX0g4LiV4Liy4Lih4Liq4Lig4Liy4Lie4LiI4Lij4Li04LiH4LiI4Liy4LiB4Lie4LiZ4Lix4LiB4LiH4Liy4LiZ4LiX4Li14LmI4Liq4Lit4LiU4LiE4Lil4LmJ4Lit4LiHIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiLguYHguJnguKfguJfguLLguIfguJvguI%2FguLTguJrguLHguJXguLTguILguK3guIfguIrguLjguKHguIrguJkifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiVWxhc2FuIiwiU2VlIGFsbCByZXZpZXdzIjoiTGloYXQgc2VtdWEgdWxhc2FuIiwiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSI6IkJla2VyamEgZGkge2NvbXBhbnlfbmFtZX0iLCJyYXRlIHNhbGFyeSBhcyBoaWdoIG9yIGF2ZXJhZ2UiOiJiZXJpIG5pbGFpIGdhamkgdGluZ2dpIGF0YXUgcmF0YS1yYXRhIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJwZWdhd2FpIG1lcmVrb21lbmRhc2lrYW4gcGVydXNhaGFhbiBpbmkga2VwYWRhIHRlbWFuIiwiWW91ciB0cnVzdCBpcyBvdXIgbWFpbiBjb25jZXJuIHNvIHRoZXNlIHJhdGluZ3MgZm9yIHtjb21wYW55X25hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciI6IktlcGVyY2F5YWFuIEFuZGEgYWRhbGFoIGZva3VzIHV0YW1hIGthbWkgc2VoaW5nZ2EgcGVuaWxhaWFuIHVudHVrIHtjb21wYW55X25hbWV9IGRpYmFnaWthbiBzZWJhZ2FpbWFuYSBhZGFueWEgZGFyaSBrYXJ5YXdhbiBzZXN1YWkgZGVuZ2FuIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJwZWRvbWFuIGtvbXVuaXRhcyBrYW1pIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiVWxhc2FuIiwiU2VlIGFsbCByZXZpZXdzIjoiTGloYXQgc2VtdWEgdWxhc2FuIiwiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSI6IkJla2VyamEgZGkge2NvbXBhbnlfbmFtZX0iLCJyYXRlIHNhbGFyeSBhcyBoaWdoIG9yIGF2ZXJhZ2UiOiJiZXJpIG5pbGFpIGdhamkgdGluZ2dpIGF0YXUgcmF0YS1yYXRhIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJwZWdhd2FpIG1lcmVrb21lbmRhc2lrYW4gcGVydXNhaGFhbiBpbmkga2VwYWRhIHRlbWFuIiwiWW91ciB0cnVzdCBpcyBvdXIgbWFpbiBjb25jZXJuIHNvIHRoZXNlIHJhdGluZ3MgZm9yIHtjb21wYW55X25hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciI6IktlcGVyY2F5YWFuIEFuZGEgYWRhbGFoIGZva3VzIHV0YW1hIGthbWkgc2VoaW5nZ2EgcGVuaWxhaWFuIHVudHVrIHtjb21wYW55X25hbWV9IGRpYmFnaWthbiBzZWJhZ2FpbWFuYSBhZGFueWEgZGFyaSBrYXJ5YXdhbiBzZXN1YWkgZGVuZ2FuIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJwZWRvbWFuIGtvbXVuaXRhcyBrYW1pIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoi4Lij4Li14Lin4Li04LinIiwiU2VlIGFsbCByZXZpZXdzIjoi4LiU4Li54Lij4Li14Lin4Li04Lin4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSI6IuC4o%2BC5iOC4p%2BC4oeC4h%2BC4suC4meC4geC4seC4miB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IuC5g%2BC4q%2BC5ieC4hOC4sOC5geC4meC4meC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4meC4p%2BC5iOC4suC4reC4ouC4ueC5iOC5g%2BC4meC4o%2BC4sOC4lOC4seC4muC4quC4ueC4h%2BC4q%2BC4o%2BC4t%2BC4reC4m%2BC4suC4meC4geC4peC4suC4hyIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoi4Lie4LiZ4Lix4LiB4LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4Liz4Lia4Lij4Li04Lip4Lix4LiX4LiZ4Li14LmJ4LmD4Lir4LmJ4LiB4Lix4Lia4LmA4Lie4Li34LmI4Lit4LiZ4LiC4Lit4LiH4Lie4Lin4LiB4LmA4LiC4LiyIiwiWW91ciB0cnVzdCBpcyBvdXIgbWFpbiBjb25jZXJuIHNvIHRoZXNlIHJhdGluZ3MgZm9yIHtjb21wYW55X25hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciI6IuC4hOC4p%2BC4suC4oeC5hOC4p%2BC5ieC4p%2BC4suC4h%2BC5g%2BC4iOC4guC4reC4h%2BC4hOC4uOC4k%2BC4hOC4t%2BC4reC4guC5ieC4reC4geC4seC4h%2BC4p%2BC4peC4q%2BC4peC4seC4geC4guC4reC4h%2BC5gOC4o%2BC4siDguJTguLHguIfguJnguLHguYnguJnguIjguLbguIfguKHguLXguIHguLLguKPguYHguIrguKPguYzguIHguLLguKPguYPguKvguYnguITguLDguYHguJnguJnguYDguKvguKXguYjguLLguJnguLXguYnguKrguLPguKvguKPguLHguJoge2NvbXBhbnlfbmFtZX0g4LiV4Liy4Lih4Liq4Lig4Liy4Lie4LiI4Lij4Li04LiH4LiI4Liy4LiB4Lie4LiZ4Lix4LiB4LiH4Liy4LiZ4LiX4Li14LmI4Liq4Lit4LiU4LiE4Lil4LmJ4Lit4LiHIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiLguYHguJnguKfguJfguLLguIfguJvguI%2FguLTguJrguLHguJXguLTguILguK3guIfguIrguLjguKHguIrguJkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoi4Lij4Li14Lin4Li04LinIiwiU2VlIGFsbCByZXZpZXdzIjoi4LiU4Li54Lij4Li14Lin4Li04Lin4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSI6IuC4o%2BC5iOC4p%2BC4oeC4h%2BC4suC4meC4geC4seC4miB7Y29tcGFueV9uYW1lfSIsInJhdGUgc2FsYXJ5IGFzIGhpZ2ggb3IgYXZlcmFnZSI6IuC5g%2BC4q%2BC5ieC4hOC4sOC5geC4meC4meC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4meC4p%2BC5iOC4suC4reC4ouC4ueC5iOC5g%2BC4meC4o%2BC4sOC4lOC4seC4muC4quC4ueC4h%2BC4q%2BC4o%2BC4t%2BC4reC4m%2BC4suC4meC4geC4peC4suC4hyIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoi4Lie4LiZ4Lix4LiB4LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4Liz4Lia4Lij4Li04Lip4Lix4LiX4LiZ4Li14LmJ4LmD4Lir4LmJ4LiB4Lix4Lia4LmA4Lie4Li34LmI4Lit4LiZ4LiC4Lit4LiH4Lie4Lin4LiB4LmA4LiC4LiyIiwiWW91ciB0cnVzdCBpcyBvdXIgbWFpbiBjb25jZXJuIHNvIHRoZXNlIHJhdGluZ3MgZm9yIHtjb21wYW55X25hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciI6IuC4hOC4p%2BC4suC4oeC5hOC4p%2BC5ieC4p%2BC4suC4h%2BC5g%2BC4iOC4guC4reC4h%2BC4hOC4uOC4k%2BC4hOC4t%2BC4reC4guC5ieC4reC4geC4seC4h%2BC4p%2BC4peC4q%2BC4peC4seC4geC4guC4reC4h%2BC5gOC4o%2BC4siDguJTguLHguIfguJnguLHguYnguJnguIjguLbguIfguKHguLXguIHguLLguKPguYHguIrguKPguYzguIHguLLguKPguYPguKvguYnguITguLDguYHguJnguJnguYDguKvguKXguYjguLLguJnguLXguYnguKrguLPguKvguKPguLHguJoge2NvbXBhbnlfbmFtZX0g4LiV4Liy4Lih4Liq4Lig4Liy4Lie4LiI4Lij4Li04LiH4LiI4Liy4LiB4Lie4LiZ4Lix4LiB4LiH4Liy4LiZ4LiX4Li14LmI4Liq4Lit4LiU4LiE4Lil4LmJ4Lit4LiHIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiLguYHguJnguKfguJfguLLguIfguJvguI%2FguLTguJrguLHguJXguLTguILguK3guIfguIrguLjguKHguIrguJkifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiVWxhc2FuIiwiU2VlIGFsbCByZXZpZXdzIjoiTGloYXQgc2VtdWEgdWxhc2FuIiwiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSI6IkJla2VyamEgZGkge2NvbXBhbnlfbmFtZX0iLCJyYXRlIHNhbGFyeSBhcyBoaWdoIG9yIGF2ZXJhZ2UiOiJiZXJpIG5pbGFpIGdhamkgdGluZ2dpIGF0YXUgcmF0YS1yYXRhIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJwZWdhd2FpIG1lcmVrb21lbmRhc2lrYW4gcGVydXNhaGFhbiBpbmkga2VwYWRhIHRlbWFuIiwiWW91ciB0cnVzdCBpcyBvdXIgbWFpbiBjb25jZXJuIHNvIHRoZXNlIHJhdGluZ3MgZm9yIHtjb21wYW55X25hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciI6IktlcGVyY2F5YWFuIEFuZGEgYWRhbGFoIGZva3VzIHV0YW1hIGthbWkgc2VoaW5nZ2EgcGVuaWxhaWFuIHVudHVrIHtjb21wYW55X25hbWV9IGRpYmFnaWthbiBzZWJhZ2FpbWFuYSBhZGFueWEgZGFyaSBrYXJ5YXdhbiBzZXN1YWkgZGVuZ2FuIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJwZWRvbWFuIGtvbXVuaXRhcyBrYW1pIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiVWxhc2FuIiwiU2VlIGFsbCByZXZpZXdzIjoiTGloYXQgc2VtdWEgdWxhc2FuIiwiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSI6IkJla2VyamEgZGkge2NvbXBhbnlfbmFtZX0iLCJyYXRlIHNhbGFyeSBhcyBoaWdoIG9yIGF2ZXJhZ2UiOiJiZXJpIG5pbGFpIGdhamkgdGluZ2dpIGF0YXUgcmF0YS1yYXRhIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJwZWdhd2FpIG1lcmVrb21lbmRhc2lrYW4gcGVydXNhaGFhbiBpbmkga2VwYWRhIHRlbWFuIiwiWW91ciB0cnVzdCBpcyBvdXIgbWFpbiBjb25jZXJuIHNvIHRoZXNlIHJhdGluZ3MgZm9yIHtjb21wYW55X25hbWV9IGFyZSBzaGFyZWQgYXMtaXMgZnJvbSBlbXBsb3llZXMgaW4gbGluZSB3aXRoIG91ciI6IktlcGVyY2F5YWFuIEFuZGEgYWRhbGFoIGZva3VzIHV0YW1hIGthbWkgc2VoaW5nZ2EgcGVuaWxhaWFuIHVudHVrIHtjb21wYW55X25hbWV9IGRpYmFnaWthbiBzZWJhZ2FpbWFuYSBhZGFueWEgZGFyaSBrYXJ5YXdhbiBzZXN1YWkgZGVuZ2FuIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJwZWRvbWFuIGtvbXVuaXRhcyBrYW1pIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiUmV2aWV3cyIsIlNlZSBhbGwgcmV2aWV3cyI6IlNlZSBhbGwgcmV2aWV3cyIsIldvcmtpbmcgYXQge2NvbXBhbnlfbmFtZX0iOiJXb3JraW5nIGF0IHtjb21wYW55X25hbWV9IiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiUmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIiwiZW1wbG95ZWVzIHJlY29tbWVuZCB0aGlzIGVtcGxveWVyIHRvIGZyaWVuZHMiOiJFbXBsb3llZXMgcmVjb21tZW5kIHRoaXMgZW1wbG95ZXIgdG8gZnJpZW5kcyIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJZb3VyIHRydXN0IGlzIG91ciBtYWluIGNvbmNlcm4gc28gdGhlc2UgcmF0aW5ncyBmb3Ige2NvbXBhbnlfbmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIiwiY29tbXVuaXR5IGd1aWRlbGluZXMiOiJjb21tdW5pdHkgZ3VpZGVsaW5lcyJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiW8WY4bq94bq94bm9w6zDrOG6veG6vcW1xaFdIiwiU2VlIGFsbCByZXZpZXdzIjoiW8Wg4bq94bq94bq94bq9IMSDxIPGmsaaIMWZ4bq94bq94bm9w6zDrOG6veG6vcW1xaFdIiwiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSI6IlvFtMO2w7bDtsWZxLfDrMOsw6zguIHguLXguYnEoyDEg8SDxIPhua8ge2NvbXBhbnlfbmFtZX1dIiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiW8WYxIPEg%2BG5r%2BG6veG6vSDFocSDxIPGmsSDxIPFmcO9w70gxIPEg8WhIOG4qcOsw6zEo%2BG4qSDDtsO2xZkgxIPEg%2BG5veG6veG6vcWZxIPEg8Sj4bq94bq9XSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiW8OLbcyCxqXGmsO2w7bDtsO9w73DveG6veG6veG6veG6veG6veG6vcWhIMWZ4bq94bq94bq9w6fDtsO2w7ZtzIJtzILhur3hur3hur3guIHguLXguYnGjCDhua%2FhuKnDrMOsw6zFoSDhur3hur3hur1tzILGpcaaw7bDtsO2w73DvcO94bq94bq94bq9xZkg4bmvw7bDtsO2IMaSxZnDrMOsw6zhur3hur3hur3guIHguLXguYnGjMWhXSIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJbw53DtsO2w7bHmseax5rFmSDhua%2FFmceax5rHmsWh4bmvIMOsw6zDrMWhIMO2w7bDtseax5rHmsWZIG3MgsSDxIPEg8Osw6zDrOC4geC4teC5iSDDp8O2w7bDtuC4geC4teC5icOn4bq94bq94bq9xZnguIHguLXguYkgxaHDtsO2w7Yg4bmv4bip4bq94bq94bq9xaHhur3hur3hur0gxZnEg8SDxIPhua%2FDrMOsw6zguIHguLXguYnEo8WhIMaSw7bDtsO2xZkge2NvbXBhbnlfbmFtZX0gxIPEg8SDxZnhur3hur3hur0gxaHhuKnEg8SDxIPFmeG6veG6veG6vcaMIMSDxIPEg8WhLcOsw6zDrMWhIMaSxZnDtsO2w7ZtzIIg4bq94bq94bq9bcyCxqXGmsO2w7bDtsO9w73DveG6veG6veG6veG6veG6veG6vcWhIMOsw6zDrOC4geC4teC5iSDGmsOsw6zDrOC4geC4teC5ieG6veG6veG6vSDFtcOsw6zDrOG5r%2BG4qSDDtsO2w7bHmseax5rFmV0iLCJjb21tdW5pdHkgZ3VpZGVsaW5lcyI6IlvDp8O2w7ZtzIJtzILHmsea4LiB4Li14LmJw6zDrOG5r8O9w70gxKPHmseaw6zDrMaM4bq94bq9xprDrMOs4LiB4Li14LmJ4bq94bq9xaFdIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZXZpZXdzIjoiW8WY4bq94bq94bm9w6zDrOG6veG6vcW1xaFdIiwiU2VlIGFsbCByZXZpZXdzIjoiW8Wg4bq94bq94bq94bq9IMSDxIPGmsaaIMWZ4bq94bq94bm9w6zDrOG6veG6vcW1xaFdIiwiV29ya2luZyBhdCB7Y29tcGFueV9uYW1lfSI6IlvFtMO2w7bDtsWZxLfDrMOsw6zguIHguLXguYnEoyDEg8SDxIPhua8ge2NvbXBhbnlfbmFtZX1dIiwicmF0ZSBzYWxhcnkgYXMgaGlnaCBvciBhdmVyYWdlIjoiW8WYxIPEg%2BG5r%2BG6veG6vSDFocSDxIPGmsSDxIPFmcO9w70gxIPEg8WhIOG4qcOsw6zEo%2BG4qSDDtsO2xZkgxIPEg%2BG5veG6veG6vcWZxIPEg8Sj4bq94bq9XSIsImVtcGxveWVlcyByZWNvbW1lbmQgdGhpcyBlbXBsb3llciB0byBmcmllbmRzIjoiW8OLbcyCxqXGmsO2w7bDtsO9w73DveG6veG6veG6veG6veG6veG6vcWhIMWZ4bq94bq94bq9w6fDtsO2w7ZtzIJtzILhur3hur3hur3guIHguLXguYnGjCDhua%2FhuKnDrMOsw6zFoSDhur3hur3hur1tzILGpcaaw7bDtsO2w73DvcO94bq94bq94bq9xZkg4bmvw7bDtsO2IMaSxZnDrMOsw6zhur3hur3hur3guIHguLXguYnGjMWhXSIsIllvdXIgdHJ1c3QgaXMgb3VyIG1haW4gY29uY2VybiBzbyB0aGVzZSByYXRpbmdzIGZvciB7Y29tcGFueV9uYW1lfSBhcmUgc2hhcmVkIGFzLWlzIGZyb20gZW1wbG95ZWVzIGluIGxpbmUgd2l0aCBvdXIiOiJbw53DtsO2w7bHmseax5rFmSDhua%2FFmceax5rHmsWh4bmvIMOsw6zDrMWhIMO2w7bDtseax5rHmsWZIG3MgsSDxIPEg8Osw6zDrOC4geC4teC5iSDDp8O2w7bDtuC4geC4teC5icOn4bq94bq94bq9xZnguIHguLXguYkgxaHDtsO2w7Yg4bmv4bip4bq94bq94bq9xaHhur3hur3hur0gxZnEg8SDxIPhua%2FDrMOsw6zguIHguLXguYnEo8WhIMaSw7bDtsO2xZkge2NvbXBhbnlfbmFtZX0gxIPEg8SDxZnhur3hur3hur0gxaHhuKnEg8SDxIPFmeG6veG6veG6vcaMIMSDxIPEg8WhLcOsw6zDrMWhIMaSxZnDtsO2w7ZtzIIg4bq94bq94bq9bcyCxqXGmsO2w7bDtsO9w73DveG6veG6veG6veG6veG6veG6vcWhIMOsw6zDrOC4geC4teC5iSDGmsOsw6zDrOC4geC4teC5ieG6veG6veG6vSDFtcOsw6zDrOG5r%2BG4qSDDtsO2w7bHmseax5rFmV0iLCJjb21tdW5pdHkgZ3VpZGVsaW5lcyI6IlvDp8O2w7ZtzIJtzILHmsea4LiB4Li14LmJw6zDrOG5r8O9w70gxKPHmseaw6zDrMaM4bq94bq9xprDrMOs4LiB4Li14LmJ4bq94bq9xaFdIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    