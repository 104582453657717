import {
  type ApolloClient,
  type NormalizedCacheObject,
  gql,
} from '@apollo/client';
import type { AppConfig } from '../../config';
import type { QueryOntologyOrganisationsArgs } from '@seek/ca-graphql-schema/types';

const ROLE_TITLE_SEARCH_QUERY = gql`
  query OntologyRoleTitlesSeniority(
    $query: String!
    $count: Int!
    $session: String!
    $countryCode2: CountryCodeIso2
    $languageCode: LanguageCodeIso
  ) {
    ontologyRoleTitlesSeniority(
      query: $query
      count: $count
      session: $session
      countryCode2: $countryCode2
      languageCode: $languageCode
    ) {
      normalisedText
    }
  }
`;

export interface RoleTitle {
  id: string;
  normalisedText: string;
}

export type RoleService = ReturnType<typeof createRoleService>;

export const createRoleService = ({
  client,
  config,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  config: AppConfig;
}) => {
  const ROLES_TO_SUGGEST = 10;
  const getRoleTitles = async (
    query: string,
    sessionId: string,
  ): Promise<RoleTitle[]> => {
    const response = await client.query<
      { ontologyRoleTitlesSeniority: RoleTitle[] },
      Partial<QueryOntologyOrganisationsArgs>
    >({
      query: ROLE_TITLE_SEARCH_QUERY,
      variables: {
        query: query,
        count: ROLES_TO_SUGGEST,
        session: sessionId,
        countryCode2: config.country,
        languageCode: config.language,
      },
    });

    return response.data?.ontologyRoleTitlesSeniority ?? [];
  };

  return { getRoleTitles };
};
