import { useTranslations } from '@vocab/react';
import {
  Actions,
  ButtonLink,
  Card,
  Column,
  Columns,
  IconArrow,
  Inline,
  Loader,
  Stack,
  Text,
  TextLink,
} from 'braid-design-system';

import translations from './.vocab';
import {
  type CompanyProfile,
  Section,
  useConfig,
  buildCommunityGuidelinesURI,
  ReviewSummaryBreakdown,
} from '@seek/libs-shared';

import {
  ReviewsEmptyState,
  ReviewsEmptyStateType,
} from '../reviewsEmptyState/ReviewsEmptyState';

import { RatingSummary } from '../ratingSummary/RatingSummary';
import { RatingBreakdown } from '../ratingBreakdown/RatingBreakdown';
import { PercentageWheel } from '../percentageWheel/PercentageWheel';
import { CompanyProfilesPage } from '../../../../';

type ReviewsSummarySectionModel = Pick<
  CompanyProfile,
  | 'companyReviewSummaryBreakdown'
  | 'employeeRecommendationRating'
  | 'name'
  | 'overallRating'
  | 'ratingBreakdown'
  | 'salaryRating'
>;
interface Props {
  loading?: boolean;
  model: ReviewsSummarySectionModel;
  isOnAboutPage?: boolean;
  onActiveTabChanged?: (item: string | undefined) => void;

  trackWriteAReviewClicked: () => void;
  trackReviewsClicked?: () => void;
  link?: string;
}

export function ReviewsSummarySection({
  model,
  isOnAboutPage,
  loading,
  onActiveTabChanged,
  trackWriteAReviewClicked,
  trackReviewsClicked,
  link,
}: Props) {
  const { t } = useTranslations(translations);
  const { locale, zone, experience } = useConfig();
  if (loading || !model) {
    return <Loader />;
  }

  const communityGuidelineURL = buildCommunityGuidelinesURI({
    locale,
    zone,
    experience,
  });
  const hasMinimumReviews = model?.overallRating;

  if (!hasMinimumReviews) {
    return (
      <ReviewsEmptyState
        companyName={model.name}
        wrapInCard
        trackWriteAReviewClicked={trackWriteAReviewClicked}
        type={ReviewsEmptyStateType.NO_SUMMARY}
      />
    );
  }
  return (
    <Section
      loading={loading}
      heading={
        isOnAboutPage
          ? t('Reviews')
          : t('Working at {company_name}', { company_name: model.name })
      }
    >
      <Stack space="large">
        <Stack space="medium">
          <Stack space="small">
            <Card>
              <Stack space="xlarge">
                <Columns
                  space={['xlarge', 'large']}
                  alignY="center"
                  collapseBelow="tablet"
                >
                  <Column width="1/4">
                    <RatingSummary model={model.overallRating} />
                  </Column>
                  <Column width="1/4">
                    <RatingBreakdown model={model.ratingBreakdown} />
                  </Column>
                  <Column width="1/4">
                    <PercentageWheel
                      text={t('rate salary as high or average')}
                      model={{ percentage: model.salaryRating }}
                      mode="view"
                      isMoney
                    />
                  </Column>
                  <Column width="1/4">
                    <PercentageWheel
                      text={t('employees recommend this employer to friends')}
                      model={{ percentage: model.employeeRecommendationRating }}
                      mode="view"
                    />
                  </Column>
                </Columns>
              </Stack>
            </Card>
            {!isOnAboutPage && (
              <Card>
                <ReviewSummaryBreakdown
                  model={model.companyReviewSummaryBreakdown}
                />
              </Card>
            )}
          </Stack>
          <Text align="left" size="xsmall">
            {t(
              'Your trust is our main concern so these ratings for {company_name} are shared as-is from employees in line with our',
              { company_name: model.name },
            )}{' '}
            <TextLink href={communityGuidelineURL} rel="nofollow">
              {t('community guidelines')}
            </TextLink>
          </Text>
        </Stack>
        {isOnAboutPage && (
          <Actions>
            <Inline space="none">
              <ButtonLink
                href={link || '#'}
                onClick={() => {
                  if (trackReviewsClicked) {
                    trackReviewsClicked();
                  }
                  if (onActiveTabChanged) {
                    onActiveTabChanged(CompanyProfilesPage.Reviews);
                  }
                }}
                icon={<IconArrow direction="right" />}
                iconPosition="trailing"
              >
                {t('See all reviews')}
              </ButtonLink>
            </Inline>
          </Actions>
        )}
      </Stack>
    </Section>
  );
}
