import {
  Box,
  Column,
  Columns,
  Loader,
  Stack,
  Strong,
  Text,
  TextDropdown,
} from 'braid-design-system';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import { type Reviews, type SortOrder } from '@seek/libs-shared';
import type { FlagReviewPayload } from '../../../../';
import { ReviewRow } from '../reviewRow/ReviewRow';
import { useEffect, useState } from 'react';
import { StickyFooter } from '../stickyFooter/stickyFooter';
import { GhostReview } from '../../ghostReview/GhostReview';

interface Props {
  model?: Reviews;
  upvoteReview: ({
    reviewId,
    companyId,
  }: {
    reviewId: string;
    companyId: string;
  }) => Promise<void>;
  flagReview: (
    companyId: string,
    reviewId: string,
    payload: FlagReviewPayload,
  ) => Promise<boolean>;
  sortOrder: SortOrder;
  onSortOrderChange: (value: SortOrder) => void;
  shouldProtectReviews: boolean;
  reviewsLoaded: boolean;
  trackSignInClicked: () => void;
  trackRegisterClicked: () => void;
}

export const ReviewsList = ({
  model,
  upvoteReview,
  flagReview,
  onSortOrderChange,
  sortOrder,
  shouldProtectReviews,
  reviewsLoaded,
  trackSignInClicked,
  trackRegisterClicked,
}: Props) => {
  const { t } = useTranslations(translations);
  const [modifiedUpvotedReviewsIds, setModifiedUpvotedReviewsIds] = useState<
    string[]
  >(model?.upvotedIds?.upvotes || []);
  const [newUpvotedReviewsIds, setNewUpvotedReviewsIds] = useState<string[]>(
    [],
  );

  const [isStickyFooterVisible, setIsStickyFooterVisible] =
    useState<boolean>(false);

  const total = model?.paging?.total || 0;
  const PLACEHOLDER_REVIEWS_COUNT = 9;
  const lastReviewIndex = PLACEHOLDER_REVIEWS_COUNT - 1;

  const handleScroll = () => {
    const DISPLAY_FOOTER_BUFFER = 300;
    const firstReview = document.querySelector('#review-card-0');
    const lastGhostReview = document.querySelector(
      `#ghost-review-${lastReviewIndex}`,
    );

    if (firstReview && lastGhostReview) {
      const reviewBoundingBox = firstReview.getBoundingClientRect();
      const ghostReviewBoundingBox = lastGhostReview.getBoundingClientRect();

      const bottomOfFirstReview = reviewBoundingBox.bottom;
      const bottomOfLastGhostReview = ghostReviewBoundingBox.bottom;

      const scrollPosition = window.innerHeight;

      if (
        scrollPosition > bottomOfFirstReview + DISPLAY_FOOTER_BUFFER &&
        scrollPosition < bottomOfLastGhostReview + DISPLAY_FOOTER_BUFFER
      ) {
        setIsStickyFooterVisible(true);
      } else {
        setIsStickyFooterVisible(false);
      }
    }
  };

  useEffect(() => {
    setModifiedUpvotedReviewsIds((prev) => [
      ...new Set([...prev, ...(model?.upvotedIds?.upvotes || [])]),
    ]);
    handleScroll(); // Check if sticky footer should be visible on initial render
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [model?.upvotedIds?.upvotes]);

  if (!model) {
    return <Loader />;
  }

  const injectModelOnUpvote = async ({
    reviewId,
    companyId,
  }: {
    reviewId: string;
    companyId: string;
  }): Promise<void> => {
    setModifiedUpvotedReviewsIds((prev) => [...new Set([...prev, reviewId])]);
    setNewUpvotedReviewsIds((prev) => [...new Set([...prev, reviewId])]);
    await upvoteReview({ reviewId, companyId });
  };

  return (
    <>
      <Stack space="large">
        <Columns space="small" collapseBelow="tablet">
          <Column>
            <Text size="small">
              {t('Showing <Strong>{total}</Strong> reviews sorted by', {
                Strong: (children) => <Strong>{children}</Strong>,
                total: `${total}`,
              })}{' '}
              <Strong>{t(`${sortOrder}`)}</Strong>
            </Text>
          </Column>
          <Column width="content">
            {!shouldProtectReviews && (
              <Text size="small">
                {t('Sort by')}{' '}
                <Strong>
                  <TextDropdown
                    id="sortDropdown"
                    label={t('Sort by')}
                    value={sortOrder}
                    onChange={onSortOrderChange}
                    options={[
                      {
                        text: t('most recent'),
                        value: 'most recent',
                      },
                      {
                        text: t('most helpful'),
                        value: 'most helpful',
                      },
                    ]}
                  />
                </Strong>
              </Text>
            )}
          </Column>
        </Columns>
        <Stack space="small">
          {model.reviews?.map((review, index) => (
            <ReviewRow
              key={index}
              model={review}
              isOnReviewPage={false}
              upvoteReview={injectModelOnUpvote}
              newUpvotedReviewsIds={newUpvotedReviewsIds}
              flagReview={flagReview}
              upvotedReviewsIds={modifiedUpvotedReviewsIds}
              index={index}
            />
          ))}
          {(shouldProtectReviews ||
            (!shouldProtectReviews && !reviewsLoaded)) && (
            <>
              <Box>
                <Stack space="small">
                  {[...Array(PLACEHOLDER_REVIEWS_COUNT)].map((_, index) => (
                    <GhostReview key={index} index={index} />
                  ))}
                </Stack>
              </Box>
            </>
          )}
        </Stack>
        {shouldProtectReviews && isStickyFooterVisible && (
          <StickyFooter
            trackSignInClicked={trackSignInClicked}
            trackRegisterClicked={trackRegisterClicked}
          />
        )}
      </Stack>
    </>
  );
};
