export const CultureAndValuesIcon = () => (
  <svg
    width="128"
    height="128"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_2)">
      <path
        d="M49.94 16C39.01 16 31.71 24.97 31.71 34.96C31.71 44.33 38.55 52.43 45.06 58.82C51.57 65.21 57.81 69.95 63.13 72.7C63.55 72.91 64.04 72.91 64.45 72.7C69.78 69.95 76.02 65.21 82.52 58.82C89.03 52.43 95.87 44.33 95.87 34.96C95.87 24.97 88.57 16 77.64 16C71.2 16 66.92 19.28 63.79 23.72C60.66 19.28 56.38 16 49.94 16Z"
        fill="#EE399E"
      />
      <path
        d="M101.11 110.81L101 103.6L114.07 87.06C114.16 86.97 114.26 86.87 114.37 86.77C115.52 85.76 116.37 84.45 116.82 82.99C117.33 81.21 117.51 79.36 117.36 77.52L118.58 49.33C118.58 46.35 118.58 42.86 113.43 42.86C110.11 42.77 109.27 45.4 108.45 49.12C108.45 49.12 105.28 72.88 105.25 73.92C105.16 73.55 105.84 72.26 104.11 70.97C101.64 68.77 97.49 70.33 95.28 72.79L81.03 87.36C77.28 91.55 77.47 94.34 77.78 98.96C77.82 99.52 77.86 100.11 77.89 100.74L78.24 111.09C78.26 111.63 78.71 112.06 79.25 112.06L100.12 111.84C100.67 111.84 101.12 111.38 101.11 110.82V110.81Z"
        fill="#F4BAAF"
      />
      <path
        d="M96.05 90.6C95.82 90.6 95.6 90.52 95.41 90.37C94.99 90.02 94.93 89.39 95.28 88.96C95.32 88.91 99.13 84.32 102.72 79.24C105.53 75.26 104.45 73.42 104.44 73.4C104.16 72.94 104.27 72.33 104.72 72.03C105.17 71.73 105.76 71.82 106.08 72.26C106.29 72.55 108.01 75.22 104.36 80.4C100.73 85.55 96.87 90.19 96.83 90.24C96.63 90.48 96.35 90.6 96.06 90.6H96.05Z"
        fill="#CE8071"
      />
      <path
        d="M48.33 112.05C48.87 112.05 49.32 111.63 49.34 111.08L49.69 100.73C49.72 100.1 49.76 99.51 49.8 98.95C50.11 94.33 50.3 91.54 46.55 87.35L32.3 72.78C30.09 70.32 25.94 68.75 23.47 70.96C21.74 72.24 22.42 73.54 22.33 73.91C22.3 72.87 19.13 49.11 19.13 49.11C18.31 45.4 17.47 42.76 14.15 42.85C9 42.85 9 46.34 9 49.32L10.22 77.51C10.07 79.35 10.25 81.21 10.76 82.98C11.21 84.44 12.06 85.75 13.21 86.76C13.32 86.87 13.42 86.96 13.51 87.05L26.58 103.59L26.47 110.8C26.47 111.35 26.91 111.81 27.46 111.82L48.33 112.04V112.05Z"
        fill="#F4BAAF"
      />
      <path
        d="M31.53 90.6C31.24 90.6 30.96 90.48 30.76 90.24C30.72 90.19 26.86 85.54 23.23 80.4C19.57 75.22 21.3 72.55 21.51 72.26C21.84 71.81 22.46 71.71 22.91 72.04C23.35 72.36 23.45 72.97 23.15 73.41C23.1 73.5 22.11 75.34 24.87 79.24C28.46 84.32 32.27 88.91 32.31 88.96C32.66 89.38 32.6 90.02 32.18 90.37C31.99 90.53 31.77 90.6 31.54 90.6H31.53Z"
        fill="#CE8071"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_2">
        <rect
          width="109.59"
          height="96.05"
          fill="white"
          transform="translate(9 16)"
        />
      </clipPath>
    </defs>
  </svg>
);
