import type { MonthPickerValue, QuestionProps } from '../../../type';
import { Box, Checkbox, Inline, MonthPicker } from 'braid-design-system';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import { useConfig } from '@seek/libs-shared';
import { getMonthAbbreviations } from './utils';
import * as styles from './WorkHistoryQuestion.css';

type FinishQuestionProps = QuestionProps<MonthPickerValue> & {
  isStillWorkHere: boolean;
  onIsStillWorkHereChange: () => void;
};

export const FinishQuestion = ({
  value,
  message,
  tone,
  isStillWorkHere,
  onChange,
  onIsStillWorkHereChange,
}: FinishQuestionProps) => {
  const { t } = useTranslations(translations);
  const { locale } = useConfig();

  const months = getMonthAbbreviations(locale);

  return (
    <Inline collapseBelow="tablet" alignY="center" space="medium">
      <Box
        className={[
          isStillWorkHere
            ? styles.monthPickerInvisible
            : styles.monthPickerTransition,
          styles.monthPickerWidth,
        ]}
      >
        <MonthPicker
          id="whenFinish"
          label={t('When did you finish')}
          onChange={onChange}
          value={value ?? {}}
          monthNames={months}
          reserveMessageSpace
          message={message}
          tone={tone}
        />
      </Box>
      <Box
        className={[
          styles.checkBox,
          isStillWorkHere ? styles.checkBoxTransition : undefined,
        ]}
      >
        <Checkbox
          id="stillWorkHere"
          label={t('I still work here')}
          checked={Boolean(isStillWorkHere)}
          onChange={onIsStillWorkHereChange}
        />
      </Box>
    </Inline>
  );
};
