import {
  ApacCandidateHeader,
  ApacCandidateFooter,
} from '@seek/apac-candidate-header-footer';
import { Box, Page, PageBlock, Stack } from 'braid-design-system';

import { useAuth } from '../../../shared/providers/authProvider';

import { getLanguageSwitcher } from './languageSwitcher';
import { useConfig } from '@seek/libs-shared';
import type { AppConfig } from '../../../shared/config';

import * as styles from './MainLayout.css';

interface Props extends React.PropsWithChildren {
  pageWidth?: 'large' | 'medium' | undefined;
  showHeaderDivider?: boolean;
  showMarginTopAndBottom?: boolean;
}

export function MainLayout({
  children,
  pageWidth = 'large',
  showHeaderDivider = false,
  showMarginTopAndBottom = true,
}: Props) {
  const config = useConfig<AppConfig>();
  const { country, path } = config;
  const { authenticationStatus, userProfile } = useAuth();

  const languageSwitcher: Partial<Record<string, { url: string }>> =
    getLanguageSwitcher(path, country);

  const handleLanguageSwitch = (lang: string) => {
    if (languageSwitcher[lang]) {
      const switcherUrl = languageSwitcher[lang]?.url;
      const prefixUrl = `${switcherUrl?.split('/companies')[0]}/companies`;
      const currentUrl = location.href.split('/companies')[1];
      const newUrl = prefixUrl + currentUrl;

      location.replace(newUrl);
    }
  };

  return (
    <Page
      footer={
        <Box className={styles.footerWrapper}>
          <ApacCandidateFooter
            authenticationStatus={authenticationStatus}
            isExpandedVersion
          />
        </Box>
      }
      footerPosition="belowFold"
    >
      <Stack space={showMarginTopAndBottom ? 'large' : 'none'}>
        <ApacCandidateHeader
          authenticationStatus={authenticationStatus}
          userProfile={userProfile}
          returnUrlForPostAuth="/companies"
          activePrimaryTab="company"
          languageSwitcher={languageSwitcher}
          divider={showHeaderDivider}
          onLanguageSwitch={handleLanguageSwitch}
        />
        <PageBlock width={pageWidth}>
          <Stack space="gutter">
            <Box className={styles.body}>{children}</Box>
          </Stack>
        </PageBlock>
      </Stack>
    </Page>
  );
}
