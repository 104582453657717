import { Autosuggest, IconSearch } from 'braid-design-system';
import type { QuestionProps } from '../../../type';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import type { Zone } from '@seek/libs-shared';
import type { LocationSuggestion } from '../../../../shared/services/locationService/locationService';

export interface LocationQuestionProps
  extends QuestionProps<LocationSuggestion> {
  text?: string;
  zone: Zone;
  searchLocations: (query: string) => Promise<LocationSuggestion[]>;
}

interface AutosuggestValue {
  text: string;
  value?: number;
}

export const LocationQuestion = ({
  message = '',
  value,
  tone,
  onChange,
  searchLocations,
}: LocationQuestionProps) => {
  const MINIMUM_CHARACTERS_SEARCH = 3;
  const [suggestions, setSuggestions] = useState<AutosuggestValue[]>([]);
  const [searchTerm, setSearchTerm] = useState<AutosuggestValue>({
    value: value?.id,
    text: value?.text || '',
  });
  const [debouncedSearchTerm] = useDebounce(searchTerm, 400);
  const { t } = useTranslations(translations);

  const handleOnChangeAutosuggest = (event: AutosuggestValue) => {
    setSearchTerm(event);
    if (!event.text || event.text.length < MINIMUM_CHARACTERS_SEARCH) {
      setSuggestions([]);
      onChange({});
      return;
    }
    if (event.value) {
      onChange({
        text: event.text,
        id: event.value,
      });
    } else {
      onChange({});
    }
  };

  const onClear = () => {
    setSearchTerm({ value: undefined, text: '' });
    onChange({});
  };

  useEffect(() => {
    const fetchLocations = async () => {
      const suggestionList = await searchLocations(debouncedSearchTerm.text);
      setSuggestions(
        suggestionList?.map(
          (item) =>
            ({
              text: item.text,
              value: item.id,
            }) as AutosuggestValue,
        ) || [],
      );
    };

    if (debouncedSearchTerm.text.trim().length >= MINIMUM_CHARACTERS_SEARCH) {
      fetchLocations();
    }
  }, [debouncedSearchTerm]);

  return (
    <Autosuggest
      id="location"
      type="text"
      label={t('Location')}
      aria-label={t('Location')}
      icon={<IconSearch />}
      onChange={handleOnChangeAutosuggest}
      suggestions={suggestions}
      onClear={onClear}
      value={searchTerm}
      tone={tone}
      message={message}
      suggestionHighlight={'matching'}
      automaticSelection={true}
      reserveMessageSpace={true}
    />
  );
};
