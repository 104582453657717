import type { MelwaysZone, Country } from '@seek/melways-sites';

export const getMelwaysZoneByCountryCode = (
  countryCode: Uppercase<Country>,
): MelwaysZone => {
  switch (countryCode) {
    case 'NZ':
      return 'anz-2';
    case 'HK':
      return 'asia-1';
    case 'TH':
      return 'asia-3';
    case 'ID':
      return 'asia-4';
    case 'MY':
      return 'asia-5';
    case 'PH':
      return 'asia-6';
    case 'SG':
      return 'asia-7';
    default:
      return 'anz-1';
  }
};
