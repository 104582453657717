import { Hidden, Stack } from 'braid-design-system';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router';
import { useMelwaysLink } from '@seek/melways-react';

import {
  AwardsSection,
  CompanyOverviewSection,
  FeaturedSection,
  StatementSection,
  GallerySection,
  useAppContext,
  Section,
} from '@seek/libs-shared';

import type { AboutModel } from './aboutModel';
import { useConfig } from '../../../../../shared/src/providers/configProvider';
import { CompanyProfilesPage, ROOT_PATH } from '../../../paths';
import { trackViewEvents } from '../../../shared/trackViewEvents';
import { ReviewsSummarySection } from '../../components/companyReviews/reviewsSummarySection/ReviewsSummarySection';
import { useAuth } from '../../../shared/providers/authProvider';
import { AUTHENTICATION_TYPES } from '@seek/apac-candidate-header-footer/types';
import { CompaniesList } from '../../components/companiesList/CompaniesList';
import { SeoCtaBlurb } from '../../components/seoCtaBlurb/SeoCtaBlurb';

interface AboutPageProps {
  model: AboutModel;
  trackRandomCompanyProfileLinkClicked: ({
    linkedCompanyId,
  }: {
    linkedCompanyId: string;
  }) => void;
  trackWriteAReviewClicked: () => void;
  trackReviewsClicked: () => void;
  trackSeeAllPhotosButtonClicked: () => void;
  trackWebsiteClicked: () => void;
  onActiveTabChanged: (item: string | undefined) => void;
  getCompanyProfilesPageUrl: (p: CompanyProfilesPage) => string;
}

export function AboutPage({
  model,
  trackRandomCompanyProfileLinkClicked,
  trackWriteAReviewClicked,
  trackReviewsClicked,
  trackSeeAllPhotosButtonClicked,
  trackWebsiteClicked,
  onActiveTabChanged,
  getCompanyProfilesPageUrl,
}: AboutPageProps) {
  const config = useConfig();
  const { language } = config;
  const { companyId, companyName, ...context } = useAppContext();
  const urlParams = useParams();
  const localize = useMelwaysLink();
  const { authenticationStatus } = useAuth();
  const [params] = useSearchParams();

  const isDraftLink = params.get('draft') === 'true' ? '?draft=true' : '';

  useEffect(() => {
    if (
      companyId &&
      companyName &&
      authenticationStatus !== AUTHENTICATION_TYPES.AUTH_PENDING
    )
      trackViewEvents.aboutPage({
        config,
        context: { ...context, companyId, companyName },
      });
  }, [companyId, companyName, authenticationStatus]);

  const shouldDisplayOverviewSection = Boolean(
    model.overview &&
      Object.keys(model.overview).length > 0 &&
      Object.values(model.overview).some(Boolean),
  );

  const shouldDisplayFeaturedSection = Boolean(
    model.featured && Object.keys(model.featured).length > 0,
  );

  const shouldDisplayAwardsSection = Boolean(
    model.awards && Object.keys(model.awards).length > 0,
  );

  return (
    <>
      <Stack space={['none', 'gutter']}>
        {shouldDisplayOverviewSection && (
          <CompanyOverviewSection
            mode="view"
            model={{ ...model.overview, name: model.name }}
            trackWebsiteClicked={trackWebsiteClicked}
          />
        )}
        {model.missionStatement?.description &&
        model.missionStatement?.description.length > 0 ? (
          <StatementSection
            mode="view"
            model={model.missionStatement}
            isMissionStatement
          />
        ) : null}
        {model.reviewSummary && (
          <ReviewsSummarySection
            model={{ ...model.reviewSummary, name: model.name }}
            isOnAboutPage
            onActiveTabChanged={onActiveTabChanged}
            trackWriteAReviewClicked={trackWriteAReviewClicked}
            trackReviewsClicked={trackReviewsClicked}
            link={localize({
              path: `/${ROOT_PATH}/${urlParams.companySlug}/${CompanyProfilesPage.Reviews}${isDraftLink}`,
              language,
            })}
          />
        )}
        {shouldDisplayFeaturedSection ? (
          <FeaturedSection mode="view" model={model.featured} />
        ) : null}
        {shouldDisplayAwardsSection ? (
          <AwardsSection mode="view" model={model.awards} />
        ) : null}
        {model.gallery && model.gallery.photos.length > 0 ? (
          <GallerySection
            mode="view"
            model={model.gallery}
            link={localize({
              path: `/${ROOT_PATH}/${urlParams.companySlug}/${CompanyProfilesPage.Culture}${isDraftLink}`,
              language,
            })}
            onActiveTabChanged={onActiveTabChanged}
            trackSeeAllPhotosButtonClicked={trackSeeAllPhotosButtonClicked}
          />
        ) : null}
        <SeoCtaBlurb
          companyName={model.name}
          getCompanyProfilesPageUrl={getCompanyProfilesPageUrl}
          onActiveTabChanged={onActiveTabChanged}
        />
      </Stack>
      <Hidden below="tablet">
        <Section>
          <CompaniesList
            title="Other companies in this industry"
            allCompanies={model.randomCompaniesInThisIndustry}
            trackCompanyProfileLinkClicked={
              trackRandomCompanyProfileLinkClicked
            }
          />
        </Section>
      </Hidden>
    </>
  );
}
