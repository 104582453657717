import {
  Stepper,
  Step,
  Stack,
  Heading,
  Text,
  Box,
  Button,
  IconArrow,
  Actions,
  Spread,
  Inline,
  Hidden,
} from 'braid-design-system';
import * as styles from './ReviewPageLayout.css';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import type { Progress } from '../../type';

type ReviewPageLayoutProps = React.PropsWithChildren<{
  progress?: Progress;
  companyName?: string;
  subtitle?: string;
  logo?: string;
  testid?: string;
  onContinue?: () => void;
  onBack?: () => void;
}>;

export const ReviewPageLayout = ({
  progress = 1,
  companyName,
  subtitle,
  logo,
  children,
  testid,
  onContinue,
  onBack,
}: ReviewPageLayoutProps) => {
  const { t } = useTranslations(translations);
  return (
    <Box
      data={testid ? { testid } : {}}
      paddingTop={{
        tablet: 'xxlarge',
        mobile: 'large',
      }}
    >
      <Stack space="large">
        {logo ? (
          <Box
            component="img"
            src={logo}
            alt="company logo"
            className={styles.logoBox}
          ></Box>
        ) : null}
        <Heading level="1">
          {companyName
            ? t('Review working at {companyName}', { companyName })
            : t('Write a company review')}
        </Heading>
        <Stepper label="Review Stepper" progress={progress} align="left">
          <Step>{t('Overall rating')}</Step>
          <Step>{t('Your role')}</Step>
          <Step>{t('Your experience')}</Step>
          <Step>{t('Your salary')}</Step>
          <Step>{t('Your feedback')}</Step>
        </Stepper>
        <Stack space="xlarge">
          <Box className={styles.questionsBox}>
            <Stack space="xlarge">
              {subtitle ? <Text size="standard">{subtitle}</Text> : null}
              <Box>{children}</Box>
            </Stack>
          </Box>
          <Hidden below="tablet">
            <Spread space="large">
              <Actions>
                {onBack ? (
                  <Button
                    variant="soft"
                    tone="neutral"
                    icon={<IconArrow direction="left" />}
                    onClick={onBack}
                  >
                    {t('Back')}
                  </Button>
                ) : null}
              </Actions>
              <Actions>
                {onContinue ? (
                  <Button
                    variant="solid"
                    tone="formAccent"
                    icon={<IconArrow direction="right" />}
                    onClick={onContinue}
                    data={{ testid: 'continue-button-desktop' }}
                  >
                    {t('Continue')}
                  </Button>
                ) : null}
              </Actions>
            </Spread>
          </Hidden>
          <Hidden above="mobile">
            <Inline space="medium" collapseBelow="tablet">
              {onContinue ? (
                <Button
                  variant="solid"
                  tone="formAccent"
                  icon={<IconArrow direction="right" />}
                  onClick={onContinue}
                >
                  {t('Continue')}
                </Button>
              ) : null}
              {onBack ? (
                <Button
                  variant="soft"
                  tone="neutral"
                  icon={<IconArrow direction="left" />}
                  onClick={onBack}
                >
                  {t('Back')}
                </Button>
              ) : null}
            </Inline>
          </Hidden>
        </Stack>
      </Stack>
    </Box>
  );
};
