import { IconStar, Box } from 'braid-design-system';

export const Star = ({
  isSelected,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: {
  isSelected: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick: () => void;
}) => {
  return (
    <Box
      role="button"
      paddingRight="xsmall"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      cursor="pointer"
    >
      <IconStar
        active={isSelected}
        tone={isSelected ? 'brandAccent' : 'secondary'}
      />
    </Box>
  );
};
