import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import { RatingQuestion } from './RatingQuestion';
import type { QuestionProps } from '../../type';
import type { Rating } from '../../components/StarSelector/StarSelector';

export const CareerOpportunityRatingQuestion = (
  props: QuestionProps<Rating>,
) => {
  const { t } = useTranslations(translations);
  return (
    <RatingQuestion {...props} title={t('Career development opportunities')} />
  );
};
