import {
  type ClientOptions,
  createClient,
  createMockClient,
} from '@seek/ca-graphql-client';
import { browserHttpLink } from '@seek/ca-graphql-client/browser';
import { nodeHttpLink } from '@seek/ca-graphql-client/node';
import type { AppConfig } from '../config';
import { getHeaders } from '@seek/libs-shared';
import { createAbsoluteUrl } from '@seek/melways-sites';

const getCandidateGraphqlEndpoint = (config: AppConfig) => {
  const { environment, product, country } = config;

  if (environment === 'production' && typeof window === 'undefined') {
    return 'http://candidate-graphql.int-cloud.seek.com.au';
  }

  if (environment === 'production') {
    return undefined;
  }

  if (environment === 'staging') {
    const url = createAbsoluteUrl({
      product,
      country,
      localized: false,
      path: '/graphql',
      staging: true,
      ...(config.consolidated ? { alternative: 'subDomainRegion' } : {}),
    });
    return url;
  }

  return 'https://www.staging.seek.com.au/graphql';
};

export const createCandidateGraphqlClient = (
  config: AppConfig,
  options?: Partial<ClientOptions>,
) => {
  if (config.environment !== 'local') {
    return createClient({
      appName: config.name,
      headers: getHeaders(config)(),
      httpLink: typeof window === 'undefined' ? nodeHttpLink : browserHttpLink,
      authOptions: {
        useAuth: true,
        forceStrategy: 'AUTH0',
      },
      brand: config.brand.toLowerCase() as ClientOptions['brand'],
      country: config.country.toUpperCase() as ClientOptions['country'],
      graphqlEndpointOverride: getCandidateGraphqlEndpoint(config),
      ...(options || {}),
    });
  }
  return createMockClient();
};
