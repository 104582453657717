import { ReviewPageLayout } from '../components/ReviewPageLayout/ReviewPageLayout';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import type { PageProps, ReviewPayload } from '../type';
import { useConfig, useValidation, type Rules } from '@seek/libs-shared';
import { validationRules } from '../validation/validationRules';
import { Stack } from 'braid-design-system';

import {
  CompanyNameQuestion,
  type CompanyNameValue,
} from '../questions/AutoSuggestionQuestions/CompanyNameQuestion/CompanyNameQuestion';
import { OverallRatingQuestion } from '../questions/RatingQuesions/OverallRatingQuestion';
import type { SearchCompanyProfileResultItem } from '../../shared/services/companyProfileService/types';

export interface OverallRatingPageProps extends PageProps {
  searchCompanyProfiles: (
    query: string,
  ) => Promise<SearchCompanyProfileResultItem[]>;
}

export const OverallRatingPage = ({
  review,
  companyProfile,
  setPage,
  setter,
  searchCompanyProfiles,
  pageNumber,
}: OverallRatingPageProps) => {
  const { t } = useTranslations(translations);
  const { language } = useConfig();

  const fields = {
    companyName: review?.companyName,
    overallRating: review?.overallRating,
  };

  const { companyName, overallRating } = validationRules(language);

  const rules = {
    companyName,
    overallRating,
  } as Rules<typeof fields>;

  const { validate, getTone, getMessage, handleValueChangeWithValidation } =
    useValidation<ReviewPayload>(fields, rules);

  const handleOnContinue = () => {
    const isValid = validate();
    if (!isValid) {
      return;
    }
    setPage(pageNumber + 1);
  };

  const onSearchCompanyProfiles = async (query: string) => {
    return searchCompanyProfiles(query);
  };

  const handleCompanyNameChange = (value: CompanyNameValue) => {
    handleValueChangeWithValidation({
      setter,
      validatorProperty: 'companyName',
      previousValue: review,
    })(value.companyName);

    setter((prev) => ({
      ...prev,
      companyId: value.companyId,
      organisationId: value.organisationId,
    }));
  };

  return (
    <ReviewPageLayout
      progress={pageNumber}
      companyName={companyProfile?.name}
      subtitle={t(
        'Your review will help someone else and it only takes a few minutes. All reviews are anonymous. We wont share your name, only details about your role.',
      )}
      logo={companyProfile?.branding?.logoImageUrl}
      onContinue={handleOnContinue}
      testid="overall-rating-page"
    >
      <Stack space="xlarge">
        {/* ToDo: Below questions are just examples and will be replaced by the actual questions */}
        <CompanyNameQuestion
          onChange={handleCompanyNameChange}
          searchCompanyProfiles={onSearchCompanyProfiles}
          text={review?.companyName}
          value={{
            companyId: review?.companyId,
            organisationId: review?.organisationId,
            companyName: review?.companyName,
          }}
          tone={getTone('companyName')}
          message={getMessage('companyName')}
        />

        <OverallRatingQuestion
          onChange={handleValueChangeWithValidation({
            setter,
            validatorProperty: 'overallRating',
            previousValue: review,
          })}
          value={review?.overallRating}
          message={getMessage('overallRating')}
        />
      </Stack>
    </ReviewPageLayout>
  );
};
