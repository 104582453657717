import { ReviewPageLayout } from '../components/ReviewPageLayout/ReviewPageLayout';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import type { PageProps, ReviewPayload } from '../type';
import { useConfig, useValidation, type Rules } from '@seek/libs-shared';
import { validationRules } from '../validation/validationRules';
import { SalaryQuestion } from '../questions/SalaryQuestions/SalaryQuestion';
import { Stack } from 'braid-design-system';

export const YourSalaryPage = ({
  companyProfile,
  review,
  setPage,
  setter,
  pageNumber,
}: PageProps) => {
  const { t } = useTranslations(translations);

  const { language } = useConfig();
  const { salary } = validationRules(language);

  const fields = {
    salary: review?.salary,
  };

  const rules = {
    salary,
  } as Rules<typeof fields>;

  const { validate, getTone, getMessage, handleValueChangeWithValidation } =
    useValidation<ReviewPayload>(fields, rules);

  const handleOnContinue = () => {
    const isValid = validate();
    if (!isValid) {
      return;
    }
    setPage(pageNumber + 1);
  };

  const handleOnBack = () => {
    setPage(pageNumber - 1);
  };

  return (
    <ReviewPageLayout
      progress={pageNumber}
      companyName={companyProfile?.name}
      subtitle={t(
        'Any salary information you share wont be tied to your review. It will only be used to calculate averages.',
      )}
      onContinue={handleOnContinue}
      onBack={handleOnBack}
      testid="your-salary-page"
    >
      <Stack space="small">
        <SalaryQuestion
          onChange={handleValueChangeWithValidation({
            setter,
            validatorProperty: 'salary',
            previousValue: review,
          })}
          tone={getTone('salary')}
          message={getMessage('salary')}
          value={review?.salary}
        />
      </Stack>
    </ReviewPageLayout>
  );
};
