import {
  Columns,
  Column,
  Stack,
  Heading,
  Text,
  Box,
  Hidden,
  Badge,
} from 'braid-design-system';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';

import { CompanySearchBar } from '../companySearchBar/CompanySearchBar';
import * as styles from './HomePageBanner.css';
import { useConfig } from '@seek/libs-shared';

export function HomePageBanner() {
  const { t } = useTranslations(translations);

  const { country } = useConfig();

  return (
    <Box
      className={styles.wrapper}
      borderRadius={['none', 'xlarge']}
      background="brand"
      padding="xlarge"
    >
      <Columns
        space={{ mobile: 'none', tablet: 'xxlarge' }}
        alignY="center"
        collapseBelow="desktop"
      >
        <Column>
          <Box className={styles.innerWrapper}>
            <Stack space="large">
              <Heading level="1">{t('Find the right company for you')}</Heading>
              <Stack space={['medium', 'xlarge']}>
                <Text size="large">
                  {t(
                    'Everything you need to know about a company, all in one place',
                  )}
                </Text>
              </Stack>
              <Stack space={['medium', 'xxlarge']}>
                <CompanySearchBar />
              </Stack>
              {country === 'nz' && (
                <Stack space={['medium', 'xlarge']}>
                  <Columns space={{ mobile: 'small' }} alignY={'center'}>
                    <Column width="content">
                      <Badge>BETA</Badge>
                    </Column>
                    <Column>
                      <Text size="large">
                        {t('More companies added daily')}
                      </Text>
                    </Column>
                  </Columns>
                </Stack>
              )}
            </Stack>
          </Box>
        </Column>
        <Hidden below="desktop">
          <Column>
            <Box display={{ mobile: 'none', desktop: 'flex' }}>
              <img
                src="https://image-service-cdn.seek.com.au/309fe98168ffe880c202528ddf1f363d98f039ba/"
                alt="landing page"
                width="100%"
              />
            </Box>
          </Column>
        </Hidden>
      </Columns>
    </Box>
  );
}
