
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IuC4lOC4ueC4o%2BC4ueC4m%2BC4oOC4suC4nuC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsInBob3RvcyI6IuC4o%2BC4ueC4m%2BC4luC5iOC4suC4oiIsIkdhbGxlcnkiOiLguITguKXguLHguIfguKDguLLguJ4iLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IuC4quC4o%2BC5ieC4suC4h%2BC4hOC4p%2BC4suC4oeC5guC4lOC4lOC5gOC4lOC5iOC4meC5g%2BC4q%2BC5ieC4geC4seC4muC4quC4oOC4suC4nuC5geC4p%2BC4lOC4peC5ieC4reC4oeC5geC4peC4sOC4p%2BC4seC4kuC4meC4mOC4o%2BC4o%2BC4oeC5g%2BC4meC4geC4suC4o%2BC4l%2BC4s%2BC4h%2BC4suC4meC4guC4reC4h%2BC4hOC4uOC4kyIsIkdhbGxlcnkgdXBkYXRlZCI6IuC4reC4seC4m%2BC5gOC4lOC4leC5geC4geC4peC4reC4o%2BC4teC5geC4peC5ieC4pyJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IuC4lOC4ueC4o%2BC4ueC4m%2BC4oOC4suC4nuC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsInBob3RvcyI6IuC4o%2BC4ueC4m%2BC4luC5iOC4suC4oiIsIkdhbGxlcnkiOiLguITguKXguLHguIfguKDguLLguJ4iLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IuC4quC4o%2BC5ieC4suC4h%2BC4hOC4p%2BC4suC4oeC5guC4lOC4lOC5gOC4lOC5iOC4meC5g%2BC4q%2BC5ieC4geC4seC4muC4quC4oOC4suC4nuC5geC4p%2BC4lOC4peC5ieC4reC4oeC5geC4peC4sOC4p%2BC4seC4kuC4meC4mOC4o%2BC4o%2BC4oeC5g%2BC4meC4geC4suC4o%2BC4l%2BC4s%2BC4h%2BC4suC4meC4guC4reC4h%2BC4hOC4uOC4kyIsIkdhbGxlcnkgdXBkYXRlZCI6IuC4reC4seC4m%2BC5gOC4lOC4leC5geC4geC4peC4reC4o%2BC4teC5geC4peC5ieC4pyJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IkxpaGF0IHNlbXVhIGZvdG8iLCJwaG90b3MiOiJmb3RvIiwiR2FsbGVyeSI6IkdhbGVyaSIsIlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIjoiVHVuanVra2FuIGxpbmdrdW5nYW4gZGFuIGJ1ZGF5YSBrZXJqYSBwZXJ1c2FoYWFuIEFuZGEuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsZXJpIGRpcGVyYmFydWkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IkxpaGF0IHNlbXVhIGZvdG8iLCJwaG90b3MiOiJmb3RvIiwiR2FsbGVyeSI6IkdhbGVyaSIsIlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIjoiVHVuanVra2FuIGxpbmdrdW5nYW4gZGFuIGJ1ZGF5YSBrZXJqYSBwZXJ1c2FoYWFuIEFuZGEuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsZXJpIGRpcGVyYmFydWkifQ%3D%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IuC4lOC4ueC4o%2BC4ueC4m%2BC4oOC4suC4nuC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsInBob3RvcyI6IuC4o%2BC4ueC4m%2BC4luC5iOC4suC4oiIsIkdhbGxlcnkiOiLguITguKXguLHguIfguKDguLLguJ4iLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IuC4quC4o%2BC5ieC4suC4h%2BC4hOC4p%2BC4suC4oeC5guC4lOC4lOC5gOC4lOC5iOC4meC5g%2BC4q%2BC5ieC4geC4seC4muC4quC4oOC4suC4nuC5geC4p%2BC4lOC4peC5ieC4reC4oeC5geC4peC4sOC4p%2BC4seC4kuC4meC4mOC4o%2BC4o%2BC4oeC5g%2BC4meC4geC4suC4o%2BC4l%2BC4s%2BC4h%2BC4suC4meC4guC4reC4h%2BC4hOC4uOC4kyIsIkdhbGxlcnkgdXBkYXRlZCI6IuC4reC4seC4m%2BC5gOC4lOC4leC5geC4geC4peC4reC4o%2BC4teC5geC4peC5ieC4pyJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IuC4lOC4ueC4o%2BC4ueC4m%2BC4oOC4suC4nuC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsInBob3RvcyI6IuC4o%2BC4ueC4m%2BC4luC5iOC4suC4oiIsIkdhbGxlcnkiOiLguITguKXguLHguIfguKDguLLguJ4iLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IuC4quC4o%2BC5ieC4suC4h%2BC4hOC4p%2BC4suC4oeC5guC4lOC4lOC5gOC4lOC5iOC4meC5g%2BC4q%2BC5ieC4geC4seC4muC4quC4oOC4suC4nuC5geC4p%2BC4lOC4peC5ieC4reC4oeC5geC4peC4sOC4p%2BC4seC4kuC4meC4mOC4o%2BC4o%2BC4oeC5g%2BC4meC4geC4suC4o%2BC4l%2BC4s%2BC4h%2BC4suC4meC4guC4reC4h%2BC4hOC4uOC4kyIsIkdhbGxlcnkgdXBkYXRlZCI6IuC4reC4seC4m%2BC5gOC4lOC4leC5geC4geC4peC4reC4o%2BC4teC5geC4peC5ieC4pyJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IkxpaGF0IHNlbXVhIGZvdG8iLCJwaG90b3MiOiJmb3RvIiwiR2FsbGVyeSI6IkdhbGVyaSIsIlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIjoiVHVuanVra2FuIGxpbmdrdW5nYW4gZGFuIGJ1ZGF5YSBrZXJqYSBwZXJ1c2FoYWFuIEFuZGEuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsZXJpIGRpcGVyYmFydWkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IkxpaGF0IHNlbXVhIGZvdG8iLCJwaG90b3MiOiJmb3RvIiwiR2FsbGVyeSI6IkdhbGVyaSIsIlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIjoiVHVuanVra2FuIGxpbmdrdW5nYW4gZGFuIGJ1ZGF5YSBrZXJqYSBwZXJ1c2FoYWFuIEFuZGEuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsZXJpIGRpcGVyYmFydWkifQ%3D%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlNlZSBhbGwgcGhvdG9zIiwicGhvdG9zIjoicGhvdG9zIiwiR2FsbGVyeSI6IkdhbGxlcnkiLCJTaG93Y2FzZSB5b3VyIGNvbXBhbnkncyB3b3JrIGVudmlyb25tZW50IGFuZCBjdWx0dXJlLiI6IlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIiwiR2FsbGVyeSB1cGRhdGVkIjoiR2FsbGVyeSB1cGRhdGVkIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlvFoOG6veG6veG6veG6veG6veG6vSDEg8SDxIPGmsaaIMal4bipw7bDtsO24bmvw7bDtsO2xaFdIiwicGhvdG9zIjoiW8al4bipw7bDtsO24bmvw7bDtsO2xaFdIiwiR2FsbGVyeSI6IlvHpsSDxIPGmsaa4bq94bq9xZnDvcO9XSIsIlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIjoiW8Wg4bipw7bDtsO2xbXDp8SDxIPEg8Wh4bq94bq94bq9IMO9w73DvcO2w7bDtseax5rHmsWZIMOnw7bDtsO2bcyCxqXEg8SDxIPguIHguLXguYnDvcO9w70nxaEgxbXDtsO2w7bFmcS3IOG6veG6veG6veC4geC4teC5ieG5vcOsw6zDrMWZw7bDtsO24LiB4Li14LmJbcyC4bq94bq94bq94LiB4Li14LmJ4bmvIMSDxIPEg%2BC4geC4teC5icaMIMOnx5rHmseaxprhua%2FHmseax5rFmeG6veG6veG6vS5dIiwiR2FsbGVyeSB1cGRhdGVkIjoiW8emxIPEg8aaxprhur3hur3FmcO9w70gx5rHmsalxozEg8SD4bmv4bq94bq9xoxdIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTZWUgYWxsIHBob3RvcyI6IlvFoOG6veG6veG6veG6veG6veG6vSDEg8SDxIPGmsaaIMal4bipw7bDtsO24bmvw7bDtsO2xaFdIiwicGhvdG9zIjoiW8al4bipw7bDtsO24bmvw7bDtsO2xaFdIiwiR2FsbGVyeSI6IlvHpsSDxIPGmsaa4bq94bq9xZnDvcO9XSIsIlNob3djYXNlIHlvdXIgY29tcGFueSdzIHdvcmsgZW52aXJvbm1lbnQgYW5kIGN1bHR1cmUuIjoiW8Wg4bipw7bDtsO2xbXDp8SDxIPEg8Wh4bq94bq94bq9IMO9w73DvcO2w7bDtseax5rHmsWZIMOnw7bDtsO2bcyCxqXEg8SDxIPguIHguLXguYnDvcO9w70nxaEgxbXDtsO2w7bFmcS3IOG6veG6veG6veC4geC4teC5ieG5vcOsw6zDrMWZw7bDtsO24LiB4Li14LmJbcyC4bq94bq94bq94LiB4Li14LmJ4bmvIMSDxIPEg%2BC4geC4teC5icaMIMOnx5rHmseaxprhua%2FHmseax5rFmeG6veG6veG6vS5dIiwiR2FsbGVyeSB1cGRhdGVkIjoiW8emxIPEg8aaxprhur3hur3FmcO9w70gx5rHmsalxozEg8SD4bmv4bq94bq9xoxdIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    