import type { RouteObject } from 'react-router';

import { App } from './App';
import { createCompanyProfilesRoutes } from './companyProfiles/routes';
import type { RouteProps } from './shared/RouteProps';
import { VocabProvider } from '@vocab/react';
import { BraidProvider, Page } from 'braid-design-system';
import { ErrorBoundary } from '@seek/libs-shared';
import theme from 'braid-design-system/themes/seekJobs';
import {
  ApacCandidateFooter,
  ApacCandidateHeader,
} from '@seek/apac-candidate-header-footer';
import { AUTHENTICATION_TYPES } from '@seek/apac-candidate-header-footer/types';
import { MelwaysProvider } from '@seek/melways-react';
import { createCompanyReviewFormRoutes } from './companyReviewForm/routes';
import { CompanyProfilesPage } from './paths';

export const PATHS = {
  LANGUAGE: '/:language?',
};

export const createRoutes = (props: RouteProps): RouteObject[] => [
  {
    path: PATHS.LANGUAGE,
    errorElement: (
      <MelwaysProvider.Server
        site={props.config.siteName as any}
        url={props.config.absoluteUrl}
        alternative={props.config.consolidated ? 'subDomainRegion' : undefined}
        staging={
          props.config.environment === 'staging' ||
          props.config.environment === 'development'
        }
      >
        <VocabProvider language={props.config.locale}>
          <BraidProvider theme={theme}>
            <Page
              footer={
                <ApacCandidateFooter
                  authenticationStatus={AUTHENTICATION_TYPES.AUTH_PENDING}
                />
              }
              footerPosition="belowFold"
            >
              <ApacCandidateHeader
                authenticationStatus={AUTHENTICATION_TYPES.AUTH_PENDING}
              />
              <ErrorBoundary logger={props.logger} config={props.config} />
            </Page>
          </BraidProvider>
        </VocabProvider>
      </MelwaysProvider.Server>
    ),
    element: <App {...props} />,
    children: [
      {
        path: CompanyProfilesPage.Home,
        id: 'company-profiles-root',
        children: [
          ...createCompanyProfilesRoutes(props),
          ...createCompanyReviewFormRoutes(props),
        ],
      },
    ],
    id: 'candidate-root',
  },
];
