import type { MkLocationSuggestion } from '@seek/ca-graphql-schema/types';

export const createLocationServiceMock = () => {
  const getLocations = async (
    query: string,
    _sessionId: string,
  ): Promise<MkLocationSuggestion[]> => {
    return [
      {
        id: 4,
        text: 'Bendigo',
        countryCode: 'AU',
      },
      {
        id: 1,
        text: 'Sydney',
        countryCode: 'AU',
      },
      {
        id: 2,
        text: 'Melbourne',
        countryCode: 'AU',
      },
      {
        id: 3,
        text: 'Brisbane',
        countryCode: 'AU',
      },
    ];
  };

  return { getLocations };
};
