import { useRouteError } from 'react-router';

import type { Logger, BaseConfig } from '../../../';
import { ErrorBoundary } from './ErrorBoundary';

interface Props {
  logger: Logger;
  config: BaseConfig;
}

type ErrorObject = Error & {
  status: number;
  graphQLErrors: unknown[];
  networkError: {
    result: unknown[];
  };
};

export function ErrorBoundaryContainer({ logger, config }: Props) {
  const error = useRouteError() as ErrorObject;

  const routeError = {
    ...error,
    graphQLErrorsStringified: JSON.stringify(error.graphQLErrors || []),
    networkError: {
      ...error.networkError,
      result: JSON.stringify(error.networkError?.result || []),
    },
  };

  // If its a 404 error, we don't want to log it as an error
  if (error.status === 404) {
    logger.warn(
      {
        routeError,
      },
      'ErrorBoundary caught an warning',
    );
  } else {
    logger.error(
      {
        routeError,
      },
      'ErrorBoundary caught an error',
    );
  }

  return <ErrorBoundary config={config} />;
}
