import { useTranslations } from '@vocab/react';
import {
  Notice,
  Box,
  Column,
  Columns,
  Heading,
  IconAdd,
  IconEdit,
  Inline,
  Loader,
  Stack,
  Text,
  TextLinkButton,
} from 'braid-design-system';

import translations from './.vocab';
import type { ReactNode, RefObject } from 'react';

type Padding = 'xsmall' | 'small' | 'medium' | 'large' | 'none';

interface Props extends React.PropsWithChildren {
  mode?: 'view' | 'edit';
  heading?: string;
  /**
   * Only used for 'edit' mode
   */
  dataEmpty?: boolean;
  /**
   * Only used for 'edit' mode
   */
  onEditClick?: () => void;
  /**
   * Only used for 'edit' mode
   */
  addDataHint?: string;
  loading?: boolean;
  padding?: Padding;
  paddingX?: Padding;
  paddingY?: Padding;
  paddingBottom?: 'large' | 'none';
  background?: 'surface' | 'body';
}

function LargePaddingCard({
  children,
  ref,
  padding,
  paddingBottom,
  background,
}: {
  children: ReactNode;
  ref?: RefObject<HTMLElement>;
  padding: Padding;
  paddingBottom: Padding;
  background: 'surface' | 'body';
}) {
  return (
    <Box
      background={background}
      paddingX={['none', 'none', padding]}
      paddingTop={padding}
      paddingBottom={paddingBottom}
      ref={ref}
    >
      {children}
    </Box>
  );
}

export function Section({
  mode = 'view',
  loading = false,
  heading,
  children,
  onEditClick,
  dataEmpty,
  addDataHint,
  padding = 'large',
  paddingX = 'none',
  paddingY = 'none',
  paddingBottom = 'large',
  background = 'surface',
}: Props) {
  const { t } = useTranslations(translations);

  const editMode = mode === 'edit';
  const universalPadding =
    paddingX === 'none' && paddingY === 'none' ? padding : 'none';

  return (
    <LargePaddingCard
      padding={universalPadding}
      paddingBottom={paddingBottom}
      background={background}
    >
      <Stack space="large">
        {heading ? (
          <Columns
            space="large"
            alignY="center"
            collapseBelow={dataEmpty ? 'tablet' : undefined}
          >
            <Column>
              <Heading level="3">{heading}</Heading>
            </Column>
            {!loading && editMode ? (
              <Column width="content">
                <Inline space="small">
                  <Text>
                    <TextLinkButton
                      icon={dataEmpty ? <IconAdd /> : <IconEdit />}
                      onClick={onEditClick}
                    >
                      {dataEmpty ? t('Add') : t('Edit')}
                    </TextLinkButton>
                  </Text>
                </Inline>
              </Column>
            ) : null}
          </Columns>
        ) : null}
        {loading ? <Loader delayVisibility /> : null}
        {!loading && editMode && dataEmpty ? (
          <Stack space="large">
            <Notice tone="info">
              <Text>{addDataHint}</Text>
            </Notice>
          </Stack>
        ) : (
          <Box>{children}</Box>
        )}
      </Stack>
    </LargePaddingCard>
  );
}
