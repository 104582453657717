import type { Country, MelwaysZone } from '@seek/melways-sites';

import {
  list as allLocationList,
  type LocationLanguage,
} from '@seek/location-data';

import { isTargetLocation } from './helpers/isTargetLocation';
import { slugifyLocation } from '../../utils/reviewLocationSlug';
import { getMelwaysZoneByCountryCode } from '../../utils/getMelwaysZoneByCountryCode';
import { getLanguagesByCountryCode } from '../../utils/getLanguagesByCountryCode';

type LocationSlug = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [key in MelwaysZone]: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [key in LocationLanguage]?: [
      {
        locationSlug: string;
        locationId: number;
      },
    ];
  };
};

const locationSlugFactory = () => {
  let locationSlugs: LocationSlug | undefined;

  const generateLocationSlugs = () => {
    if (!locationSlugs) {
      locationSlugs = {} as LocationSlug;

      for (const location of allLocationList) {
        const countryCode = location.country?.countryCode as Uppercase<Country>;
        const locationZone = getMelwaysZoneByCountryCode(countryCode);
        const locationLanguages = getLanguagesByCountryCode(countryCode);

        if (!isTargetLocation({ locationKind: location.kind, locationZone })) {
          continue;
        }

        for (const locationLanguage of locationLanguages) {
          const term = location.contextualName(locationLanguage, countryCode);

          const slug = slugifyLocation({
            locationContextualName: term,
            filteredEncoding: true,
          });

          if (!locationSlugs[locationZone]) {
            locationSlugs[locationZone] = {
              [locationLanguage]: [
                {
                  locationSlug: slug,
                  locationId: location.shortId,
                },
              ],
            };
            continue;
          }

          if (!locationSlugs[locationZone][locationLanguage]) {
            locationSlugs[locationZone][locationLanguage] = [
              {
                locationSlug: slug,
                locationId: location.shortId,
              },
            ];
            continue;
          }

          locationSlugs[locationZone][locationLanguage].push({
            locationSlug: slug,
            locationId: location.shortId,
          });
        }
      }
    }

    return locationSlugs;
  };

  return { generateLocationSlugs };
};

export const createLocationSlugService = () => {
  const factory = locationSlugFactory();
  const locationSlugs = factory.generateLocationSlugs();

  const getLocationIdByLocationSlug = ({
    locationSlug,
    locationLanguage,
    zone,
  }: {
    locationSlug: string;
    locationLanguage: LocationLanguage;
    zone: MelwaysZone;
  }): number | undefined => {
    if (!locationSlugs[zone][locationLanguage]) {
      return undefined;
    }

    return locationSlugs[zone][locationLanguage].find(
      (location) => location.locationSlug === locationSlug,
    )?.locationId;
  };

  return { getLocationIdByLocationSlug };
};

export type LocationSlugService = ReturnType<typeof createLocationSlugService>;
