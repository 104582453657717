import { ReviewPageLayout } from '../components/ReviewPageLayout/ReviewPageLayout';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import type {
  MonthPickerValue,
  PageProps,
  Progress,
  ReviewPayload,
} from '../type';
import { useConfig, useValidation, type Rules } from '@seek/libs-shared';
import { validationRules } from '../validation/validationRules';
import { WorkTypeQuestion } from '../questions/SimpleQuestions/WorkTypeQuestion';
import { Stack } from 'braid-design-system';
import { JobTitleQuestion } from '../questions/AutoSuggestionQuestions/JobTitleQuestion';
import type { RoleTitle } from '../../shared/services/roleService/roleService';
import { LocationQuestion } from '../questions/AutoSuggestionQuestions/LocationQuestion/LocationQuestion';
import type { LocationSuggestion } from '../../shared/services/locationService/locationService';
import { FinishQuestion } from '../questions/GroupedQuestions/WorkHistoryQuestion/FinishQuestion';
import { StartQuestion } from '../questions/GroupedQuestions/WorkHistoryQuestion/StartQuestion';
import { testWorkHistoryRequired } from '../validation/validator/workHistoryRequired';

export interface YourRolePageProps extends PageProps {
  getRoleTitles: (query: string) => Promise<RoleTitle[]>;
  searchLocations: (query: string) => Promise<LocationSuggestion[]>;
}

export const YourRolePage = ({
  companyProfile,
  review,
  setPage,
  setter,
  getRoleTitles,
  searchLocations,
  pageNumber,
}: YourRolePageProps) => {
  const { t } = useTranslations(translations);
  const { language, zone } = useConfig();

  const fields = {
    workType: review?.workType,
    jobTitle: review?.jobTitle,
    fullLocation: review?.fullLocation,
    workHistoryFrom: review?.workHistoryFrom,
    ...(!review.isStillWorkHere
      ? {
          workHistoryTo: review?.workHistoryTo,
          workHistory: {
            from: review?.workHistoryFrom,
            to: review?.workHistoryTo,
          },
        }
      : {}),
  };

  const {
    workType,
    jobTitle,
    fullLocation,
    workHistoryFrom,
    workHistoryTo,
    workHistory,
  } = validationRules(language);

  const rules = {
    workType,
    jobTitle,
    fullLocation,
    workHistoryFrom,
    ...(!review.isStillWorkHere
      ? {
          workHistoryTo,
          workHistory,
        }
      : {}),
  } as Rules<typeof fields>;

  const { validate, getTone, getMessage, handleValueChangeWithValidation } =
    useValidation<ReviewPayload, 'workHistory'>(fields, rules);

  const handleOnContinue = () => {
    const isValid = validate();
    if (!isValid) {
      return;
    }
    setPage(pageNumber + 1);
  };

  const handleOnBack = () => {
    setPage(pageNumber - 1);
  };

  const handleLocationNameChange = (value: LocationSuggestion) => {
    handleValueChangeWithValidation({
      setter,
      validatorProperty: 'fullLocation',
      previousValue: review,
    })(value.text);

    setter((prev) => ({
      ...prev,
      locationId: value.id,
    }));
  };

  const handleStartQuestionChange = (value: MonthPickerValue) => {
    setter({
      ...review,
      workHistoryFrom: value,
    });

    const shouldTestWorkHistory =
      testWorkHistoryRequired(value) &&
      review?.workHistoryTo &&
      testWorkHistoryRequired(review?.workHistoryTo);

    if (!review.isStillWorkHere) {
      validate({
        workHistoryFrom: value,
        ...(shouldTestWorkHistory
          ? {
              workHistory: {
                from: value,
                to: review?.workHistoryTo,
              },
            }
          : {}),
      });
    }
  };

  const handleFinishQuestionChange = (value: MonthPickerValue) => {
    setter({
      ...review,
      workHistoryTo: value,
    });

    const shouldTestWorkHistory =
      testWorkHistoryRequired(value) &&
      review?.workHistoryFrom &&
      testWorkHistoryRequired(review?.workHistoryFrom);

    if (!review.isStillWorkHere) {
      validate({
        workHistoryTo: value,
        ...(shouldTestWorkHistory
          ? {
              workHistory: {
                from: review?.workHistoryFrom,
                to: value,
              },
            }
          : {}),
      });
    }
  };

  return (
    <ReviewPageLayout
      progress={pageNumber}
      companyName={companyProfile?.name}
      subtitle={t(
        'This information will be shared with your review. You can make the job title more generic to protect your identity.',
      )}
      onContinue={handleOnContinue}
      onBack={handleOnBack}
      testid="your-role-page"
    >
      <Stack space="small">
        <JobTitleQuestion
          onChange={handleValueChangeWithValidation({
            setter,
            validatorProperty: 'jobTitle',
            previousValue: review,
          })}
          value={review?.jobTitle}
          tone={getTone('jobTitle')}
          message={getMessage('jobTitle')}
          getRoleTitles={getRoleTitles}
        />
        <LocationQuestion
          onChange={handleLocationNameChange}
          searchLocations={searchLocations}
          value={{
            text: review?.fullLocation,
            id: review?.locationId,
          }}
          zone={zone}
          tone={getTone('fullLocation')}
          message={getMessage('fullLocation')}
        />
        <WorkTypeQuestion
          onChange={handleValueChangeWithValidation({
            setter,
            validatorProperty: 'workType',
            previousValue: review,
          })}
          value={review?.workType}
          tone={getTone('workType')}
          message={getMessage('workType')}
        />
        <StartQuestion
          value={review.workHistoryFrom}
          onChange={handleStartQuestionChange}
          tone={getTone('workHistoryFrom')}
          message={getMessage('workHistoryFrom')}
        />
        <FinishQuestion
          value={review.workHistoryTo}
          onChange={handleFinishQuestionChange}
          isStillWorkHere={Boolean(review.isStillWorkHere)}
          onIsStillWorkHereChange={() => {
            setter({ ...review, isStillWorkHere: !review.isStillWorkHere });
          }}
          tone={
            getTone('workHistoryTo') === 'critical'
              ? getTone('workHistoryTo')
              : getTone('workHistory')
          }
          message={getMessage('workHistoryTo') || getMessage('workHistory')}
        />
      </Stack>
    </ReviewPageLayout>
  );
};
