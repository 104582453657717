import { useRouteLoaderData } from 'react-router';
import { CompanyProfilesPage } from '../../../paths';
import type { CultureModel } from './cultureModel';
import { CulturePage } from '../../pages/culture/CulturePage';
import { PageLoader } from '@seek/libs-shared';

export function CulturePageContainer({
  trackPhotosPaginationClicked,
}: {
  trackPhotosPaginationClicked: ({
    pageNumber,
  }: {
    pageNumber: number;
  }) => void;
}): JSX.Element {
  const model = useRouteLoaderData(CompanyProfilesPage.Culture) as CultureModel;

  return model ? (
    <CulturePage
      model={model}
      trackPhotosPaginationClicked={trackPhotosPaginationClicked}
    />
  ) : (
    <PageLoader />
  );
}
