import type { SalaryFrequency } from '@seek/salary-data';
import type { SalaryType } from '../../type';

const salaryTypeMapping: Record<SalaryType, SalaryFrequency> = {
  HourlyRate: 'HOURLY',
  Monthly: 'MONTHLY',
  AnnualPackage: 'ANNUAL',
};

export function mapSalaryType(key: SalaryType): SalaryFrequency {
  return salaryTypeMapping[key];
}
