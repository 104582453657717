import type { Locale } from '@seek/melways-sites';

type MonthName = [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
];

export const getMonthAbbreviations = (locale: Locale): MonthName => {
  const months = [];
  for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
    const date = new Date(2000, monthIndex, 1); // Year 2000 to avoid leap year edge cases
    const formatter = new Intl.DateTimeFormat(locale, { month: 'short' });
    months.push(formatter.format(date));
  }
  return months as MonthName;
};

export const getLast50Years = () => {
  const currentYear = new Date().getFullYear();
  const years: string[] = Array.from({ length: 50 }, (_, i) =>
    (currentYear - i).toString(),
  );
  return years;
};
