import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import { Textarea } from 'braid-design-system';
import type { QuestionProps } from '../../type';

export const TheGoodThingsQuestion = ({
  value = '',
  message = '',
  tone,
  onChange,
}: QuestionProps) => {
  const { t } = useTranslations(translations);
  const GOOD_THINGS_MAX_LENGTH = 300;
  return (
    <Textarea
      id={'theGoodThings'}
      label={t('The good things')}
      description={t('Describe what you like the most')}
      onChange={(event) => onChange(event.currentTarget.value)}
      value={value}
      tone={tone}
      message={message}
      reserveMessageSpace
      characterLimit={GOOD_THINGS_MAX_LENGTH}
    />
  );
};
