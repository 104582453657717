import { useRouteLoaderData } from 'react-router';
import { CompanyProfilesPage } from '../../../paths';
import type { AboutModel } from './aboutModel';
import { AboutPage } from '../../pages/about/AboutPage';
import { PageLoader } from '@seek/libs-shared';
import { SeoStructuredData } from '../../components/seoStructuredData/SeoStructuredData';
import { mapToSeoData } from '../../components/seoStructuredData/mapper';

export function AboutPageContainer({
  trackRandomCompanyProfileLinkClicked,
  trackReviewsClicked,
  trackSeeAllPhotosButtonClicked,
  trackWebsiteClicked,
  trackWriteAReviewClicked,
  onActiveTabChanged,
  getCompanyProfilesPageUrl,
}: {
  trackRandomCompanyProfileLinkClicked: ({
    linkedCompanyId,
  }: {
    linkedCompanyId: string;
  }) => void;
  trackReviewsClicked: () => void;
  trackSeeAllPhotosButtonClicked: () => void;
  trackWebsiteClicked: () => void;
  trackWriteAReviewClicked: () => void;
  onActiveTabChanged: (item: string | undefined) => void;
  getCompanyProfilesPageUrl: (p: CompanyProfilesPage) => string;
}) {
  const aboutData = useRouteLoaderData(CompanyProfilesPage.About) as AboutModel;

  return aboutData ? (
    <>
      <SeoStructuredData data={mapToSeoData(aboutData)} />
      <AboutPage
        model={{ ...aboutData }}
        onActiveTabChanged={onActiveTabChanged}
        getCompanyProfilesPageUrl={getCompanyProfilesPageUrl}
        trackRandomCompanyProfileLinkClicked={
          trackRandomCompanyProfileLinkClicked
        }
        trackWriteAReviewClicked={trackWriteAReviewClicked}
        trackReviewsClicked={trackReviewsClicked}
        trackSeeAllPhotosButtonClicked={trackSeeAllPhotosButtonClicked}
        trackWebsiteClicked={trackWebsiteClicked}
      />
    </>
  ) : (
    <PageLoader />
  );
}
