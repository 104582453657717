import {
  Box,
  Column,
  Columns,
  Hidden,
  Inline,
  Stack,
  Text,
} from 'braid-design-system';

import * as styles from './ghostReview.css';

interface Props {
  index: number;
}

export const GhostReview = ({ index }: Props) => {
  const PlaceHolderLineSmall = (
    <Box display="block" borderRadius="standard" background="neutralLight">
      <Text size="xsmall">&nbsp;</Text>
    </Box>
  );

  const PlaceholderLineMedium = (
    <Box display="block" borderRadius="standard" background="neutralLight">
      <Text size="xsmall">&nbsp;</Text>
      <Text size="xsmall">&nbsp;</Text>
    </Box>
  );

  const PlaceholderLineLarge = (
    <Box display="block" borderRadius="standard" background="neutralLight">
      <Text size="xsmall">&nbsp;</Text>
      <Text size="xsmall">&nbsp;</Text>
      <Text size="xsmall">&nbsp;</Text>
      <Text size="xsmall">&nbsp;</Text>
    </Box>
  );

  const PlaceholderCircle = <Box className={styles.greyCircle}></Box>;

  return (
    <Box
      id={`ghost-review-${index}`}
      boxShadow="borderNeutralLight"
      borderRadius="large"
      padding="gutter"
      data={{
        testid: 'ghost-review-component',
      }}
    >
      <Hidden below="tablet">
        <Columns space="gutter">
          <Column width="1/4">
            <Stack space="large">
              {PlaceholderLineMedium}
              <Columns space="small">
                <Column width="4/5">
                  <Stack space="large">
                    <Stack space="xsmall">
                      {PlaceHolderLineSmall}
                      {PlaceHolderLineSmall}
                    </Stack>
                    {PlaceHolderLineSmall}
                  </Stack>
                </Column>
              </Columns>
            </Stack>
          </Column>

          <Column width="2/3">
            <Stack space="medium">
              <Stack space="large">
                <Stack space="xsmall">
                  {PlaceholderLineMedium}
                  <Columns space="none">
                    <Column width="2/3">{PlaceholderLineMedium}</Column>
                  </Columns>
                </Stack>

                <Columns space="none">
                  <Column width="1/4">{PlaceHolderLineSmall}</Column>
                </Columns>
              </Stack>

              <Stack space="xsmall">
                {PlaceHolderLineSmall}
                <Columns space="none">
                  <Column width="2/3">{PlaceHolderLineSmall}</Column>
                </Columns>
              </Stack>

              <Columns space="none">
                <Column width="1/4">{PlaceHolderLineSmall}</Column>
              </Columns>

              <Stack space="xsmall">
                {PlaceHolderLineSmall}
                <Columns space="none">
                  <Column width="2/3">{PlaceHolderLineSmall}</Column>
                </Columns>
              </Stack>

              <Inline space="small" alignY="center">
                <Box className={styles.helpfulDesktopButton}>
                  {PlaceholderLineLarge}
                </Box>
                <Box className={styles.helpfulDesktopDescription}>
                  {PlaceHolderLineSmall}
                </Box>
              </Inline>
            </Stack>
          </Column>
          <Column>
            <Box className={styles.circleWrapper}>{PlaceholderCircle}</Box>
          </Column>
        </Columns>
      </Hidden>

      <Hidden above="mobile">
        <Stack space="medium">
          <Stack space="large">
            <Stack space="xsmall">
              {PlaceholderLineMedium}
              <Columns space="none">
                <Column width="2/3">{PlaceholderLineMedium}</Column>
              </Columns>
            </Stack>

            <Columns space="none">
              <Column width="2/5">{PlaceHolderLineSmall}</Column>
            </Columns>
          </Stack>

          <Stack space="xsmall">
            {PlaceHolderLineSmall}
            <Columns space="none">
              <Column width="2/3">{PlaceHolderLineSmall}</Column>
            </Columns>
          </Stack>

          <Columns space="none">
            <Column width="2/5">{PlaceHolderLineSmall}</Column>
          </Columns>

          <Stack space="xsmall">
            {PlaceHolderLineSmall}
            <Columns space="none">
              <Column width="2/3">{PlaceHolderLineSmall}</Column>
            </Columns>
          </Stack>

          <Box className={styles.helpfulMobileRowWrapper}>
            <Box className={styles.helpfulMobileButton}>
              {PlaceholderLineLarge}
            </Box>
            <Box className={styles.helpfulMobileDescription}>
              {PlaceHolderLineSmall}
            </Box>
            <Box className={styles.circleMobile}>{PlaceholderCircle}</Box>
          </Box>
        </Stack>
      </Hidden>
    </Box>
  );
};
