import { Stack, Heading, Text, Box } from 'braid-design-system';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import { PuzzleIcon } from '@seek/libs-shared';

export function NoResults() {
  const { t } = useTranslations(translations);
  return (
    <Box padding="xlarge">
      <Stack space="gutter" align="center">
        <PuzzleIcon />
        <Heading level="3" align="center">
          {t('No matching search results')}
        </Heading>
        <Stack space="small" align="center">
          <Text tone="secondary" align="center">
            {t('We couldn’t find anything that matched your search.')}
          </Text>
          <Text tone="secondary" align="center">
            {t('Try checking for spelling errors.')}
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
}
