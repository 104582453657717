import { useTranslations } from '@vocab/react';
import {
  Stack,
  Box,
  Actions,
  ButtonLink,
  Inline,
  IconArrow,
} from 'braid-design-system';
import { useLocation } from 'react-router';
import translations from './.vocab';
import {
  PhotoTiles,
  type GalleryPaginationType,
} from '../../common/PhotoTiles/PhotoTiles';
import { UpdateSuccessAlert, type Gallery } from '../../../';
import { Section } from '../../common/section/Section';
import { useEffect, useRef } from 'react';

interface Props {
  mode: 'view' | 'edit';
  model: Gallery;
  loading?: boolean;
  link?: string;
  trackSeeAllPhotosButtonClicked?: () => void;
  trackPhotosPaginationClicked?: ({
    pageNumber,
    type,
  }: {
    pageNumber: number;
    type: GalleryPaginationType;
  }) => void;

  /**
   * Only used for 'edit' mode
   */
  showUpdateSuccess?: boolean;
  onEditClick?: () => void;
  onHideUpdateSuccess?: () => void;
  onActiveTabChanged?: (item: string | undefined) => void;
}

export const GallerySection = ({
  mode,
  loading,
  model,
  link,
  showUpdateSuccess = false,
  onActiveTabChanged,
  trackSeeAllPhotosButtonClicked,
  trackPhotosPaginationClicked,
  onEditClick,
  onHideUpdateSuccess,
}: Props) => {
  const { t } = useTranslations(translations);
  const isAboutPage = Boolean(link);
  const heading = isAboutPage ? `${model.name} ${t('photos')}` : t('Gallery');
  const tilesPerPage = isAboutPage ? 4 : undefined;
  const { state } = useLocation();
  const galleryRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (state?.shouldScrollToGallery) {
      scrollToGallery();
    }
  }, [state?.shouldScrollToGallery]);

  const scrollToGallery = () => {
    galleryRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const photoLinkClicked = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
    if (onActiveTabChanged) {
      if (trackSeeAllPhotosButtonClicked) trackSeeAllPhotosButtonClicked();
      onActiveTabChanged('culture');
    }
  };

  const isEmpty = !model || !model.photos || model.photos.length === 0;

  return (
    <Section
      mode={mode}
      loading={loading}
      paddingBottom={mode === 'view' ? 'none' : 'large'}
      dataEmpty={isEmpty}
      addDataHint={t("Showcase your company's work environment and culture.")}
      heading={heading}
      onEditClick={onEditClick}
    >
      <Stack space="gutter">
        {showUpdateSuccess && (
          <UpdateSuccessAlert
            onClose={() => {
              if (onHideUpdateSuccess) onHideUpdateSuccess();
            }}
            text={t('Gallery updated')}
          />
        )}

        <Box ref={galleryRef}>
          <Stack space="large">
            <PhotoTiles
              gallery={model}
              tilesPerPage={tilesPerPage}
              modalTitle={heading}
              scrollToGallery={scrollToGallery}
              trackPhotosPaginationClicked={trackPhotosPaginationClicked}
              mode={mode}
              showPagination={!isAboutPage}
            />
            {isAboutPage && (
              <Actions>
                <Inline space="none">
                  <ButtonLink
                    href={link || '#'}
                    onClick={photoLinkClicked}
                    icon={<IconArrow direction="right" />}
                    iconPosition="trailing"
                  >
                    {t('See all photos')}
                  </ButtonLink>
                </Inline>
              </Actions>
            )}
          </Stack>
        </Box>
      </Stack>
    </Section>
  );
};
