/* istanbul ignore file */
import { lengthBetween, maxLength, required } from '@seek/validators-js';
import { isWorkHistoryValid } from './validator/isWorkHistoryValid';
import { isSalaryValid } from './validator/isSalaryValid';
import translations from './.vocab';
import { getTranslations } from '@seek/libs-shared';
import type { Language } from '@seek/melways-sites';
import { workHistoryRequired } from './validator/workHistoryRequired';

const t = getTranslations(translations);

export const validationRules = (language: Language) => ({
  overallRating: {
    validators: [required],
    validatorProps: {
      requiredMessage: t(language, 'Rate your overall experience'),
    },
  },
  companyName: {
    validators: [maxLength, required],
    validatorProps: {
      max: 100,
      requiredMessage: t(language, 'Company name is required'),
    },
  },
  fullLocation: {
    validators: [maxLength, required],
    validatorProps: {
      max: 200,
      requiredMessage: t(language, 'Select a location from the list'),
    },
  },
  theGoodThings: {
    validators: [required, lengthBetween],
    validatorProps: {
      min: 20,
      max: 1000,
      requiredMessage: t(
        language,
        'Tell us the positive aspects of working at the company',
      ),
    },
  },
  theChallenges: {
    validators: [required, lengthBetween],
    validatorProps: {
      min: 20,
      max: 1000,
      requiredMessage: t(
        language,
        'Tell us the challenging aspects of working at the company',
      ),
    },
  },
  title: {
    validators: [lengthBetween, required],
    validatorProps: {
      min: 20,
      max: 1000,
      requiredMessage: t(language, 'Add a summary of your experience'),
    },
  },
  workHistoryFrom: {
    validators: [workHistoryRequired],
  },
  workHistoryTo: {
    validators: [workHistoryRequired],
  },
  workHistory: {
    validators: [isWorkHistoryValid],
  },
  workType: {
    validators: [required],
    validatorProps: {
      requiredMessage: t(language, 'Select a work type'),
    },
  },
  jobTitle: {
    validators: [required],
    validatorProps: {
      requiredMessage: t(language, 'Enter your job title'),
    },
  },
  salary: {
    validators: [isSalaryValid],
  },
});
