import translations from './.vocab';
import { getTranslations } from './translations';

const t = getTranslations(translations);

// This logic is brought from the cpp-react repo
export const formatYearLeft = (yearLeft: string, language: string) => {
  if (!yearLeft || yearLeft.length === 0) {
    return '';
  }

  return yearLeft === 'still_work_here' || yearLeft === 'Still work here'
    ? t(language, 'current employee')
    : t(language, 'former employee');
};

export const formatYearOfExperience = (
  yearsWorkedWith: string,
  language: string,
) => {
  const size = yearsWorkedWith && yearsWorkedWith.split(/[\s_]+/);
  const filterData =
    size &&
    size.filter(
      (item) => !isNaN(parseInt(item, 10)) && parseInt(item, 10) !== 0,
    );
  if (!filterData || !filterData.length) {
    return '';
  }
  if (filterData.length > 1) {
    return t(language, '{start} to {end} years in the role, ', {
      start: filterData[0],
      end: filterData[1],
    });
  }
  if (filterData.length === 1 && filterData[0] === '1') {
    return t(language, 'Less than 1 year in the role, ');
  }
  return t(language, 'More than {years} years in the role, ', {
    years: filterData[0],
  });
};

export const formatReviewSalarySummary = (
  salarySummary: string,
  language: string,
): string => {
  switch (salarySummary) {
    case 'generous':
    case 'Generous':
      return t(language, 'High');
    case 'fair':
    case 'Fair':
      return t(language, 'Average');
    case 'below':
    case 'Below Par':
      return t(language, 'Low');
    default:
      return t(language, 'Not specified');
  }
};
