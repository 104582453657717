import { ContentBlock, Heading, Stack } from 'braid-design-system';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router';
import { PageLoader, useAppContext, useConfig } from '@seek/libs-shared';
import { useTranslations } from '@vocab/react';

import { useService } from '../../../shared/providers/serviceProvider';
import { trackViewEvents } from '../../../shared/trackViewEvents';
import type { SearchCompaniesResult } from '../../../shared/services/companyProfileService/types';

import { CompanySearchBar } from '../../components/companySearchBar/CompanySearchBar';
import { MainLayout } from '../../layouts/mainLayout/MainLayout';
import { NoResults } from '../../components/noResults/NoResults';

import translations from './.vocab';
import { CompanySearchCard } from '../../components/companySearchCard/CompanySearchCard';

export const SearchPage = () => {
  const { t } = useTranslations(translations);
  const { companyProfileService } = useService();
  const config = useConfig();
  const context = useAppContext();

  const [searchParams] = useSearchParams();
  const [searchCompaniesResult, setSearchCompaniesResult] =
    useState<SearchCompaniesResult>();
  const [searchIsLoading, setSearchIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(
    searchParams.get('term')?.toString() || '',
  );

  useEffect(() => {
    setSearchTerm(searchParams.get('term')?.toString() || '');
    setSearchCompaniesResult(undefined);
    setSearchIsLoading(true);
  }, [searchParams]);

  useEffect(() => {
    if (searchTerm) {
      companyProfileService
        .searchCompanies({ searchTerm })
        .then((companies) => {
          setSearchCompaniesResult(companies);
          window.scroll({ top: 0, behavior: 'smooth' });
          setSearchIsLoading(false);
          trackViewEvents.searchResultsPage({
            config,
            context,
            searchKeyword: searchTerm,
            companyCount: companies.totalCompanies,
          });
        });
    } else {
      setSearchIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyProfileService, searchTerm]);

  return (
    <MainLayout>
      <ContentBlock width="small">
        <Stack space="xlarge">
          <Heading level="3">
            {t("Search results for 'searchTerm'", { searchTerm })}
          </Heading>
          <CompanySearchBar />

          {searchIsLoading ? (
            <PageLoader />
          ) : (
            <Stack space="small">
              {searchCompaniesResult?.totalCompanies ? (
                searchCompaniesResult.companies.map((company) => (
                  <CompanySearchCard
                    key={company.name}
                    name={company.name}
                    logo={company.logoUrl}
                    averageCompanyRating={company.overallRating.average}
                    reviewsCount={company.overallRating.count}
                    slug={company.slug}
                  />
                ))
              ) : (
                <NoResults />
              )}
            </Stack>
          )}
        </Stack>
      </ContentBlock>
    </MainLayout>
  );
};
