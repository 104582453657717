
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiLguKfguLHguJLguJnguJjguKPguKPguKHguYHguKXguLDguITguYjguLLguJnguLTguKLguKEiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoi4LmE4Liu4LmE4Lil4LiV4LmM4Lin4Lix4LiS4LiZ4LiY4Lij4Lij4Lih4LmB4Lil4Liw4LiE4LmI4Liy4LiZ4Li04Lii4Lih4LiC4Lit4LiH4Lia4Lij4Li04Lip4Lix4LiX4LiE4Li44LiTIiwiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQiOiLguK3guLHguJvguYDguJTguJXguKfguLHguJLguJnguJjguKPguKPguKHguYHguKXguLDguITguYjguLLguJnguLTguKLguKHguYHguKXguYnguKcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiLguKfguLHguJLguJnguJjguKPguKPguKHguYHguKXguLDguITguYjguLLguJnguLTguKLguKEiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoi4LmE4Liu4LmE4Lil4LiV4LmM4Lin4Lix4LiS4LiZ4LiY4Lij4Lij4Lih4LmB4Lil4Liw4LiE4LmI4Liy4LiZ4Li04Lii4Lih4LiC4Lit4LiH4Lia4Lij4Li04Lip4Lix4LiX4LiE4Li44LiTIiwiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQiOiLguK3guLHguJvguYDguJTguJXguKfguLHguJLguJnguJjguKPguKPguKHguYHguKXguLDguITguYjguLLguJnguLTguKLguKHguYHguKXguYnguKcifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJCdWRheWEgZGFuIG5pbGFpIiwiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiI6IlNvcm90aSBidWRheWEgZGFuIG5pbGFpIHBlcnVzYWhhYW4gQW5kYS4iLCJDdWx0dXJlIGFuZCB2YWx1ZXMgdXBkYXRlZCI6IkJ1ZGF5YSBkYW4gbmlsYWkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJCdWRheWEgZGFuIG5pbGFpIiwiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiI6IlNvcm90aSBidWRheWEgZGFuIG5pbGFpIHBlcnVzYWhhYW4gQW5kYS4iLCJDdWx0dXJlIGFuZCB2YWx1ZXMgdXBkYXRlZCI6IkJ1ZGF5YSBkYW4gbmlsYWkifQ%3D%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiLguKfguLHguJLguJnguJjguKPguKPguKHguYHguKXguLDguITguYjguLLguJnguLTguKLguKEiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoi4LmE4Liu4LmE4Lil4LiV4LmM4Lin4Lix4LiS4LiZ4LiY4Lij4Lij4Lih4LmB4Lil4Liw4LiE4LmI4Liy4LiZ4Li04Lii4Lih4LiC4Lit4LiH4Lia4Lij4Li04Lip4Lix4LiX4LiE4Li44LiTIiwiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQiOiLguK3guLHguJvguYDguJTguJXguKfguLHguJLguJnguJjguKPguKPguKHguYHguKXguLDguITguYjguLLguJnguLTguKLguKHguYHguKXguYnguKcifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiLguKfguLHguJLguJnguJjguKPguKPguKHguYHguKXguLDguITguYjguLLguJnguLTguKLguKEiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoi4LmE4Liu4LmE4Lil4LiV4LmM4Lin4Lix4LiS4LiZ4LiY4Lij4Lij4Lih4LmB4Lil4Liw4LiE4LmI4Liy4LiZ4Li04Lii4Lih4LiC4Lit4LiH4Lia4Lij4Li04Lip4Lix4LiX4LiE4Li44LiTIiwiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQiOiLguK3guLHguJvguYDguJTguJXguKfguLHguJLguJnguJjguKPguKPguKHguYHguKXguLDguITguYjguLLguJnguLTguKLguKHguYHguKXguYnguKcifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJCdWRheWEgZGFuIG5pbGFpIiwiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiI6IlNvcm90aSBidWRheWEgZGFuIG5pbGFpIHBlcnVzYWhhYW4gQW5kYS4iLCJDdWx0dXJlIGFuZCB2YWx1ZXMgdXBkYXRlZCI6IkJ1ZGF5YSBkYW4gbmlsYWkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJCdWRheWEgZGFuIG5pbGFpIiwiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiI6IlNvcm90aSBidWRheWEgZGFuIG5pbGFpIHBlcnVzYWhhYW4gQW5kYS4iLCJDdWx0dXJlIGFuZCB2YWx1ZXMgdXBkYXRlZCI6IkJ1ZGF5YSBkYW4gbmlsYWkifQ%3D%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJDdWx0dXJlIGFuZCB2YWx1ZXMiLCJIaWdobGlnaHQgdGhlIGN1bHR1cmUgYW5kIHZhbHVlcyBvZiB5b3VyIGNvbXBhbnkuIjoiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiQ3VsdHVyZSBhbmQgdmFsdWVzIHVwZGF0ZWQifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJbw4fHmseax5rGmuG5r8eax5rHmsWZ4bq94bq94bq9IMSDxIPEg%2BC4geC4teC5icaMIOG5vcSDxIPEg8aax5rHmsea4bq94bq94bq9xaFdIiwiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiI6IlvhuKjDrMOsw6zEo%2BG4qcaaw6zDrMOsxKPhuKnhua8g4bmv4bip4bq94bq94bq9IMOnx5rHmseaxprhua%2FHmseax5rFmeG6veG6veG6vSDEg8SDxIPguIHguLXguYnGjCDhub3Eg8SDxIPGmseax5rHmuG6veG6veG6vcWhIMO2w7bDtsaSIMO9w73DvcO2w7bDtseax5rHmsWZIMOnw7bDtsO2bcyCxqXEg8SDxIPguIHguLXguYnDvcO9w70uXSIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiW8OHx5rHmseaxprhua%2FHmseax5rFmeG6veG6veG6vSDEg8SDxIPguIHguLXguYnGjCDhub3Eg8SDxIPGmseax5rHmuG6veG6veG6vcWhIMeax5rHmsalxozEg8SDxIPhua%2Fhur3hur3hur3GjF0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDdWx0dXJlIGFuZCB2YWx1ZXMiOiJbw4fHmseax5rGmuG5r8eax5rHmsWZ4bq94bq94bq9IMSDxIPEg%2BC4geC4teC5icaMIOG5vcSDxIPEg8aax5rHmsea4bq94bq94bq9xaFdIiwiSGlnaGxpZ2h0IHRoZSBjdWx0dXJlIGFuZCB2YWx1ZXMgb2YgeW91ciBjb21wYW55LiI6IlvhuKjDrMOsw6zEo%2BG4qcaaw6zDrMOsxKPhuKnhua8g4bmv4bip4bq94bq94bq9IMOnx5rHmseaxprhua%2FHmseax5rFmeG6veG6veG6vSDEg8SDxIPguIHguLXguYnGjCDhub3Eg8SDxIPGmseax5rHmuG6veG6veG6vcWhIMO2w7bDtsaSIMO9w73DvcO2w7bDtseax5rHmsWZIMOnw7bDtsO2bcyCxqXEg8SDxIPguIHguLXguYnDvcO9w70uXSIsIkN1bHR1cmUgYW5kIHZhbHVlcyB1cGRhdGVkIjoiW8OHx5rHmseaxprhua%2FHmseax5rFmeG6veG6veG6vSDEg8SDxIPguIHguLXguYnGjCDhub3Eg8SDxIPGmseax5rHmuG6veG6veG6vcWhIMeax5rHmsalxozEg8SDxIPhua%2Fhur3hur3hur3GjF0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    