export const SpeechBubbleIcon = () => (
  <svg
    width="128"
    height="128"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M100.918 94.6719C107.836 86.3541 112 75.6643 112 64C112 37.4902 90.5096 16 64 16C37.4904 16 16 37.4902 16 64C16 90.5098 37.4904 112 64 112C72.2191 112 79.9536 109.931 86.7169 106.29L103.457 109.213L100.918 94.6719Z"
      fill="#EE399E"
    />
    <path
      d="M65.0412 47.2773L66.9314 51.1072L71.1579 51.7213C72.1102 51.8597 72.4904 53.0299 71.8013 53.7015L68.743 56.6827L69.465 60.8921C69.6276 61.8405 68.6322 62.5637 67.7805 62.1159L64.0002 60.1285L60.2198 62.1159C59.3682 62.5637 58.3727 61.8405 58.5354 60.8921L59.2574 56.6827L56.199 53.7015C55.51 53.0299 55.8902 51.8597 56.8424 51.7213L61.069 51.1072L62.9591 47.2773C63.385 46.4144 64.6154 46.4144 65.0412 47.2773Z"
      fill="#FDD651"
    />
    <path
      d="M44.6692 47.2773L46.5593 51.1072L50.7859 51.7213C51.7381 51.8597 52.1183 53.0299 51.4293 53.7015L48.3709 56.6827L49.0929 60.8921C49.2556 61.8405 48.2601 62.5637 47.4085 62.1159L43.6281 60.1285L39.8478 62.1159C38.9961 62.5637 38.0007 61.8405 38.1633 60.8921L38.8853 56.6827L35.827 53.7015C35.1379 53.0299 35.5181 51.8597 36.4704 51.7213L40.6969 51.1072L42.5871 47.2773C43.0129 46.4144 44.2433 46.4144 44.6692 47.2773Z"
      fill="#FDD651"
    />
    <path
      d="M85.4128 47.2773L87.303 51.1072L91.5295 51.7213C92.4817 51.8597 92.862 53.0299 92.1729 53.7015L89.1146 56.6827L89.8365 60.8921C89.9992 61.8405 89.0038 62.5637 88.1521 62.1159L84.3718 60.1285L80.5914 62.1159C79.7397 62.5637 78.7443 61.8405 78.907 60.8921L79.629 56.6827L76.5706 53.7015C75.8816 53.0299 76.2618 51.8597 77.214 51.7213L81.4405 51.1072L83.3307 47.2773C83.7566 46.4144 84.987 46.4144 85.4128 47.2773Z"
      fill="#FDD651"
    />
    <path d="M84.6431 72.2119H43.3569V75.1455H84.6431V72.2119Z" fill="white" />
    <path d="M81.1411 82.4678H46.8589V85.4014H81.1411V82.4678Z" fill="white" />
  </svg>
);
