
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiLguKvguKHguLLguKLguYDguKXguILguKvguJnguYnguLLguILguK3guIfguJzguKXguKXguLHguJ7guJjguYwiLCJOZXh0Ijoi4LiV4LmI4Lit4LmE4LibIiwiUHJldmlvdXMiOiLguIHguYjguK3guJnguKvguJnguYnguLIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiLguKvguKHguLLguKLguYDguKXguILguKvguJnguYnguLLguILguK3guIfguJzguKXguKXguLHguJ7guJjguYwiLCJOZXh0Ijoi4LiV4LmI4Lit4LmE4LibIiwiUHJldmlvdXMiOiLguIHguYjguK3guJnguKvguJnguYnguLIifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQZW5vbW9yYW4gaGFsYW1hbiBoYXNpbCIsIk5leHQiOiJCZXJpa3V0bnlhIiwiUHJldmlvdXMiOiJTZWJlbHVtbnlhIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQZW5vbW9yYW4gaGFsYW1hbiBoYXNpbCIsIk5leHQiOiJCZXJpa3V0bnlhIiwiUHJldmlvdXMiOiJTZWJlbHVtbnlhIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiLguKvguKHguLLguKLguYDguKXguILguKvguJnguYnguLLguILguK3guIfguJzguKXguKXguLHguJ7guJjguYwiLCJOZXh0Ijoi4LiV4LmI4Lit4LmE4LibIiwiUHJldmlvdXMiOiLguIHguYjguK3guJnguKvguJnguYnguLIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiLguKvguKHguLLguKLguYDguKXguILguKvguJnguYnguLLguILguK3guIfguJzguKXguKXguLHguJ7guJjguYwiLCJOZXh0Ijoi4LiV4LmI4Lit4LmE4LibIiwiUHJldmlvdXMiOiLguIHguYjguK3guJnguKvguJnguYnguLIifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQZW5vbW9yYW4gaGFsYW1hbiBoYXNpbCIsIk5leHQiOiJCZXJpa3V0bnlhIiwiUHJldmlvdXMiOiJTZWJlbHVtbnlhIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQZW5vbW9yYW4gaGFsYW1hbiBoYXNpbCIsIk5leHQiOiJCZXJpa3V0bnlhIiwiUHJldmlvdXMiOiJTZWJlbHVtbnlhIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiLCJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJbxqTEg8SDxIPEo8Osw6zDrOC4geC4teC5icSDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5iSDDtsO2w7bGkiDFmeG6veG6veG6vcWhx5rHmseaxprhua%2FFoV0iLCJOZXh0IjoiW8OR4bq94bq94bq96q2V4bmvXSIsIlByZXZpb3VzIjoiW8akxZnhur3hur3hub3DrMOsw7bDtseax5rFoV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQYWdpbmF0aW9uIG9mIHJlc3VsdHMiOiJbxqTEg8SDxIPEo8Osw6zDrOC4geC4teC5icSDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5iSDDtsO2w7bGkiDFmeG6veG6veG6vcWhx5rHmseaxprhua%2FFoV0iLCJOZXh0IjoiW8OR4bq94bq94bq96q2V4bmvXSIsIlByZXZpb3VzIjoiW8akxZnhur3hur3hub3DrMOsw7bDtseax5rFoV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    