export const PerksAndBenefitsIcon = () => (
  <svg
    width="128"
    height="128"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_20)">
      <path
        d="M100.74 118.7H36.74C34.53 118.7 32.74 116.91 32.74 114.7V65.01H104.74V114.7C104.74 116.91 102.95 118.7 100.74 118.7Z"
        fill="#EE399E"
      />
      <path d="M77.5 65.01H59.99V118.7H77.5V65.01Z" fill="#F470BD" />
      <path
        d="M63.57 18.86C62.64 15.69 60.53 13.08 57.63 11.5C51.65 8.23998 44.13 10.45 40.87 16.43C39.7 18.58 39.19 21.01 39.41 23.45L39.64 27.97L35.84 25.51C33.84 24.1 31.49 23.32 29.03 23.26C28.93 23.26 28.83 23.26 28.73 23.26C22.06 23.26 16.55 28.61 16.38 35.31C16.21 42.12 21.62 47.8 28.43 47.96C28.53 47.96 28.63 47.96 28.72 47.96C30.81 47.96 32.88 47.42 34.76 46.38L40.4 43.14V43.17L46.26 39.77L52.41 36.24L52.38 36.22L58.06 32.93C59.95 31.8 61.5 30.18 62.55 28.26C64.13 25.36 64.49 22.02 63.55 18.86H63.57ZM30.83 39.43C30.14 39.81 29.4 40.01 28.62 39.98C26.22 39.92 24.31 37.92 24.37 35.52C24.43 33.16 26.37 31.27 28.72 31.27C28.76 31.27 28.79 31.27 28.83 31.27C29.71 31.29 30.55 31.57 31.25 32.08L37.06 35.84L30.82 39.42L30.83 39.43ZM55.54 24.44C55.17 25.12 54.64 25.67 54.01 26.05L47.74 29.69L47.4 22.97V22.78C47.3 21.91 47.48 21.04 47.9 20.28C48.69 18.83 50.19 18.01 51.73 18.01C52.43 18.01 53.15 18.18 53.81 18.54C54.83 19.1 55.57 20.02 55.9 21.13C56.23 22.25 56.1 23.42 55.55 24.44H55.54Z"
        fill="#F470BD"
      />
      <path
        d="M88.82 33.81L19.54 73.81L12.54 61.69C11.44 59.78 12.09 57.33 14 56.23L76.35 20.22C78.26 19.12 80.71 19.77 81.81 21.68L88.81 33.8L88.82 33.81Z"
        fill="#EE399E"
      />
      <path
        d="M52.7619 33.8364L37.5978 42.5914L46.6228 58.2231L61.7869 49.4681L52.7619 33.8364Z"
        fill="#F470BD"
      />
      <path
        d="M104.9 32.4C106.49 33.08 107.3 33.45 107.82 33.96C108.34 34.48 108.7 35.29 109.38 36.88C109.46 37.08 109.62 37.24 109.83 37.33C110.27 37.52 110.77 37.32 110.96 36.88C111.64 35.29 112.01 34.47 112.52 33.96C113.04 33.45 113.85 33.08 115.44 32.4C115.64 32.32 115.8 32.16 115.89 31.95C116.08 31.51 115.88 31.01 115.44 30.82C113.85 30.14 113.03 29.77 112.52 29.26C112.01 28.74 111.64 27.93 110.96 26.34C110.88 26.15 110.72 25.98 110.51 25.89C110.07 25.7 109.57 25.9 109.38 26.34C108.7 27.93 108.33 28.74 107.82 29.26C107.3 29.78 106.49 30.14 104.9 30.82C104.7 30.9 104.54 31.06 104.45 31.27C104.26 31.71 104.46 32.21 104.9 32.4Z"
        fill="#FDC221"
      />
      <path
        d="M89.01 47.62C88.77 48.18 89.03 48.83 89.59 49.07C92.23 50.21 93.61 50.82 94.51 51.7L94.56 51.75C95.47 52.66 96.08 54.04 97.24 56.72C97.35 56.97 97.55 57.18 97.82 57.3C98.38 57.54 99.03 57.28 99.27 56.72C100.41 54.08 101.02 52.7 101.9 51.79L101.95 51.74C102.86 50.83 104.24 50.22 106.92 49.06C107.17 48.95 107.38 48.75 107.5 48.48C107.74 47.92 107.48 47.27 106.92 47.03C104.23 45.87 102.85 45.26 101.95 44.36C101.05 43.46 100.43 42.08 99.27 39.39C99.16 39.14 98.96 38.93 98.69 38.81C98.13 38.57 97.48 38.83 97.24 39.39C96.09 42.08 95.47 43.46 94.56 44.36C93.65 45.26 92.27 45.88 89.59 47.04C89.34 47.15 89.13 47.35 89.01 47.62Z"
        fill="#FDC221"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_20">
        <rect
          width="103.97"
          height="108.71"
          fill="white"
          transform="translate(12 10)"
        />
      </clipPath>
    </defs>
  </svg>
);
