import { useTranslations } from '@vocab/react';
import { Text, IconChevron, Box, TextLinkButton } from 'braid-design-system';
import { useEffect, useRef, useCallback, useState } from 'react';
import type * as React from 'react';

import translations from './.vocab';

interface Props {
  children: React.ReactNode;
  linesToPreview?: number;
  moreLabel?: string;
  lessLabel?: string;
  onShowMore?: (showMore: boolean) => void;
  onMoreButtonVisibilityChange?: (tooLong: boolean) => void;
  showFullTextOnly?: boolean;
}

/**
 * Truncates its children behind a More/Less toggle if they grow larger than a
 * specified number of lines.
 */
const ReadMore = ({
  children,
  linesToPreview = 2,
  moreLabel,
  lessLabel,
  onMoreButtonVisibilityChange,
  onShowMore,
  showFullTextOnly,
}: Props) => {
  const { t } = useTranslations(translations);
  const fullTextRef = useRef<HTMLDivElement>(null);
  const visibleTextRef = useRef<HTMLDivElement>(null);
  const [showFullText, setShowFullText] = useState<boolean>(
    Boolean(showFullTextOnly) === true,
  );
  const [showButton, setShowButton] = useState<boolean>(false);
  const translatedMoreLabel = moreLabel || t('More');
  const translatedLessLabel = lessLabel || t('Less');

  useEffect(() => {
    const calculateIfTextTooLong = (
      fullEl: HTMLDivElement,
      visibleTextEl: HTMLDivElement,
    ) => {
      const fullTextHeight = fullEl.scrollHeight;
      const visibleTextHeight = visibleTextEl.scrollHeight;

      const isTooLong = fullTextHeight > visibleTextHeight;
      setShowButton(isTooLong);

      if (onMoreButtonVisibilityChange && isTooLong !== showButton) {
        onMoreButtonVisibilityChange(showButton);
      }
    };

    if (fullTextRef.current && visibleTextRef.current) {
      calculateIfTextTooLong(fullTextRef.current, visibleTextRef.current);

      // Re-measure when web fonts have loaded
      document.fonts?.ready.then(() => {
        if (fullTextRef.current && visibleTextRef.current) {
          calculateIfTextTooLong(fullTextRef.current, visibleTextRef.current);
        }
      });
    }
  }, [onMoreButtonVisibilityChange, showButton, children]);

  const handleShowMore = useCallback(() => {
    setShowFullText(!showFullText);

    if (onShowMore) {
      onShowMore(!showFullText);
    }
  }, [onShowMore, showFullText]);

  return (
    <Box>
      <Box position="relative">
        <Box
          ref={fullTextRef}
          position="absolute"
          inset={0}
          pointerEvents="none"
          overflow="hidden"
          opacity={0}
        >
          <Text>{children}</Text>
        </Box>
        <Box ref={visibleTextRef} aria-hidden>
          <Text maxLines={showFullText ? undefined : linesToPreview}>
            {children}
          </Text>
        </Box>
      </Box>
      {showButton && (
        <Box marginTop="small" aria-hidden>
          <Text>
            <TextLinkButton
              onClick={handleShowMore}
              hitArea="large"
              weight="weak"
              icon={
                <IconChevron
                  direction={showFullText ? 'up' : 'down'}
                  alignY="lowercase"
                />
              }
              iconPosition="trailing"
            >
              {showFullText ? translatedLessLabel : translatedMoreLabel}
            </TextLinkButton>
          </Text>
        </Box>
      )}
    </Box>
  );
};

ReadMore.displayName = 'ReadMore';

export { ReadMore };
