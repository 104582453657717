import {
  Box,
  Heading,
  IconMoney,
  IconThumb,
  Stack,
  Text,
  Loader,
} from 'braid-design-system';

import CircularProgress from './CircularProgress';

import * as styles from './PercentageWheel.css';
import { formatRating } from '@seek/libs-shared';

interface PercentageWheelModel {
  percentage?: number;
}

interface Props {
  mode: 'view' | 'edit';
  model?: PercentageWheelModel;
  loading?: boolean;
  text?: string;
  isMoney?: boolean;
}
export const PercentageWheel = ({
  model,
  text,
  isMoney = false,
  loading,
}: Props) => {
  if (loading) {
    return <Loader />;
  }
  const percentage = model?.percentage || 0;
  return (
    <Stack space="small" align="center">
      <Box className={styles.greyCircle}>
        <Box className={styles.whiteCircle}>
          {percentage < 70 || (
            <Box className={styles.circleIcon}>
              {isMoney ? (
                <IconMoney tone="formAccent" size="small" />
              ) : (
                <IconThumb tone="formAccent" size="small" />
              )}
            </Box>
          )}
          <Box className={styles.circleText}>
            <Heading level="3" weight="weak">
              {formatRating(percentage)}%
            </Heading>
          </Box>
        </Box>
        <CircularProgress progress={percentage} />
      </Box>
      <Text size="small" align="center">
        {text}
      </Text>
    </Stack>
  );
};
