
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IOC5hOC4oeC5iOC4oeC4teC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC4l%2BC4teC5iOC4reC4reC4meC5hOC4peC4meC5jOC5g%2BC4meC4guC4k%2BC4sOC4meC4teC5iSIsIlBsZWFzZSB1c2Ugb3VyIEpvYiBzZWFyY2ggdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIjoi4LmC4Lib4Lij4LiU4LmD4LiK4LmJPFRleHRMaW5rPuC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC4h%2BC4suC4mTwvVGV4dExpbms%2B4LmA4Lie4Li34LmI4Lit4Lir4Liy4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4Lin4LmI4Liy4LiHIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IOC5hOC4oeC5iOC4oeC4teC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC4l%2BC4teC5iOC4reC4reC4meC5hOC4peC4meC5jOC5g%2BC4meC4guC4k%2BC4sOC4meC4teC5iSIsIlBsZWFzZSB1c2Ugb3VyIEpvYiBzZWFyY2ggdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIjoi4LmC4Lib4Lij4LiU4LmD4LiK4LmJPFRleHRMaW5rPuC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC4h%2BC4suC4mTwvVGV4dExpbms%2B4LmA4Lie4Li34LmI4Lit4Lir4Liy4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4Lin4LmI4Liy4LiHIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IHNhYXQgaW5pIHRpZGFrIG1lbWlsaWtpIGxvd29uZ2FuIGtlcmphIGFrdGlmLiIsIlBsZWFzZSB1c2Ugb3VyIEpvYiBzZWFyY2ggdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIjoiR3VuYWthbiA8VGV4dExpbms%2BUGVuY2FyaWFuIEtlcmphPC9UZXh0TGluaz4gdW50dWsgbWVuY2FyaSBsb3dvbmdhbiBrZXJqYSB5YW5nIHRlcnNlZGlhLiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IHNhYXQgaW5pIHRpZGFrIG1lbWlsaWtpIGxvd29uZ2FuIGtlcmphIGFrdGlmLiIsIlBsZWFzZSB1c2Ugb3VyIEpvYiBzZWFyY2ggdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIjoiR3VuYWthbiA8VGV4dExpbms%2BUGVuY2FyaWFuIEtlcmphPC9UZXh0TGluaz4gdW50dWsgbWVuY2FyaSBsb3dvbmdhbiBrZXJqYSB5YW5nIHRlcnNlZGlhLiJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IOC5hOC4oeC5iOC4oeC4teC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC4l%2BC4teC5iOC4reC4reC4meC5hOC4peC4meC5jOC5g%2BC4meC4guC4k%2BC4sOC4meC4teC5iSIsIlBsZWFzZSB1c2Ugb3VyIEpvYiBzZWFyY2ggdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIjoi4LmC4Lib4Lij4LiU4LmD4LiK4LmJPFRleHRMaW5rPuC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC4h%2BC4suC4mTwvVGV4dExpbms%2B4LmA4Lie4Li34LmI4Lit4Lir4Liy4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4Lin4LmI4Liy4LiHIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IOC5hOC4oeC5iOC4oeC4teC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4meC4l%2BC4teC5iOC4reC4reC4meC5hOC4peC4meC5jOC5g%2BC4meC4guC4k%2BC4sOC4meC4teC5iSIsIlBsZWFzZSB1c2Ugb3VyIEpvYiBzZWFyY2ggdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIjoi4LmC4Lib4Lij4LiU4LmD4LiK4LmJPFRleHRMaW5rPuC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC4h%2BC4suC4mTwvVGV4dExpbms%2B4LmA4Lie4Li34LmI4Lit4Lir4Liy4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4Lin4LmI4Liy4LiHIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IHNhYXQgaW5pIHRpZGFrIG1lbWlsaWtpIGxvd29uZ2FuIGtlcmphIGFrdGlmLiIsIlBsZWFzZSB1c2Ugb3VyIEpvYiBzZWFyY2ggdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIjoiR3VuYWthbiA8VGV4dExpbms%2BUGVuY2FyaWFuIEtlcmphPC9UZXh0TGluaz4gdW50dWsgbWVuY2FyaSBsb3dvbmdhbiBrZXJqYSB5YW5nIHRlcnNlZGlhLiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IHNhYXQgaW5pIHRpZGFrIG1lbWlsaWtpIGxvd29uZ2FuIGtlcmphIGFrdGlmLiIsIlBsZWFzZSB1c2Ugb3VyIEpvYiBzZWFyY2ggdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIjoiR3VuYWthbiA8VGV4dExpbms%2BUGVuY2FyaWFuIEtlcmphPC9UZXh0TGluaz4gdW50dWsgbWVuY2FyaSBsb3dvbmdhbiBrZXJqYSB5YW5nIHRlcnNlZGlhLiJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlBsZWFzZSB1c2Ugb3VyIDxUZXh0TGluaz5Kb2Igc2VhcmNoPC9UZXh0TGluaz4gdG8gbG9vayBmb3Igb3BlbiB2YWNhbmNpZXMuIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJbe2NvbXBhbnlOYW1lfSDGjMO2w7bDtuG6veG6veG6vcWhIOC4geC4teC5icO2w7bDtuG5ryDhuKnEg8SDxIPhub3hur3hur3hur0gxIPEg8SD4LiB4Li14LmJw73DvcO9IMSDxIPEg8On4bmvw6zDrMOs4bm94bq94bq94bq9IMS1w7bDtsO2w5%2FFoSDFmcOsw6zDrMSj4bip4bmvIOC4geC4teC5icO2w7bDtsW1Ll0iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlvGpMaa4bq94bq9xIPEg8Wh4bq94bq9IMeax5rFoeG6veG6vSDDtsO2x5rHmsWZIDxUZXh0TGluaz7EtMO2w7bDtsOfIMWh4bq94bq94bq9xIPEg8SDxZnDp%2BG4qTwvVGV4dExpbms%2BIOG5r8O2w7bDtiDGmsO2w7bDtsO2w7bDtsS3IMaSw7bDtsO2xZkgw7bDtsO2xqXhur3hur3hur3guIHguLXguYkg4bm9xIPEg8SDw6fEg8SDxIPguIHguLXguYnDp8Osw6zDrOG6veG6veG6vcWhLl0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGRvZXMgbm90IGhhdmUgYW55IGFjdGl2ZSBqb2JzIHJpZ2h0IG5vdy4iOiJbe2NvbXBhbnlOYW1lfSDGjMO2w7bDtuG6veG6veG6vcWhIOC4geC4teC5icO2w7bDtuG5ryDhuKnEg8SDxIPhub3hur3hur3hur0gxIPEg8SD4LiB4Li14LmJw73DvcO9IMSDxIPEg8On4bmvw6zDrMOs4bm94bq94bq94bq9IMS1w7bDtsO2w5%2FFoSDFmcOsw6zDrMSj4bip4bmvIOC4geC4teC5icO2w7bDtsW1Ll0iLCJQbGVhc2UgdXNlIG91ciBKb2Igc2VhcmNoIHRvIGxvb2sgZm9yIG9wZW4gdmFjYW5jaWVzLiI6IlvGpMaa4bq94bq9xIPEg8Wh4bq94bq9IMeax5rFoeG6veG6vSDDtsO2x5rHmsWZIDxUZXh0TGluaz7EtMO2w7bDtsOfIMWh4bq94bq94bq9xIPEg8SDxZnDp%2BG4qTwvVGV4dExpbms%2BIOG5r8O2w7bDtiDGmsO2w7bDtsO2w7bDtsS3IMaSw7bDtsO2xZkgw7bDtsO2xqXhur3hur3hur3guIHguLXguYkg4bm9xIPEg8SDw6fEg8SDxIPguIHguLXguYnDp8Osw6zDrOG6veG6veG6vcWhLl0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    