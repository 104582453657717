import type { LoaderFunction } from 'react-router';

import type { RouteProps } from '../../../shared/RouteProps';
import type { HomePageModel } from './homePageModel';

export const homePageLoader =
  ({ services, logger }: RouteProps): LoaderFunction =>
  async ({ _params, _request }): Promise<HomePageModel> => {
    try {
      const topCompanies =
        await services.companyProfileService.getGroupedCompanies({
          groupBy: {
            group: 'INDUSTRY',
            value: [],
          },
          perPage: 1,
          sortBy: 'REVIEWS_COUNT',
        });
      return { topCompanies };
    } catch (error) {
      logger.error({ error }, 'Error in homePageLoader');
      throw error;
    }
  };
