import { useEffect, useState } from 'react';
import {
  DEFAULT_LOGO_URL,
  useConfig,
  useValidation,
  type CompanyProfile,
} from '@seek/libs-shared';

import { Progress, type ReviewPayload } from '../type';
import { validationRules } from '../validation/validationRules';
import type { RoleTitle } from '../../shared/services/roleService/roleService';
import { OverallRatingPage } from './OverallRatingPage';
import { YourRolePage } from './YourRolePage';
import { YourExperiencePage } from './YourExperiencePage';
import { YourSalaryPage } from './YourSalaryPage';
import { YourFeedbackPage } from './YourFeedbackPage';
import { SubmitSuccessPage } from './SubmitSuccessPage';
import { Box } from 'braid-design-system';
import type { SearchCompanyProfileResultItem } from '../../shared/services/companyProfileService/types';
import type { LocationSuggestion } from '../../shared/services/locationService/locationService';

export type Props = {
  getRoleTitles: (query: string) => Promise<RoleTitle[]>;
  searchCompanyProfiles: (
    query: string,
  ) => Promise<SearchCompanyProfileResultItem[]>;
  searchLocations: (query: string) => Promise<LocationSuggestion[]>;
};

export const WriteAReviewPage = ({
  getRoleTitles,
  searchCompanyProfiles,
  searchLocations,
}: Props) => {
  const [review, setReview] = useState<ReviewPayload>({});
  const [page, setPage] = useState<Progress>(Progress.OverallRating);

  // ToDo, this will be replaced by an API call result
  const [companyProfile, setCompanyProfile] = useState<CompanyProfile>({
    id: '123',
    name: 'Seek',
    branding: {
      logoImageUrl: DEFAULT_LOGO_URL,
    },
  });
  const { language } = useConfig();

  const { validate } = useValidation<ReviewPayload>(
    review,
    validationRules(language),
  );

  // Prevent users from accidentally leaving the page
  useEffect(() => {
    if (page === Progress.SubmitSuccess) return;

    function beforeUnload(e: BeforeUnloadEvent) {
      e.preventDefault();
    }

    window.addEventListener('beforeunload', beforeUnload);

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [page]);

  useEffect(() => {
    if (
      review.companyId !== companyProfile.id ||
      review.companyName !== companyProfile.name
    ) {
      setCompanyProfile({
        id: review.companyId || '',
        name: review.companyName || '',
        branding: {
          logoImageUrl: DEFAULT_LOGO_URL,
        },
      });
    }
  }, [review]);

  const handleOnSubmit = async () => {
    const isValid = validate(review);
    if (!isValid) {
      return;
    }
    setPage(Progress.SubmitSuccess);
  };

  const handleOnSetPage = (page: Progress) => {
    setPage(page);
  };

  const onSearchCompanyProfiles = async (query: string) => {
    return await searchCompanyProfiles(query);
  };

  const onSearchLocations = async (query: string) => {
    return await searchLocations(query);
  };

  return (
    <Box data={{ testid: 'write-a-review-page' }}>
      {page === Progress.OverallRating && (
        <OverallRatingPage
          review={review}
          companyProfile={companyProfile}
          searchCompanyProfiles={onSearchCompanyProfiles}
          setPage={handleOnSetPage}
          setter={setReview}
          pageNumber={page}
        />
      )}
      {page === Progress.YourRole && (
        <YourRolePage
          review={review}
          companyProfile={companyProfile}
          searchLocations={onSearchLocations}
          setPage={handleOnSetPage}
          setter={setReview}
          getRoleTitles={getRoleTitles}
          pageNumber={page}
        />
      )}
      {page === Progress.YourExperience && (
        <YourExperiencePage
          review={review}
          companyProfile={companyProfile}
          setPage={handleOnSetPage}
          setter={setReview}
          pageNumber={page}
        />
      )}
      {page === Progress.YourSalary && (
        <YourSalaryPage
          review={review}
          companyProfile={companyProfile}
          setPage={handleOnSetPage}
          setter={setReview}
          pageNumber={page}
        />
      )}
      {page === Progress.YourFeedback && (
        <YourFeedbackPage
          review={review}
          companyProfile={companyProfile}
          setPage={handleOnSetPage}
          setter={setReview}
          onSubmit={handleOnSubmit}
          pageNumber={page}
        />
      )}
      {page === Progress.SubmitSuccess && (
        <SubmitSuccessPage
          companyName={companyProfile.name}
          companyId={
            companyProfile.id === 'orphan' ? undefined : companyProfile.id
          }
        />
      )}
    </Box>
  );
};
