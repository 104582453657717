import {
  Box,
  Checkbox,
  Column,
  Columns,
  Dropdown,
  FieldLabel,
  RadioGroup,
  RadioItem,
  Stack,
  Text,
} from 'braid-design-system';
import {
  type QuestionProps,
  type Salary,
  type SalaryType,
  salaryTypeValues,
} from '../../type';
import { useEffect, useState } from 'react';
import {
  SalaryCurrencyActiveValues,
  type SalaryCurrency,
} from '@seek/salary-data';
import { getSalaryRate } from './SalaryRanges';
import { mapSalaryType } from './utils';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import { constructSalaryDisplayText } from '@seek/unified-display-salary';

export const SalaryQuestion = ({
  value = { type: 'Monthly' },
  message,
  tone,
  onChange,
}: QuestionProps<Salary>) => {
  const { t } = useTranslations(translations);

  const [selectedSalaryBracket, setSelectedSalaryBracket] =
    useState<string>('');
  const [salaryBracketList, setSalaryBracketList] = useState<
    { min: number; max: number }[]
  >([]);

  useEffect(() => {
    const currency = value.currency;
    const payType = value.type;

    if (currency && payType) {
      const currentSalaryBracketList = getSalaryRate(
        currency,
        mapSalaryType(payType),
      );
      if (currentSalaryBracketList) {
        setSalaryBracketList(currentSalaryBracketList);
        if (value.minimum && value.maximum) {
          const selectedSalaryBracket = currentSalaryBracketList.findIndex(
            (salaryBracket) =>
              salaryBracket.min === value.minimum &&
              salaryBracket.max === value.maximum,
          );
          setSelectedSalaryBracket(selectedSalaryBracket.toString());
        }
      }
    }
  }, [value.type, value.currency]);

  const resetForm = () => {
    setSelectedSalaryBracket('');
    setSalaryBracketList([]);
  };

  const handleSalaryBracketOnChange = (
    event: React.FormEvent<HTMLSelectElement>,
  ) => {
    setSelectedSalaryBracket(event.currentTarget.value);
    onChange({
      ...value,
      minimum: salaryBracketList[Number(event.currentTarget.value)].min,
      maximum: salaryBracketList[Number(event.currentTarget.value)].max,
    });
  };

  const handleCurrencyOnChange = (
    event: React.FormEvent<HTMLSelectElement>,
  ) => {
    resetForm();
    onChange({
      ...value,
      currency: event.currentTarget.value as SalaryCurrency,
      minimum: undefined,
      maximum: undefined,
    });
  };

  const handleSalaryFrequencyOnChange = (
    event: React.FormEvent<HTMLInputElement>,
  ) => {
    resetForm();
    onChange({
      ...value,
      type: event.currentTarget.value as SalaryType,
      minimum: undefined,
      maximum: undefined,
    });
  };
  const handleBonusOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    onChange({
      ...value,
      includeBonus: event.currentTarget.checked,
    });
  };

  const getSalaryText = ({
    min,
    max,
    index,
    last,
  }: {
    min?: number;
    max?: number;
    index?: number;
    last?: boolean;
  }): string | undefined => {
    if (value.currency) {
      const salaryText = constructSalaryDisplayText({
        min,
        max,
        currency: value.currency,
      });
      if (index === 0) {
        return `${t('Under')} ${salaryText}`;
      }
      if (last) {
        return `${t('More than')} ${salaryText}`;
      }
      return `${salaryText}`;
    }
  };

  return (
    <>
      <FieldLabel
        htmlFor="salaryQuestion"
        label="What is your salary?"
        description="Used to calculate average and won't be included in your review"
      />
      <Box id="salaryQuestion">
        <Stack space="large">
          <Columns space="small" alignY="bottom">
            <Column width="1/4">
              <Dropdown
                id="currency"
                label=""
                aria-label="Currency"
                onChange={handleCurrencyOnChange}
                value={value.currency ?? ''}
                placeholder="Currency"
                data={{ testid: 'currency-selection' }}
                tone={tone}
              >
                {SalaryCurrencyActiveValues.map((currency, index) => (
                  <option key={index}>{currency}</option>
                ))}
              </Dropdown>
            </Column>
            <Column>
              <Dropdown
                id="range"
                label=""
                aria-label="Range"
                onChange={handleSalaryBracketOnChange}
                value={selectedSalaryBracket}
                placeholder=""
                tone={tone}
                data={{ testid: 'range-selection' }}
              >
                {salaryBracketList?.map((salaryBracket, index) => (
                  <option key={index} value={index}>
                    {getSalaryText({
                      min: salaryBracket.min,
                      max: salaryBracket.max,
                      index,
                      last: index === salaryBracketList.length - 1,
                    })}
                  </option>
                ))}
              </Dropdown>
            </Column>
          </Columns>

          <Checkbox
            checked={value.includeBonus ?? false}
            onChange={handleBonusOnChange}
            label={t('I also receive bonuses or commission')}
            id="includeBonus"
            data={{ testid: 'include-bonus-check' }}
          />

          {message ? <Text tone={tone}>{message}</Text> : null}

          <RadioGroup
            value={value.type}
            onChange={handleSalaryFrequencyOnChange}
            label="Your pay frequency"
            id="salaryFrequency"
            data={{ testid: 'salary-frequency-selection' }}
          >
            {salaryTypeValues.map((frequency) => (
              <RadioItem
                key={frequency}
                label={mapSalaryType(frequency)}
                value={frequency}
              />
            ))}
          </RadioGroup>
        </Stack>
      </Box>
    </>
  );
};
