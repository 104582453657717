import {
  type ApolloClient,
  type NormalizedCacheObject,
  gql,
} from '@apollo/client';
import type { AppConfig } from '../../config';

export interface GetCandidateResult {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface CandidateService {
  getCandidate: () => Promise<GetCandidateResult>;
}

const CANDIDATE_USER_QUERY = gql`
  query GetUser {
    viewer {
      ... on Candidate {
        id
        emailAddress
        personalDetails {
          firstName
          lastName
        }
      }
    }
  }
`;

export const createCandidateService = ({
  client,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  config: AppConfig;
}): CandidateService => {
  const getCandidate = async (): Promise<GetCandidateResult> => {
    const response = await client.query({
      query: CANDIDATE_USER_QUERY,
    });

    const { viewer } = response.data;

    return {
      id: viewer.id,
      firstName: viewer.personalDetails.firstName,
      lastName: viewer.personalDetails.lastName,
      email: viewer.emailAddress,
    };
  };

  return { getCandidate };
};
