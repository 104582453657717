import {
  useConfig,
  buildEmployerCompanyProfilePromoPageURI,
} from '@seek/libs-shared';
import { useTranslations } from '@vocab/react';
import { Stack, Text, TextLink } from 'braid-design-system';

import translations from './.vocab';
import { NoLifeAndCultureIcon } from './NoLifeAndCultureIcon';

export const NoLifeAndCultureContent = ({
  companyName,
}: {
  companyName: string;
}) => {
  const { t } = useTranslations(translations);
  const { country, language, environment } = useConfig();
  return (
    <Stack space="large" align="center">
      <NoLifeAndCultureIcon />
      <Stack space="xlarge" align="center">
        <Stack space="medium" align="center">
          <Text tone="secondary" align="center">
            {t('{companyName} hasn’t created a Life and Culture page yet.', {
              companyName,
            })}
          </Text>
          <Text tone="secondary" align="center">
            {t('Check back soon for content!')}
          </Text>
        </Stack>
        <Text tone="secondary" align="center">
          {t('If you’re from company, find out more', {
            companyName,
            Link: (linkText) => (
              <TextLink
                href={buildEmployerCompanyProfilePromoPageURI({
                  country,
                  language,
                  environment,
                })}
              >
                {linkText}
              </TextLink>
            ),
          })}
        </Text>
      </Stack>
    </Stack>
  );
};
