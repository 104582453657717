
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxIOC4meC4suC4l%2BC4teC4l%2BC4teC5iOC5geC4peC5ieC4pyIsInttaW51dGVzfW0gYWdvIjoie21pbnV0ZXN9IOC4meC4suC4l%2BC4teC4l%2BC4teC5iOC5geC4peC5ieC4pyIsIntob3Vyc31oIGFnbyI6Intob3Vyc30g4LiC4Lix4LmI4Lin4LmC4Lih4LiH4LiX4Li14LmI4Lic4LmI4Liy4LiZ4Lih4LiyIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c30g4Lin4Lix4LiZ4LiX4Li14LmI4Lic4LmI4Liy4LiZ4Lih4LiyIiwiMzBkKyBhZ28iOiLguKHguLLguIHguIHguKfguYjguLIgMzAg4Lin4Lix4LiZ4LiX4Li14LmI4Lic4LmI4Liy4LiZ4Lih4LiyIiwiY3VycmVudCBlbXBsb3llZSI6IuC4nuC4meC4seC4geC4h%2BC4suC4meC4m%2BC4seC4iOC4iOC4uOC4muC4seC4mSIsImZvcm1lciBlbXBsb3llZSI6IuC4reC4lOC4teC4leC4nuC4meC4seC4geC4h%2BC4suC4mSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0g4LiW4Li24LiHIHtlbmR9IOC4m%2BC4teC5g%2BC4meC4leC4s%2BC5geC4q%2BC4meC5iOC4hyIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6IuC4meC5ieC4reC4ouC4geC4p%2BC5iOC4siAxIOC4m%2BC4teC5g%2BC4meC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4mSIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiLguKHguLLguIHguIHguKfguYjguLIge3llYXJzfSDguJvguLXguYPguJnguJXguLPguYHguKvguJnguYjguIfguIfguLLguJkiLCJIaWdoIjoi4Liq4Li54LiHIiwiQXZlcmFnZSI6IuC4hOC5iOC4suC5gOC4ieC4peC4teC5iOC4oiIsIkxvdyI6IuC4leC5iOC4syIsIk5vdCBzcGVjaWZpZWQiOiLguYTguKHguYjguKPguLDguJrguLgifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxIOC4meC4suC4l%2BC4teC4l%2BC4teC5iOC5geC4peC5ieC4pyIsInttaW51dGVzfW0gYWdvIjoie21pbnV0ZXN9IOC4meC4suC4l%2BC4teC4l%2BC4teC5iOC5geC4peC5ieC4pyIsIntob3Vyc31oIGFnbyI6Intob3Vyc30g4LiC4Lix4LmI4Lin4LmC4Lih4LiH4LiX4Li14LmI4Lic4LmI4Liy4LiZ4Lih4LiyIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c30g4Lin4Lix4LiZ4LiX4Li14LmI4Lic4LmI4Liy4LiZ4Lih4LiyIiwiMzBkKyBhZ28iOiLguKHguLLguIHguIHguKfguYjguLIgMzAg4Lin4Lix4LiZ4LiX4Li14LmI4Lic4LmI4Liy4LiZ4Lih4LiyIiwiY3VycmVudCBlbXBsb3llZSI6IuC4nuC4meC4seC4geC4h%2BC4suC4meC4m%2BC4seC4iOC4iOC4uOC4muC4seC4mSIsImZvcm1lciBlbXBsb3llZSI6IuC4reC4lOC4teC4leC4nuC4meC4seC4geC4h%2BC4suC4mSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0g4LiW4Li24LiHIHtlbmR9IOC4m%2BC4teC5g%2BC4meC4leC4s%2BC5geC4q%2BC4meC5iOC4hyIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6IuC4meC5ieC4reC4ouC4geC4p%2BC5iOC4siAxIOC4m%2BC4teC5g%2BC4meC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4mSIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiLguKHguLLguIHguIHguKfguYjguLIge3llYXJzfSDguJvguLXguYPguJnguJXguLPguYHguKvguJnguYjguIfguIfguLLguJkiLCJIaWdoIjoi4Liq4Li54LiHIiwiQXZlcmFnZSI6IuC4hOC5iOC4suC5gOC4ieC4peC4teC5iOC4oiIsIkxvdyI6IuC4leC5iOC4syIsIk5vdCBzcGVjaWZpZWQiOiLguYTguKHguYjguKPguLDguJrguLgifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxIG1lbml0IHlhbmcgbGFsdSIsInttaW51dGVzfW0gYWdvIjoie21pbnV0ZXN9IG1lbml0IHlhbmcgbGFsdSIsIntob3Vyc31oIGFnbyI6Intob3Vyc30gamFtIHlhbmcgbGFsdSIsIntkYXlzfWQgYWdvIjoie2RheXN9IGhhcmkgeWFuZyBsYWx1IiwiMzBkKyBhZ28iOiIzMCsgaGFyaSB5YW5nIGxhbHUiLCJjdXJyZW50IGVtcGxveWVlIjoia2FyeWF3YW4gc2FhdCBpbmkiLCJmb3JtZXIgZW1wbG95ZWUiOiJtYW50YW4ga2FyeWF3YW4iLCJ7c3RhcnR9IHRvIHtlbmR9IHllYXJzIGluIHRoZSByb2xlLCAiOiJ7c3RhcnR9IGhpbmdnYSB7ZW5kfSB0YWh1biBtZW5qYWJhdCBwb3Npc2kgdGVyc2VidXQsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikt1cmFuZyBkYXJpIDEgdGFodW4gbWVuamFiYXQsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJMZWJpaCBkYXJpIHt5ZWFyc30gdGFodW4gbWVuamFiYXQsIHllYXJzIiwiSGlnaCI6IlRpbmdnaSIsIkF2ZXJhZ2UiOiJSYXRhLXJhdGEiLCJMb3ciOiJSZW5kYWgiLCJOb3Qgc3BlY2lmaWVkIjoiVGlkYWsgZGl0ZW50dWthbiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxIG1lbml0IHlhbmcgbGFsdSIsInttaW51dGVzfW0gYWdvIjoie21pbnV0ZXN9IG1lbml0IHlhbmcgbGFsdSIsIntob3Vyc31oIGFnbyI6Intob3Vyc30gamFtIHlhbmcgbGFsdSIsIntkYXlzfWQgYWdvIjoie2RheXN9IGhhcmkgeWFuZyBsYWx1IiwiMzBkKyBhZ28iOiIzMCsgaGFyaSB5YW5nIGxhbHUiLCJjdXJyZW50IGVtcGxveWVlIjoia2FyeWF3YW4gc2FhdCBpbmkiLCJmb3JtZXIgZW1wbG95ZWUiOiJtYW50YW4ga2FyeWF3YW4iLCJ7c3RhcnR9IHRvIHtlbmR9IHllYXJzIGluIHRoZSByb2xlLCAiOiJ7c3RhcnR9IGhpbmdnYSB7ZW5kfSB0YWh1biBtZW5qYWJhdCBwb3Npc2kgdGVyc2VidXQsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikt1cmFuZyBkYXJpIDEgdGFodW4gbWVuamFiYXQsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJMZWJpaCBkYXJpIHt5ZWFyc30gdGFodW4gbWVuamFiYXQsIHllYXJzIiwiSGlnaCI6IlRpbmdnaSIsIkF2ZXJhZ2UiOiJSYXRhLXJhdGEiLCJMb3ciOiJSZW5kYWgiLCJOb3Qgc3BlY2lmaWVkIjoiVGlkYWsgZGl0ZW50dWthbiJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxIOC4meC4suC4l%2BC4teC4l%2BC4teC5iOC5geC4peC5ieC4pyIsInttaW51dGVzfW0gYWdvIjoie21pbnV0ZXN9IOC4meC4suC4l%2BC4teC4l%2BC4teC5iOC5geC4peC5ieC4pyIsIntob3Vyc31oIGFnbyI6Intob3Vyc30g4LiC4Lix4LmI4Lin4LmC4Lih4LiH4LiX4Li14LmI4Lic4LmI4Liy4LiZ4Lih4LiyIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c30g4Lin4Lix4LiZ4LiX4Li14LmI4Lic4LmI4Liy4LiZ4Lih4LiyIiwiMzBkKyBhZ28iOiLguKHguLLguIHguIHguKfguYjguLIgMzAg4Lin4Lix4LiZ4LiX4Li14LmI4Lic4LmI4Liy4LiZ4Lih4LiyIiwiY3VycmVudCBlbXBsb3llZSI6IuC4nuC4meC4seC4geC4h%2BC4suC4meC4m%2BC4seC4iOC4iOC4uOC4muC4seC4mSIsImZvcm1lciBlbXBsb3llZSI6IuC4reC4lOC4teC4leC4nuC4meC4seC4geC4h%2BC4suC4mSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0g4LiW4Li24LiHIHtlbmR9IOC4m%2BC4teC5g%2BC4meC4leC4s%2BC5geC4q%2BC4meC5iOC4hyIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6IuC4meC5ieC4reC4ouC4geC4p%2BC5iOC4siAxIOC4m%2BC4teC5g%2BC4meC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4mSIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiLguKHguLLguIHguIHguKfguYjguLIge3llYXJzfSDguJvguLXguYPguJnguJXguLPguYHguKvguJnguYjguIfguIfguLLguJkiLCJIaWdoIjoi4Liq4Li54LiHIiwiQXZlcmFnZSI6IuC4hOC5iOC4suC5gOC4ieC4peC4teC5iOC4oiIsIkxvdyI6IuC4leC5iOC4syIsIk5vdCBzcGVjaWZpZWQiOiLguYTguKHguYjguKPguLDguJrguLgifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxIOC4meC4suC4l%2BC4teC4l%2BC4teC5iOC5geC4peC5ieC4pyIsInttaW51dGVzfW0gYWdvIjoie21pbnV0ZXN9IOC4meC4suC4l%2BC4teC4l%2BC4teC5iOC5geC4peC5ieC4pyIsIntob3Vyc31oIGFnbyI6Intob3Vyc30g4LiC4Lix4LmI4Lin4LmC4Lih4LiH4LiX4Li14LmI4Lic4LmI4Liy4LiZ4Lih4LiyIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c30g4Lin4Lix4LiZ4LiX4Li14LmI4Lic4LmI4Liy4LiZ4Lih4LiyIiwiMzBkKyBhZ28iOiLguKHguLLguIHguIHguKfguYjguLIgMzAg4Lin4Lix4LiZ4LiX4Li14LmI4Lic4LmI4Liy4LiZ4Lih4LiyIiwiY3VycmVudCBlbXBsb3llZSI6IuC4nuC4meC4seC4geC4h%2BC4suC4meC4m%2BC4seC4iOC4iOC4uOC4muC4seC4mSIsImZvcm1lciBlbXBsb3llZSI6IuC4reC4lOC4teC4leC4nuC4meC4seC4geC4h%2BC4suC4mSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0g4LiW4Li24LiHIHtlbmR9IOC4m%2BC4teC5g%2BC4meC4leC4s%2BC5geC4q%2BC4meC5iOC4hyIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6IuC4meC5ieC4reC4ouC4geC4p%2BC5iOC4siAxIOC4m%2BC4teC5g%2BC4meC4leC4s%2BC5geC4q%2BC4meC5iOC4h%2BC4h%2BC4suC4mSIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiLguKHguLLguIHguIHguKfguYjguLIge3llYXJzfSDguJvguLXguYPguJnguJXguLPguYHguKvguJnguYjguIfguIfguLLguJkiLCJIaWdoIjoi4Liq4Li54LiHIiwiQXZlcmFnZSI6IuC4hOC5iOC4suC5gOC4ieC4peC4teC5iOC4oiIsIkxvdyI6IuC4leC5iOC4syIsIk5vdCBzcGVjaWZpZWQiOiLguYTguKHguYjguKPguLDguJrguLgifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxIG1lbml0IHlhbmcgbGFsdSIsInttaW51dGVzfW0gYWdvIjoie21pbnV0ZXN9IG1lbml0IHlhbmcgbGFsdSIsIntob3Vyc31oIGFnbyI6Intob3Vyc30gamFtIHlhbmcgbGFsdSIsIntkYXlzfWQgYWdvIjoie2RheXN9IGhhcmkgeWFuZyBsYWx1IiwiMzBkKyBhZ28iOiIzMCsgaGFyaSB5YW5nIGxhbHUiLCJjdXJyZW50IGVtcGxveWVlIjoia2FyeWF3YW4gc2FhdCBpbmkiLCJmb3JtZXIgZW1wbG95ZWUiOiJtYW50YW4ga2FyeWF3YW4iLCJ7c3RhcnR9IHRvIHtlbmR9IHllYXJzIGluIHRoZSByb2xlLCAiOiJ7c3RhcnR9IGhpbmdnYSB7ZW5kfSB0YWh1biBtZW5qYWJhdCBwb3Npc2kgdGVyc2VidXQsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikt1cmFuZyBkYXJpIDEgdGFodW4gbWVuamFiYXQsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJMZWJpaCBkYXJpIHt5ZWFyc30gdGFodW4gbWVuamFiYXQsIHllYXJzIiwiSGlnaCI6IlRpbmdnaSIsIkF2ZXJhZ2UiOiJSYXRhLXJhdGEiLCJMb3ciOiJSZW5kYWgiLCJOb3Qgc3BlY2lmaWVkIjoiVGlkYWsgZGl0ZW50dWthbiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxIG1lbml0IHlhbmcgbGFsdSIsInttaW51dGVzfW0gYWdvIjoie21pbnV0ZXN9IG1lbml0IHlhbmcgbGFsdSIsIntob3Vyc31oIGFnbyI6Intob3Vyc30gamFtIHlhbmcgbGFsdSIsIntkYXlzfWQgYWdvIjoie2RheXN9IGhhcmkgeWFuZyBsYWx1IiwiMzBkKyBhZ28iOiIzMCsgaGFyaSB5YW5nIGxhbHUiLCJjdXJyZW50IGVtcGxveWVlIjoia2FyeWF3YW4gc2FhdCBpbmkiLCJmb3JtZXIgZW1wbG95ZWUiOiJtYW50YW4ga2FyeWF3YW4iLCJ7c3RhcnR9IHRvIHtlbmR9IHllYXJzIGluIHRoZSByb2xlLCAiOiJ7c3RhcnR9IGhpbmdnYSB7ZW5kfSB0YWh1biBtZW5qYWJhdCBwb3Npc2kgdGVyc2VidXQsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikt1cmFuZyBkYXJpIDEgdGFodW4gbWVuamFiYXQsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJMZWJpaCBkYXJpIHt5ZWFyc30gdGFodW4gbWVuamFiYXQsIHllYXJzIiwiSGlnaCI6IlRpbmdnaSIsIkF2ZXJhZ2UiOiJSYXRhLXJhdGEiLCJMb3ciOiJSZW5kYWgiLCJOb3Qgc3BlY2lmaWVkIjoiVGlkYWsgZGl0ZW50dWthbiJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiIxbSBhZ28iLCJ7bWludXRlc31tIGFnbyI6InttaW51dGVzfW0gYWdvIiwie2hvdXJzfWggYWdvIjoie2hvdXJzfWggYWdvIiwie2RheXN9ZCBhZ28iOiJ7ZGF5c31kIGFnbyIsIjMwZCsgYWdvIjoiMzBkKyBhZ28iLCJjdXJyZW50IGVtcGxveWVlIjoiY3VycmVudCBlbXBsb3llZSIsImZvcm1lciBlbXBsb3llZSI6ImZvcm1lciBlbXBsb3llZSIsIntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICI6IntzdGFydH0gdG8ge2VuZH0geWVhcnMgaW4gdGhlIHJvbGUsICIsIkxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICI6Ikxlc3MgdGhhbiAxIHllYXIgaW4gdGhlIHJvbGUsICIsIk1vcmUgdGhhbiB7eWVhcnN9IHllYXJzIGluIHRoZSByb2xlLCAiOiJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIiwiSGlnaCI6IkhpZ2giLCJBdmVyYWdlIjoiQXZlcmFnZSIsIkxvdyI6IkxvdyIsIk5vdCBzcGVjaWZpZWQiOiJOb3Qgc3BlY2lmaWVkIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiJbMW3MgiDEg8SDxIPEo8O2w7bDtl0iLCJ7bWludXRlc31tIGFnbyI6Ilt7bWludXRlc31tzIIgxIPEg8Sjw7bDtl0iLCJ7aG91cnN9aCBhZ28iOiJbe2hvdXJzfeG4qSDEg8SDxKPDtsO2XSIsIntkYXlzfWQgYWdvIjoiW3tkYXlzfcaMIMSDxIPEo8O2w7ZdIiwiMzBkKyBhZ28iOiJbMzDGjCsgxIPEg8SDxKPDtsO2w7ZdIiwiY3VycmVudCBlbXBsb3llZSI6IlvDp8eax5rFmcWZ4bq94bq94LiB4Li14LmJ4bmvIOG6veG6vW3MgsalxprDtsO2w73DveG6veG6veG6veG6vV0iLCJmb3JtZXIgZW1wbG95ZWUiOiJbxpLDtsO2xZltzILhur3hur3FmSDhur3hur1tzILGpcaaw7bDtsO9w73hur3hur3hur3hur1dIiwie3N0YXJ0fSB0byB7ZW5kfSB5ZWFycyBpbiB0aGUgcm9sZSwgIjoiW3tzdGFydH0g4bmvw7bDtsO2IHtlbmR9IMO9w73DveG6veG6veG6vcSDxIPEg8WZxaEgw6zDrMOs4LiB4Li14LmJIOG5r%2BG4qeG6veG6veG6vSDFmcO2w7bDtsaa4bq94bq94bq9LCBdIiwiTGVzcyB0aGFuIDEgeWVhciBpbiB0aGUgcm9sZSwgIjoiW8WB4bq94bq94bq9xaHFoSDhua%2FhuKnEg8SDxIPguIHguLXguYkgMSDDvcO9w73hur3hur3hur3Eg8SDxIPFmSDDrMOsw6zguIHguLXguYkg4bmv4bip4bq94bq94bq9IMWZw7bDtsO2xprhur3hur3hur0sIF0iLCJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIjoiW%2BG5gsO2w7bDtsWZ4bq94bq94bq9IOG5r%2BG4qcSDxIPEg%2BC4geC4teC5iSB7eWVhcnN9IMO9w73DveG6veG6veG6vcSDxIPEg8WZxaEgw6zDrMOs4LiB4Li14LmJIOG5r%2BG4qeG6veG6veG6vSDFmcO2w7bDtsaa4bq94bq94bq9LCBdIiwiSGlnaCI6IlvhuKjDrMOsw6zEo%2BG4qV0iLCJBdmVyYWdlIjoiW%2BG6rOG5veG6veG6vcWZxIPEg8Sj4bq94bq9XSIsIkxvdyI6IlvFgcO2w7bDtsW1XSIsIk5vdCBzcGVjaWZpZWQiOiJbw5HDtsO2w7bhua8gxaHGpeG6veG6veG6vcOnw6zDrMOsxpLDrMOsw6zhur3hur3hur3GjF0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyIxbSBhZ28iOiJbMW3MgiDEg8SDxIPEo8O2w7bDtl0iLCJ7bWludXRlc31tIGFnbyI6Ilt7bWludXRlc31tzIIgxIPEg8Sjw7bDtl0iLCJ7aG91cnN9aCBhZ28iOiJbe2hvdXJzfeG4qSDEg8SDxKPDtsO2XSIsIntkYXlzfWQgYWdvIjoiW3tkYXlzfcaMIMSDxIPEo8O2w7ZdIiwiMzBkKyBhZ28iOiJbMzDGjCsgxIPEg8SDxKPDtsO2w7ZdIiwiY3VycmVudCBlbXBsb3llZSI6IlvDp8eax5rFmcWZ4bq94bq94LiB4Li14LmJ4bmvIOG6veG6vW3MgsalxprDtsO2w73DveG6veG6veG6veG6vV0iLCJmb3JtZXIgZW1wbG95ZWUiOiJbxpLDtsO2xZltzILhur3hur3FmSDhur3hur1tzILGpcaaw7bDtsO9w73hur3hur3hur3hur1dIiwie3N0YXJ0fSB0byB7ZW5kfSB5ZWFycyBpbiB0aGUgcm9sZSwgIjoiW3tzdGFydH0g4bmvw7bDtsO2IHtlbmR9IMO9w73DveG6veG6veG6vcSDxIPEg8WZxaEgw6zDrMOs4LiB4Li14LmJIOG5r%2BG4qeG6veG6veG6vSDFmcO2w7bDtsaa4bq94bq94bq9LCBdIiwiTGVzcyB0aGFuIDEgeWVhciBpbiB0aGUgcm9sZSwgIjoiW8WB4bq94bq94bq9xaHFoSDhua%2FhuKnEg8SDxIPguIHguLXguYkgMSDDvcO9w73hur3hur3hur3Eg8SDxIPFmSDDrMOsw6zguIHguLXguYkg4bmv4bip4bq94bq94bq9IMWZw7bDtsO2xprhur3hur3hur0sIF0iLCJNb3JlIHRoYW4ge3llYXJzfSB5ZWFycyBpbiB0aGUgcm9sZSwgIjoiW%2BG5gsO2w7bDtsWZ4bq94bq94bq9IOG5r%2BG4qcSDxIPEg%2BC4geC4teC5iSB7eWVhcnN9IMO9w73DveG6veG6veG6vcSDxIPEg8WZxaEgw6zDrMOs4LiB4Li14LmJIOG5r%2BG4qeG6veG6veG6vSDFmcO2w7bDtsaa4bq94bq94bq9LCBdIiwiSGlnaCI6IlvhuKjDrMOsw6zEo%2BG4qV0iLCJBdmVyYWdlIjoiW%2BG6rOG5veG6veG6vcWZxIPEg8Sj4bq94bq9XSIsIkxvdyI6IlvFgcO2w7bDtsW1XSIsIk5vdCBzcGVjaWZpZWQiOiJbw5HDtsO2w7bhua8gxaHGpeG6veG6veG6vcOnw6zDrMOsxpLDrMOsw6zhur3hur3hur3GjF0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    