import {
  Heading,
  Stack,
  Text,
  TextLink,
  TextLinkButton,
  ContentBlock,
  Box,
} from 'braid-design-system';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';

import { createUrlResolver } from '@seek/melways-sites';
import type { BaseConfig } from '../../../providers/configProvider';
import { ErrorBoundaryIcon } from './ErrorBoundaryIcon';

export const ErrorBoundary = ({ config }: { config: BaseConfig }) => {
  const { country, product, siteName, language } = config;
  const { t } = useTranslations(translations);

  const resolveUrl = createUrlResolver({
    site: siteName,
    language,
  });

  const contactUsUrl = resolveUrl({
    country,
    path: product === 'candidate' ? '/contact-us' : '/contactus',
    language,
    product,
    consolidated: config.consolidated,
  });

  const homeUrl = resolveUrl({
    product,
    path: '/',
  });

  return (
    <ContentBlock>
      <Box paddingY="xxxlarge">
        <Stack space="xlarge" align="center">
          <ErrorBoundaryIcon />
          <Heading level="3">{t("We're working on it")}</Heading>
          <Stack space="medium" align="center">
            <Text tone="secondary">
              {t(
                "We can't load this right now but we're doing our best to fix it.",
              )}
            </Text>
            <Text tone="secondary">
              <TextLinkButton onClick={() => location.reload()}>
                {t('Try refreshing the page or check back later.')}
              </TextLinkButton>
            </Text>
            <Text tone="secondary">
              <TextLink href={homeUrl}>{t('Go to homepage')}</TextLink>
            </Text>
          </Stack>
          <Text tone="secondary">
            {t(
              "If this still doesn't work, reach out to our Customer Service team for assistance.",
              {
                TextLink: (children) => (
                  <TextLink href={contactUsUrl}>{children}</TextLink>
                ),
              },
            )}
          </Text>
        </Stack>
      </Box>
    </ContentBlock>
  );
};
