import { CompanyProfileCarousel } from '@seek/branding-assets';
import { useState } from 'react';
import { useConfig, buildWriteAReviewURI } from '@seek/libs-shared';

const HideReviewsCountries = ['th', 'hk', 'nz'];
export function ExploreCompanies({ userId }: { userId?: number }) {
  const [isCompanyCarouselError, setIsCompanyCarouselError] = useState(false);
  const { country, language, environment, zone, consolidated } = useConfig();

  const writeAReviewUrl = buildWriteAReviewURI({
    country,
    environment,
    language,
    consolidated,
  });

  if (isCompanyCarouselError) return null;

  return (
    <CompanyProfileCarousel
      analyticsQueryParams="cid=sk-reviews-carousel&ref=sk-reviews-carousel"
      hideReviews={HideReviewsCountries.includes(country)}
      onReviewsLandingPage
      onError={() => {
        setIsCompanyCarouselError(true);
      }}
      writeAReviewUrl={writeAReviewUrl}
      payload={{
        currentPage: 'company overview',
        siteCountry: country,
        siteLanguage: language,
        zone,
        isLoggedIn: Boolean(userId),
        siteSection: 'discover',
        loginId: userId?.toString() || '',
        companyViewOrigin: 'sk-reviews-carousel',
      }}
    />
  );
}
