import type { CompanyReviewSummaryBreakdown } from '../models/CompanyProfile';

export type DefaultDesc = (typeof defaultDesc)[number];

const defaultDesc = [
  'Work/Life balance',
  'Career development',
  'Benefits & perks',
  'Management',
  'Working environment',
  'Diversity & equal opportunity',
] as const;

export const getRatingByDescription = (
  description: DefaultDesc,
  reviewSummaryBreakdowns: CompanyReviewSummaryBreakdown[],
) => {
  const ratingItem = reviewSummaryBreakdowns.find(
    (item) => item.description === description,
  );
  return ratingItem?.rating && ratingItem?.rating > 0
    ? {
        description,
        rating: ratingItem.rating,
      }
    : null;
};

export const mapRatingsBreakDown = (
  reviewSummaryBreakdowns: CompanyReviewSummaryBreakdown[],
) => [
    getRatingByDescription('Work/Life balance', reviewSummaryBreakdowns),
    getRatingByDescription('Career development', reviewSummaryBreakdowns),
    getRatingByDescription('Benefits & perks', reviewSummaryBreakdowns),
    getRatingByDescription('Management', reviewSummaryBreakdowns),
    getRatingByDescription('Working environment', reviewSummaryBreakdowns),
    getRatingByDescription(
      'Diversity & equal opportunity',
      reviewSummaryBreakdowns,
    ),
  ].filter((item) => item !== null);
