import {
  Box,
  Button,
  Column,
  Columns,
  Heading,
  Text,
  Stack,
  ContentBlock,
  Hidden,
} from 'braid-design-system';

import { StickyFooterIcon } from './stickyFooterIcon';
import * as styles from './stickyFooter.css';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import { useAuth } from '../../../../shared/providers/authProvider';

interface props {
  trackSignInClicked: () => void;
  trackRegisterClicked: () => void;
}
export const StickyFooter = ({
  trackSignInClicked,
  trackRegisterClicked,
}: props) => {
  const { t } = useTranslations(translations);
  const { ssoLogin } = useAuth();

  const onSignInClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.currentTarget.blur();
    trackSignInClicked();
    if (typeof ssoLogin === 'function') {
      ssoLogin();
    }
  };

  const onRegisterClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const returnUrl = encodeURIComponent(window.location.href);
    e.currentTarget.blur();
    trackRegisterClicked();
    window.location.href = `/oauth/register?returnUrl=${returnUrl}`;
  };

  const stickyFooterButtons = (
    <Box className={styles.stickyFooterButtons}>
      <Button variant="solid" tone="formAccent" onClick={onSignInClick}>
        {t('Sign in')}
      </Button>
      <Button variant="ghost" tone="formAccent" onClick={onRegisterClick}>
        {t('Register')}
      </Button>
    </Box>
  );

  return (
    <Box className={styles.stickyFooter} width="full" transition="fast">
      <ContentBlock>
        <Columns space="large" align="center" alignY="center">
          <Hidden below="desktop">
            <Column width="content">
              <Box className={styles.stickyFooterIcon}>
                <StickyFooterIcon />
              </Box>
            </Column>
          </Hidden>
          <Column>
            <Hidden above="tablet">
              <Box className={styles.stickyFooterIcon}>
                <StickyFooterIcon />
              </Box>
            </Hidden>
            <Box className={styles.stickyFooterText}>
              <Stack space="small">
                <Heading level="3">{t('Sign in to see more')}</Heading>
                <Text>
                  {t(
                    'Access over 2 million reviews across thousands of companies.',
                  )}
                </Text>
              </Stack>
            </Box>
          </Column>
          <Hidden below="desktop">
            <Column>{stickyFooterButtons}</Column>
          </Hidden>
        </Columns>

        <Hidden above="tablet">
          <Columns space="none">
            <Column>{stickyFooterButtons}</Column>
          </Columns>
        </Hidden>
      </ContentBlock>
    </Box>
  );
};
