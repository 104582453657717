import type { SalaryCurrency, SalaryFrequency } from '@seek/salary-data';

const getAUDANNUALSalaryRate = [
  { min: 30000, max: 30000 },
  { min: 30000, max: 40000 },
  { min: 40000, max: 50000 },
  { min: 50000, max: 60000 },
  { min: 60000, max: 70000 },
  { min: 70000, max: 80000 },
  { min: 80000, max: 90000 },
  { min: 90000, max: 100000 },
  { min: 100000, max: 120000 },
  { min: 120000, max: 150000 },
  { min: 150000, max: 200000 },
  { min: 200000, max: 250000 },
  { min: 250000, max: 300000 },
  { min: 300000, max: 400000 },
  { min: 400000, max: 400000 },
];

const getAUDHOURLYSalaryRate = [
  { min: 10, max: 10 },
  { min: 10, max: 19 },
  { min: 20, max: 29 },
  { min: 30, max: 39 },
  { min: 40, max: 49 },
  { min: 50, max: 59 },
  { min: 60, max: 69 },
  { min: 70, max: 79 },
  { min: 80, max: 89 },
  { min: 90, max: 99 },
  { min: 100, max: 100 },
];

const getNZDANNUALSalaryRate = [
  { min: 30000, max: 30000 },
  { min: 30000, max: 40000 },
  { min: 40000, max: 50000 },
  { min: 50000, max: 60000 },
  { min: 60000, max: 70000 },
  { min: 70000, max: 80000 },
  { min: 80000, max: 100000 },
  { min: 100000, max: 120000 },
  { min: 120000, max: 150000 },
  { min: 150000, max: 200000 },
  { min: 200000, max: 250000 },
  { min: 250000, max: 350000 },
  { min: 350000, max: 350000 },
];

const getNZDHOURLYSalaryRate = [
  { min: 15, max: 15 },
  { min: 15, max: 20 },
  { min: 20, max: 25 },
  { min: 25, max: 30 },
  { min: 30, max: 35 },
  { min: 35, max: 40 },
  { min: 40, max: 50 },
  { min: 50, max: 60 },
  { min: 60, max: 75 },
  { min: 75, max: 100 },
  { min: 100, max: 125 },
  { min: 125, max: 175 },
  { min: 175, max: 175 },
];

const getIDRMONTHLYSalaryRate = [
  { min: 1000000, max: 1000000 },
  { min: 1000000, max: 2000000 },
  { min: 2000000, max: 3000000 },
  { min: 3000000, max: 5000000 },
  { min: 5000000, max: 7000000 },
  { min: 7000000, max: 10000000 },
  { min: 10000000, max: 15000000 },
  { min: 15000000, max: 20000000 },
  { min: 20000000, max: 25000000 },
  { min: 25000000, max: 30000000 },
  { min: 30000000, max: 45000000 },
  { min: 45000000, max: 60000000 },
  { min: 45000000, max: 60000000 },
  { min: 60000000, max: 75000000 },
  { min: 75000000, max: 75000000 },
];

const getIDRHOURLYSalaryRate = [
  { min: 8000, max: 8000 },
  { min: 8000, max: 12000 },
  { min: 12000, max: 18000 },
  { min: 18000, max: 30000 },
  { min: 30000, max: 40000 },
  { min: 40000, max: 55000 },
  { min: 55000, max: 80000 },
  { min: 80000, max: 110000 },
  { min: 110000, max: 150000 },
  { min: 150000, max: 200000 },
  { min: 200000, max: 250000 },
  { min: 250000, max: 350000 },
  { min: 350000, max: 450000 },
  { min: 450000, max: 450000 },
];

const getPHPMONTHLYSalaryRate = [
  { min: 10000, max: 10000 },
  { min: 10000, max: 15000 },
  { min: 15000, max: 20000 },
  { min: 20000, max: 30000 },
  { min: 30000, max: 40000 },
  { min: 40000, max: 50000 },
  { min: 50000, max: 60000 },
  { min: 60000, max: 70000 },
  { min: 70000, max: 80000 },
  { min: 80000, max: 100000 },
  { min: 100000, max: 120000 },
  { min: 120000, max: 150000 },
  { min: 150000, max: 150000 },
];

const getPHPHOURLYSalaryRate = [
  { min: 50, max: 50 },
  { min: 50, max: 100 },
  { min: 100, max: 150 },
  { min: 150, max: 200 },
  { min: 200, max: 250 },
  { min: 250, max: 300 },
  { min: 300, max: 350 },
  { min: 350, max: 400 },
  { min: 400, max: 450 },
  { min: 450, max: 500 },
  { min: 500, max: 700 },
  { min: 700, max: 700 },
];

const getSGDMONTHLYSalaryRate = [
  { min: 1000, max: 1000 },
  { min: 1000, max: 2000 },
  { min: 2000, max: 3000 },
  { min: 3000, max: 4000 },
  { min: 4000, max: 5000 },
  { min: 5000, max: 6000 },
  { min: 6000, max: 7000 },
  { min: 7000, max: 8000 },
  { min: 8000, max: 9000 },
  { min: 9000, max: 10000 },
  { min: 10000, max: 12000 },
  { min: 12000, max: 15000 },
  { min: 15000, max: 20000 },
  { min: 20000, max: 25000 },
  { min: 25000, max: 25000 },
];

const getSGDHOURLYSalaryRate = [
  { min: 10, max: 10 },
  { min: 10, max: 20 },
  { min: 20, max: 30 },
  { min: 30, max: 40 },
  { min: 40, max: 50 },
  { min: 50, max: 60 },
  { min: 60, max: 70 },
  { min: 70, max: 80 },
  { min: 80, max: 90 },
  { min: 90, max: 100 },
  { min: 100, max: 120 },
  { min: 120, max: 150 },
  { min: 150, max: 200 },
  { min: 200, max: 250 },
  { min: 250, max: 250 },
];

const getMYRMONTHLYSalaryRate = [
  { min: 1000, max: 1000 },
  { min: 1000, max: 2000 },
  { min: 2000, max: 3000 },
  { min: 3000, max: 4000 },
  { min: 4000, max: 5000 },
  { min: 5000, max: 6000 },
  { min: 6000, max: 7000 },
  { min: 7000, max: 8000 },
  { min: 8000, max: 9000 },
  { min: 9000, max: 10000 },
  { min: 10000, max: 12000 },
  { min: 12000, max: 15000 },
  { min: 15000, max: 20000 },
  { min: 20000, max: 25000 },
  { min: 25000, max: 25000 },
];

const getMYRHOURLYSalaryRate = [
  { min: 10, max: 10 },
  { min: 10, max: 20 },
  { min: 20, max: 30 },
  { min: 30, max: 40 },
  { min: 40, max: 50 },
  { min: 50, max: 60 },
  { min: 60, max: 70 },
  { min: 70, max: 80 },
  { min: 80, max: 90 },
  { min: 90, max: 100 },
  { min: 100, max: 120 },
  { min: 120, max: 150 },
  { min: 150, max: 200 },
  { min: 200, max: 250 },
  { min: 250, max: 250 },
];

const getHKDMONTHLYSalaryRate = [
  { min: 11000, max: 11000 },
  { min: 11000, max: 14000 },
  { min: 14000, max: 17000 },
  { min: 17000, max: 20000 },
  { min: 20000, max: 25000 },
  { min: 25000, max: 30000 },
  { min: 30000, max: 35000 },
  { min: 35000, max: 40000 },
  { min: 40000, max: 50000 },
  { min: 50000, max: 60000 },
  { min: 60000, max: 80000 },
  { min: 80000, max: 120000 },
  { min: 120000, max: 120000 },
];

const getHKDHOURLYSalaryRate = [
  { min: 40, max: 40 },
  { min: 40, max: 60 },
  { min: 60, max: 80 },
  { min: 80, max: 100 },
  { min: 100, max: 125 },
  { min: 125, max: 150 },
  { min: 150, max: 175 },
  { min: 175, max: 200 },
  { min: 200, max: 250 },
  { min: 250, max: 350 },
  { min: 350, max: 450 },
  { min: 450, max: 600 },
  { min: 600, max: 600 },
];

const getTHBMONTHLYSalaryRate = [
  { min: 15000, max: 15000 },
  { min: 15000, max: 20000 },
  { min: 20000, max: 25000 },
  { min: 25000, max: 30000 },
  { min: 30000, max: 40000 },
  { min: 40000, max: 50000 },
  { min: 50000, max: 60000 },
  { min: 60000, max: 70000 },
  { min: 70000, max: 80000 },
  { min: 80000, max: 100000 },
  { min: 100000, max: 150000 },
  { min: 150000, max: 200000 },
  { min: 200000, max: 200000 },
];

const getTHBHOURLYSalaryRate = [
  { min: 100, max: 100 },
  { min: 100, max: 125 },
  { min: 125, max: 150 },
  { min: 150, max: 200 },
  { min: 200, max: 250 },
  { min: 250, max: 300 },
  { min: 300, max: 350 },
  { min: 350, max: 400 },
  { min: 400, max: 500 },
  { min: 500, max: 600 },
  { min: 600, max: 900 },
  { min: 900, max: 1200 },
  { min: 1200, max: 1200 },
];

const salaryRange = {
  getAUDANNUALSalaryRateMethod: () => getAUDANNUALSalaryRate,
  getAUDHOURLYSalaryRateMethod: () => getAUDHOURLYSalaryRate,
  getNZDANNUALSalaryRateMethod: () => getNZDANNUALSalaryRate,
  getNZDHOURLYSalaryRateMethod: () => getNZDHOURLYSalaryRate,
  getIDRMONTHLYSalaryRateMethod: () => getIDRMONTHLYSalaryRate,
  getIDRHOURLYSalaryRateMethod: () => getIDRHOURLYSalaryRate,
  getPHPMONTHLYSalaryRateMethod: () => getPHPMONTHLYSalaryRate,
  getPHPHOURLYSalaryRateMethod: () => getPHPHOURLYSalaryRate,
  getSGDMONTHLYSalaryRateMethod: () => getSGDMONTHLYSalaryRate,
  getSGDHOURLYSalaryRateMethod: () => getSGDHOURLYSalaryRate,
  getMYRMONTHLYSalaryRateMethod: () => getMYRMONTHLYSalaryRate,
  getMYRHOURLYSalaryRateMethod: () => getMYRHOURLYSalaryRate,
  getHKDMONTHLYSalaryRateMethod: () => getHKDMONTHLYSalaryRate,
  getHKDHOURLYSalaryRateMethod: () => getHKDHOURLYSalaryRate,
  getTHBMONTHLYSalaryRateMethod: () => getTHBMONTHLYSalaryRate,
  getTHBHOURLYSalaryRateMethod: () => getTHBHOURLYSalaryRate,
};

export const getSalaryRate = (
  currency: SalaryCurrency,
  payPeriod: SalaryFrequency,
) => {
  const methodName = `get${currency}${payPeriod}SalaryRateMethod`;

  if (methodName in salaryRange) {
    return salaryRange[methodName as keyof typeof salaryRange]();
  }
};
