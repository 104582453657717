import type { MonthPickerValue, QuestionProps } from '../../../type';
import { Box, Inline, MonthPicker } from 'braid-design-system';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import { useConfig } from '@seek/libs-shared';
import { getMonthAbbreviations } from './utils';
import * as styles from './WorkHistoryQuestion.css';

export const StartQuestion = ({
  value,
  message,
  tone,
  onChange,
}: QuestionProps<MonthPickerValue>) => {
  const { t } = useTranslations(translations);
  const { locale } = useConfig();

  const months = getMonthAbbreviations(locale);

  return (
    <Inline collapseBelow="tablet" alignY="center" space="medium">
      <Box className={[styles.monthPickerWidth]}>
        <MonthPicker
          id="whenStart"
          label={t('When did you start')}
          onChange={onChange}
          value={value ?? {}}
          monthNames={months}
          reserveMessageSpace
          message={message}
          tone={tone}
        />
      </Box>
    </Inline>
  );
};
