export const NoJobsFoundIcon = () => (
  <svg
    width="128"
    height="128"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.7078 33.6848C50.7508 33.6848 49.1643 35.2713 49.1643 37.2283V50.8712C49.1643 52.8282 50.7508 54.4147 52.7078 54.4147H75.2926C77.2496 54.4147 78.8361 52.8282 78.8361 50.8712V37.2283C78.8361 35.2713 77.2496 33.6848 75.2926 33.6848H52.7078ZM41.4795 37.2283C41.4795 31.0271 46.5065 26 52.7078 26H75.2926C81.4938 26 86.5209 31.0271 86.5209 37.2283V50.8712C86.5209 57.0724 81.4938 62.0995 75.2926 62.0995H52.7078C46.5066 62.0995 41.4795 57.0724 41.4795 50.8712V37.2283Z"
      fill="#0D3880"
    />
    <path
      d="M99.7986 65.0859H28.2012C24.8875 65.0859 22.2012 67.7722 22.2012 71.0859V96.2404C22.2012 99.5541 24.8875 102.24 28.2012 102.24H99.7986C103.112 102.24 105.799 99.5541 105.799 96.2404V71.0859C105.799 67.7722 103.112 65.0859 99.7986 65.0859Z"
      fill="#F470BD"
    />
    <path
      d="M102.677 70.6951L64.6775 75.0132C64.2273 75.0644 63.7727 75.0644 63.3225 75.0132L25.3225 70.6951C22.2904 70.3505 20 67.785 20 64.7334V47.5469C20 44.2332 22.6863 41.5469 26 41.5469H102C105.314 41.5469 108 44.2332 108 47.5469V64.7334C108 67.785 105.71 70.3505 102.677 70.6951Z"
      fill="#EE399E"
    />
    <path
      d="M71.0347 69.8008H56.9653C56.324 69.8008 55.8042 70.3206 55.8042 70.9619V77.819C55.8042 78.4603 56.324 78.9801 56.9653 78.9801H71.0347C71.676 78.9801 72.1958 78.4603 72.1958 77.819V70.9619C72.1958 70.3206 71.676 69.8008 71.0347 69.8008Z"
      fill="#F7F8FB"
    />
  </svg>
);
