
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiU2lnbiBpbiB0byBzZWUgbW9yZSIsIkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiI6IkFjY2VzcyBvdmVyIDIgbWlsbGlvbiByZXZpZXdzIGFjcm9zcyB0aG91c2FuZHMgb2YgY29tcGFuaWVzLiIsIlNpZ24gaW4iOiJTaWduIGluIiwiUmVnaXN0ZXIiOiJSZWdpc3RlciJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiW8Wgw6zDrMOsxKPguIHguLXguYkgw6zDrMOs4LiB4Li14LmJIOG5r8O2w7bDtiDFoeG6veG6veG6veG6veG6veG6vSBtzILDtsO2w7bFmeG6veG6veG6vV0iLCJBY2Nlc3Mgb3ZlciAyIG1pbGxpb24gcmV2aWV3cyBhY3Jvc3MgdGhvdXNhbmRzIG9mIGNvbXBhbmllcy4iOiJb4bqsw6fDp%2BG6veG6veG6vcWhxaEgw7bDtsO24bm94bq94bq94bq9xZkgMiBtzILDrMOsw6zGmsaaw6zDrMOsw7bDtsO24LiB4Li14LmJIMWZ4bq94bq94bq94bm9w6zDrMOs4bq94bq94bq9xbXFoSDEg8SDxIPDp8WZw7bDtsO2xaHFoSDhua%2FhuKnDtsO2w7bHmseax5rFocSDxIPEg%2BC4geC4teC5icaMxaEgw7bDtsO2xpIgw6fDtsO2w7ZtzILGpcSDxIPEg%2BC4geC4teC5icOsw6zDrOG6veG6veG6vcWhLl0iLCJTaWduIGluIjoiW8Wgw6zDrMOsxKPguIHguLXguYkgw6zDrMOs4LiB4Li14LmJXSIsIlJlZ2lzdGVyIjoiW8WY4bq94bq94bq9xKPDrMOsw6zFoeG5r%2BG6veG6veG6vcWZXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTaWduIGluIHRvIHNlZSBtb3JlIjoiW8Wgw6zDrMOsxKPguIHguLXguYkgw6zDrMOs4LiB4Li14LmJIOG5r8O2w7bDtiDFoeG6veG6veG6veG6veG6veG6vSBtzILDtsO2w7bFmeG6veG6veG6vV0iLCJBY2Nlc3Mgb3ZlciAyIG1pbGxpb24gcmV2aWV3cyBhY3Jvc3MgdGhvdXNhbmRzIG9mIGNvbXBhbmllcy4iOiJb4bqsw6fDp%2BG6veG6veG6vcWhxaEgw7bDtsO24bm94bq94bq94bq9xZkgMiBtzILDrMOsw6zGmsaaw6zDrMOsw7bDtsO24LiB4Li14LmJIMWZ4bq94bq94bq94bm9w6zDrMOs4bq94bq94bq9xbXFoSDEg8SDxIPDp8WZw7bDtsO2xaHFoSDhua%2FhuKnDtsO2w7bHmseax5rFocSDxIPEg%2BC4geC4teC5icaMxaEgw7bDtsO2xpIgw6fDtsO2w7ZtzILGpcSDxIPEg%2BC4geC4teC5icOsw6zDrOG6veG6veG6vcWhLl0iLCJTaWduIGluIjoiW8Wgw6zDrMOsxKPguIHguLXguYkgw6zDrMOs4LiB4Li14LmJXSIsIlJlZ2lzdGVyIjoiW8WY4bq94bq94bq9xKPDrMOsw6zFoeG5r%2BG6veG6veG6vcWZXSJ9!"
        )
      )
      });
  
      export { translations as default };
    