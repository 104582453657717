// eslint-disable-next-line no-control-regex
const nonLatinRegex = /[^\x00-\x7F]/;

/**
 * This regex can be used to detect the presence of these non-Latin characters in a given string.
 * It matches any character that falls outside the ASCII range (0-127). Note that it also matches for "&" and ",".
 */
const locationFilteredRegex = new RegExp(`[&,]|${nonLatinRegex.source}`);

const commonEncoding = (textToEncode: string, regex?: RegExp) =>
  encodeText(textToEncode, regex)
    .replace(/-/g, '+')
    .replace(/%20/g, '-')
    .replace(/%26/g, '&')
    .replace(/%2C/g, ',')
    .replace(/!/g, '%21')
    .replace(/\*/g, '%2A');

const encodeText = (text: string, filteredEncodingPattern?: RegExp) =>
  text
    .split('')
    .map((char) =>
      filteredEncodingPattern?.test(char) ? char : encodeURIComponent(char),
    )
    .join('');

//* Copied from: `import { getSlugById } from '@seek/seek-jobs-seo';`
export const slugifyLocation = ({
  locationContextualName,
  filteredEncoding = false,
}: {
  locationContextualName: string;
  filteredEncoding?: boolean;
}): string => {
  const textToEncode = locationContextualName.trim().replace(/ +/g, ' ');

  return commonEncoding(
    textToEncode,
    filteredEncoding ? locationFilteredRegex : undefined,
  );
};

export const validateAndParseReviewLocationSlugUrl = (
  url: string,
): string | undefined => {
  // using URL so was can take advantage of its parsing capabilities
  const parsedUrl = new URL(url, 'http://dummy');

  // This regex does just checks on the path not the query params
  const regex =
    /^\/(?:(?:id|th)\/)?companies\/[^/]+\/reviews\/locations\/([^\s/]+)$/u;

  const match = parsedUrl.pathname.match(regex);

  if (!match || match.length < 2) {
    return undefined;
  }

  return decodeURIComponent(match[1]);
};
