
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJXaGVuIGRpZCB5b3Ugc3RhcnQ%2FIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IldoZW4gZGlkIHlvdSBmaW5pc2g%2FIiwiSSBzdGlsbCB3b3JrIGhlcmUiOiJJIHN0aWxsIHdvcmsgaGVyZSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJbxbThuKnhur3hur3hur3guIHguLXguYkgxozDrMOsw6zGjCDDvcO9w73DtsO2w7bHmseax5ogxaHhua%2FEg8SDxIPFmeG5rz9dIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IlvFtOG4qeG6veG6veG6veC4geC4teC5iSDGjMOsw6zDrMaMIMO9w73DvcO2w7bDtseax5rHmiDGksOsw6zDrOC4geC4teC5icOsw6zDrMWh4bipP10iLCJJIHN0aWxsIHdvcmsgaGVyZSI6IlvDjyDFoeG5r8Osw6zDrMaaxpogxbXDtsO2w7bFmcS3IOG4qeG6veG6veG6vcWZ4bq94bq94bq9XSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVuIGRpZCB5b3Ugc3RhcnQiOiJbxbThuKnhur3hur3hur3guIHguLXguYkgxozDrMOsw6zGjCDDvcO9w73DtsO2w7bHmseax5ogxaHhua%2FEg8SDxIPFmeG5rz9dIiwiV2hlbiBkaWQgeW91IGZpbmlzaCI6IlvFtOG4qeG6veG6veG6veC4geC4teC5iSDGjMOsw6zDrMaMIMO9w73DvcO2w7bDtseax5rHmiDGksOsw6zDrOC4geC4teC5icOsw6zDrMWh4bipP10iLCJJIHN0aWxsIHdvcmsgaGVyZSI6IlvDjyDFoeG5r8Osw6zDrMaaxpogxbXDtsO2w7bFmcS3IOG4qeG6veG6veG6vcWZ4bq94bq94bq9XSJ9!"
        )
      )
      });
  
      export { translations as default };
    