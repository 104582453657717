
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiLguKrguYjguKfguJnguITguYnguJnguKvguLLguIfguLLguJkiLCJSZWZpbmUgYnkgam9iIHR5cGUiOiLguJvguKPguLHguJrguYHguJXguYjguIfguJXguLLguKHguJvguKPguLDguYDguKDguJfguIfguLLguJkiLCJTaG93IGpvYnMiOiLguYHguKrguJTguIfguIfguLLguJkiLCJlZzogQmFrZXIiOiLguYDguIrguYjguJk6IOC4hOC4meC4l%2BC4s%2BC4guC4meC4oeC4m%2BC4seC4hyIsInt0b3RhbEpvYnN9IGpvYnMgaW4ge2NvbXBhbnlOYW1lfSI6IjxzdHJvbmc%2Be3RvdGFsSm9ic308L3N0cm9uZz4g4LiH4Liy4LiZ4LmD4LiZe2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiLguKrguYjguKfguJnguITguYnguJnguKvguLLguIfguLLguJkiLCJSZWZpbmUgYnkgam9iIHR5cGUiOiLguJvguKPguLHguJrguYHguJXguYjguIfguJXguLLguKHguJvguKPguLDguYDguKDguJfguIfguLLguJkiLCJTaG93IGpvYnMiOiLguYHguKrguJTguIfguIfguLLguJkiLCJlZzogQmFrZXIiOiLguYDguIrguYjguJk6IOC4hOC4meC4l%2BC4s%2BC4guC4meC4oeC4m%2BC4seC4hyIsInt0b3RhbEpvYnN9IGpvYnMgaW4ge2NvbXBhbnlOYW1lfSI6IjxzdHJvbmc%2Be3RvdGFsSm9ic308L3N0cm9uZz4g4LiH4Liy4LiZ4LmD4LiZe2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJCYWdpYW4gUGVuY2FyaWFuIEtlcmphIiwiUmVmaW5lIGJ5IGpvYiB0eXBlIjoiRmlsdGVyIGJlcmRhc2Fya2FuIHRpcGUgcGVrZXJqYWFuIiwiU2hvdyBqb2JzIjoiVGFtcGlsa2FuIHBla2VyamFhbiIsImVnOiBCYWtlciI6Im1pczogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIHBla2VyamFhbiBkaSB7Y29tcGFueU5hbWV9In0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJCYWdpYW4gUGVuY2FyaWFuIEtlcmphIiwiUmVmaW5lIGJ5IGpvYiB0eXBlIjoiRmlsdGVyIGJlcmRhc2Fya2FuIHRpcGUgcGVrZXJqYWFuIiwiU2hvdyBqb2JzIjoiVGFtcGlsa2FuIHBla2VyamFhbiIsImVnOiBCYWtlciI6Im1pczogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIHBla2VyamFhbiBkaSB7Y29tcGFueU5hbWV9In0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiLguKrguYjguKfguJnguITguYnguJnguKvguLLguIfguLLguJkiLCJSZWZpbmUgYnkgam9iIHR5cGUiOiLguJvguKPguLHguJrguYHguJXguYjguIfguJXguLLguKHguJvguKPguLDguYDguKDguJfguIfguLLguJkiLCJTaG93IGpvYnMiOiLguYHguKrguJTguIfguIfguLLguJkiLCJlZzogQmFrZXIiOiLguYDguIrguYjguJk6IOC4hOC4meC4l%2BC4s%2BC4guC4meC4oeC4m%2BC4seC4hyIsInt0b3RhbEpvYnN9IGpvYnMgaW4ge2NvbXBhbnlOYW1lfSI6IjxzdHJvbmc%2Be3RvdGFsSm9ic308L3N0cm9uZz4g4LiH4Liy4LiZ4LmD4LiZe2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiLguKrguYjguKfguJnguITguYnguJnguKvguLLguIfguLLguJkiLCJSZWZpbmUgYnkgam9iIHR5cGUiOiLguJvguKPguLHguJrguYHguJXguYjguIfguJXguLLguKHguJvguKPguLDguYDguKDguJfguIfguLLguJkiLCJTaG93IGpvYnMiOiLguYHguKrguJTguIfguIfguLLguJkiLCJlZzogQmFrZXIiOiLguYDguIrguYjguJk6IOC4hOC4meC4l%2BC4s%2BC4guC4meC4oeC4m%2BC4seC4hyIsInt0b3RhbEpvYnN9IGpvYnMgaW4ge2NvbXBhbnlOYW1lfSI6IjxzdHJvbmc%2Be3RvdGFsSm9ic308L3N0cm9uZz4g4LiH4Liy4LiZ4LmD4LiZe2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJCYWdpYW4gUGVuY2FyaWFuIEtlcmphIiwiUmVmaW5lIGJ5IGpvYiB0eXBlIjoiRmlsdGVyIGJlcmRhc2Fya2FuIHRpcGUgcGVrZXJqYWFuIiwiU2hvdyBqb2JzIjoiVGFtcGlsa2FuIHBla2VyamFhbiIsImVnOiBCYWtlciI6Im1pczogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIHBla2VyamFhbiBkaSB7Y29tcGFueU5hbWV9In0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJCYWdpYW4gUGVuY2FyaWFuIEtlcmphIiwiUmVmaW5lIGJ5IGpvYiB0eXBlIjoiRmlsdGVyIGJlcmRhc2Fya2FuIHRpcGUgcGVrZXJqYWFuIiwiU2hvdyBqb2JzIjoiVGFtcGlsa2FuIHBla2VyamFhbiIsImVnOiBCYWtlciI6Im1pczogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIHBla2VyamFhbiBkaSB7Y29tcGFueU5hbWV9In0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJKb2IgU2VhcmNoIHNlY3Rpb24iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJSZWZpbmUgYnkgam9iIHR5cGUiLCJTaG93IGpvYnMiOiJTaG93IGpvYnMiLCJlZzogQmFrZXIiOiJlZzogQmFrZXIiLCJ7dG90YWxKb2JzfSBqb2JzIGluIHtjb21wYW55TmFtZX0iOiI8c3Ryb25nPnt0b3RhbEpvYnN9PC9zdHJvbmc%2BIGpvYnMgaW4ge2NvbXBhbnlOYW1lfSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJbxLTDtsO2w7bDnyDFoOG6veG6veG6vcSDxIPEg8WZw6fhuKkgxaHhur3hur3hur3Dp%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5iV0iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJbxZjhur3hur3hur3GksOsw6zDrOC4geC4teC5ieG6veG6veG6vSDDn8O9w73DvSDEtcO2w7bDtsOfIOG5r8O9w73Dvcal4bq94bq94bq9XSIsIlNob3cgam9icyI6IlvFoOG4qcO2w7bDtsW1IMS1w7bDtsO2w5%2FFoV0iLCJlZzogQmFrZXIiOiJb4bq94bq94bq9xKM6IOG6nsSDxIPEg8S34bq94bq94bq9xZldIiwie3RvdGFsSm9ic30gam9icyBpbiB7Y29tcGFueU5hbWV9IjoiWzxzdHJvbmc%2Be3RvdGFsSm9ic308L3N0cm9uZz4gxLXDtsO2w7bDn8WhIMOsw6zDrOC4geC4teC5iSB7Y29tcGFueU5hbWV9XSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgU2VhcmNoIHNlY3Rpb24iOiJbxLTDtsO2w7bDnyDFoOG6veG6veG6vcSDxIPEg8WZw6fhuKkgxaHhur3hur3hur3Dp%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5iV0iLCJSZWZpbmUgYnkgam9iIHR5cGUiOiJbxZjhur3hur3hur3GksOsw6zDrOC4geC4teC5ieG6veG6veG6vSDDn8O9w73DvSDEtcO2w7bDtsOfIOG5r8O9w73Dvcal4bq94bq94bq9XSIsIlNob3cgam9icyI6IlvFoOG4qcO2w7bDtsW1IMS1w7bDtsO2w5%2FFoV0iLCJlZzogQmFrZXIiOiJb4bq94bq94bq9xKM6IOG6nsSDxIPEg8S34bq94bq94bq9xZldIiwie3RvdGFsSm9ic30gam9icyBpbiB7Y29tcGFueU5hbWV9IjoiWzxzdHJvbmc%2Be3RvdGFsSm9ic308L3N0cm9uZz4gxLXDtsO2w7bDn8WhIMOsw6zDrOC4geC4teC5iSB7Y29tcGFueU5hbWV9XSJ9!"
        )
      )
      });
  
      export { translations as default };
    