import type { CompanyProfile } from '@seek/libs-shared';
import type { Rating } from './components/StarSelector/StarSelector';
import type { MonthPicker } from 'braid-design-system';
import type { SalaryCurrency } from '@seek/salary-data';


export enum Progress {
  OverallRating = 1,
  YourRole = 2,
  YourExperience = 3,
  YourSalary = 4,
  YourFeedback = 5,
  SubmitSuccess = 6,
};

export type WorkType =
  | 'Full Time'
  | 'Part Time'
  | 'Contract / Temporary'
  | 'Volunteer / Intern';

export type MonthPickerValue = Parameters<
  Parameters<typeof MonthPicker>[0]['onChange']
>[0];

export const salaryTypeValues = [
  'Monthly',
  'AnnualPackage',
  'HourlyRate',
] as const;

export type SalaryType = (typeof salaryTypeValues)[number];

export interface Salary {
  includeBonus?: boolean;
  type: SalaryType;
  minimum?: number;
  maximum?: number;
  currency?: SalaryCurrency;
}

export interface ReviewPayload {
  overallRating?: Rating;
  careerOpportunityRating?: Rating;
  diversityRating?: Rating;
  executiveManagementRating?: Rating;
  workEnvironmentRating?: Rating;
  workLifeBalanceRating?: Rating;
  perksAndBenefitsRating?: Rating;
  companyName?: string;
  companyId?: string;
  organisationId?: string;
  theGoodThings?: string;
  theChallenges?: string;
  workHistoryFrom?: MonthPickerValue;
  workHistoryTo?: MonthPickerValue;
  isStillWorkHere?: boolean;
  workType?: WorkType;
  jobTitle?: string;
  title?: string;
  fullLocation?: string;
  locationId?: number;
  salary?: Salary;
}

export interface PageProps {
  experimentNumber?: number;
  review: ReviewPayload;
  pageNumber: Progress;
  companyProfile?: CompanyProfile;
  setPage: (page: Progress) => void;
  setter: React.Dispatch<React.SetStateAction<ReviewPayload>>;
  onSubmit?: () => void;
}

export interface QuestionProps<T = string> {
  value?: T;
  tone?: 'critical' | 'neutral';
  message?: string;
  onChange: (value: T) => void;
}
