import { Box, Loader } from 'braid-design-system';

export const PageLoader = () => (
  <Box
    width="full"
    alignItems="center"
    display="flex"
    justifyContent="center"
    paddingY="xxxlarge"
  >
    <Loader size="large" />
  </Box>
);
