import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { createLegacyCompanyProfileService } from './legacyCompanyProfileService/legacyCompanyProfileService';
import { createLegacyCompanyProfileServiceMock } from './legacyCompanyProfileService/legacyCompanyProfileService.mock';

import {
  type CandidateService,
  createCandidateService,
} from './candidateService/candidateService';
import {
  type CompanyProfileService,
  createCompanyProfileService,
} from './companyProfileService/companyProfileService';
import { createCompanyProfileServiceMock } from './companyProfileService/companyProfileService.mock';
import {
  type SitemapService,
  createSitemapService,
} from './sitemapService/sitemapService';
import { createSitemapServiceMock } from './sitemapService/sitemapService.mock';
import { createCandidateServiceMock } from './candidateService/candidateService.mock';
import type { AppConfig } from '../config';
import type { Logger } from '@seek/libs-shared';
import { createRoleService, type RoleService } from './roleService/roleService';
import { createRoleServiceMock } from './roleService/roleService.mock';
import { createLocationServiceMock } from './locationService/locationService.mock';
import {
  createLocationService,
  type LocationService,
} from './locationService/locationService';

export interface Services {
  companyProfileService: CompanyProfileService;
  candidateService: CandidateService;
  roleService: RoleService;
  legacyCompanyProfileService: ReturnType<
    typeof createLegacyCompanyProfileService
  >;
  sitemapService: SitemapService;
  locationService: LocationService;
}

export const createServices = ({
  client,
  config,
  logger,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  config: AppConfig;
  logger: Logger;
}): Services => {
  if (config.environment === 'local') {
    return {
      companyProfileService: createCompanyProfileServiceMock({ config }),
      candidateService: createCandidateServiceMock(),
      roleService: createRoleServiceMock(),
      legacyCompanyProfileService: createLegacyCompanyProfileServiceMock(),
      sitemapService: createSitemapServiceMock({ config }),
      locationService: createLocationServiceMock(),
    };
  }

  if (config.environment === 'development') {
    return {
      companyProfileService: createCompanyProfileService({
        client,
        config,
        logger,
      }),
      candidateService: createCandidateService({ client, config }),
      roleService: createRoleService({ client, config }),
      legacyCompanyProfileService: createLegacyCompanyProfileService({
        companyProfilesApiBaseUrl: config.companyProfilesApiBaseUrl,
        config,
        logger,
      }),
      sitemapService: createSitemapServiceMock({ config }),
      locationService: createLocationService({ client, config }),
    };
  }

  return {
    companyProfileService: createCompanyProfileService({
      client,
      config,
      logger,
    }),
    candidateService: createCandidateService({ client, config }),
    roleService: createRoleService({ client, config }),
    legacyCompanyProfileService: createLegacyCompanyProfileService({
      companyProfilesApiBaseUrl: config.companyProfilesApiBaseUrl,
      config,
      logger,
    }),
    sitemapService: createSitemapService({ config }),
    locationService: createLocationService({ client, config }),
  };
};

export {
  createLocationSlugService,
  type LocationSlugService,
} from './locationService/locationSlugService';
