import type { Jobs } from '@seek/libs-shared';
import type { GetJobsListResponse } from './types';

export const mapJobWorkLocations = (
  location: string,
  area: string,
  jobLocation: { label: string; countryCode: string } | undefined,
) => {
  if (jobLocation?.label?.length) {
    return [jobLocation.label];
  }

  if (location && area) {
    const separator = '-';
    return [`${location} ${separator} ${area}`];
  }

  if (location) {
    return [location];
  }

  if (area) {
    return [area];
  }

  return [];
};

export const mapJobsModel = (legacyJobs: GetJobsListResponse): Jobs => ({
  jobs: legacyJobs?.data.map((job) => ({
    id: job.id,
    title: job.title,
    url: `/job/${job.id}`,
    advertiser: job.advertiser?.description || '',
    classification: job.classification?.description || '',
    subClassification: job.subClassification?.description || '',
    description: job.teaser,
    createdAt: job.listingDate,
    bulletPoints: job.bulletPoints,
    salaryDisplayFlag: false,
    location: mapJobWorkLocations(job.location, job.area, job.jobLocation)?.[0],
  })),
  totalJobs: legacyJobs.solMetadata.totalJobCount,
});

export const buildUrl = (prefix: string, params: string[]): string =>
  `${prefix}?${params.filter((param) => param !== '').join('&')}`;

export const buildJobSearchUrlForSearch = ({
  organisationId,
  page,
  pageSize,
  country,
  source,
}: {
  organisationId: string;
  page: number;
  pageSize: number;
  country: string;
  source: string;
}): string =>
  buildUrl(`/search`, [
    `companyid=${organisationId}`,
    page && page.toString() !== '1' ? `page=${page}` : '',
    `pagesize=${pageSize}`,
    `siteKey=${country}`,
    `source=${source}`,
  ]);
