export const ErrorBoundaryIcon = () => (
  <svg
    width="205"
    height="206"
    viewBox="0 0 205 206"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1744_4721)">
      <path
        d="M68.1514 5.9231V6.53043C68.7357 6.32198 69.3235 6.12119 69.9126 5.9231H68.1514Z"
        fill="#F0F6FC"
      />
      <path
        d="M155.949 47.3909L108.048 47.3692V64.2945H178.837V37.7734H166.1C165.809 43.1338 161.382 47.3934 155.949 47.3909Z"
        fill="#DCE5F2"
      />
      <path
        d="M60.335 57.5269V64.2944H104.018V47.3672L70.5144 47.3521C64.8932 47.3495 60.335 51.9057 60.335 57.5269Z"
        fill="#DCE5F2"
      />
      <path
        d="M178.529 34.3406C174.725 30.1345 170.576 26.2453 166.128 22.7183V34.3406H178.529Z"
        fill="#DCE5F2"
      />
      <path
        d="M50.1599 76.5188H39.3266C33.7072 76.5188 29.1518 81.0742 29.1518 86.6936C29.1518 89.6959 27.8436 92.386 25.7754 94.2484H69.1926V67.7273H60.2298C59.5534 72.6906 55.3088 76.5188 50.1599 76.5188Z"
        fill="#DCE5F2"
      />
      <path
        d="M146.318 94.2484H204.59C203.807 85.0026 201.794 76.1089 198.717 67.7273H146.318L146.318 94.2484Z"
        fill="#DCE5F2"
      />
      <path
        d="M197.383 64.2946C193.711 55.3125 188.803 46.9697 182.866 39.4758V64.2946H197.383Z"
        fill="#DCE5F2"
      />
      <path
        d="M142.289 67.7273H73.2222V94.2484H142.289V67.7273Z"
        fill="#DCE5F2"
      />
      <path
        d="M69.1924 127.635H3.15283C5.47998 137.057 9.1064 145.966 13.8396 154.157H69.1924V127.635Z"
        fill="#DCE5F2"
      />
      <path
        d="M15.9185 157.59C20.6166 165.031 26.2466 171.823 32.6449 177.806V157.59H15.9185Z"
        fill="#DCE5F2"
      />
      <path
        d="M32.645 97.6812H0.313525C0.221829 99.4436 0.162598 101.215 0.162598 103C0.162598 110.27 0.925533 117.361 2.36606 124.202H32.645L32.645 97.6812Z"
        fill="#DCE5F2"
      />
      <path
        d="M43.3589 186.559C51.2203 192.139 59.9049 196.634 69.1925 199.834V186.559H43.3589Z"
        fill="#DCE5F2"
      />
      <path
        d="M105.741 183.127V157.59H36.6748V181.381C37.3771 181.972 38.0865 182.554 38.8047 183.127H105.741Z"
        fill="#DCE5F2"
      />
      <path
        d="M73.2222 186.559V201.127C82.5177 203.902 92.3645 205.4 102.562 205.4C116.65 205.4 130.073 202.554 142.289 197.407V186.559H73.2222Z"
        fill="#DCE5F2"
      />
      <path
        d="M182.866 166.538C185.134 163.676 187.25 160.689 189.207 157.59H182.866V166.538Z"
        fill="#DCE5F2"
      />
      <path
        d="M105.741 97.6812H36.6748V124.202H105.741V97.6812Z"
        fill="#DCE5F2"
      />
      <path
        d="M178.837 97.6812H109.771V124.202H178.837V97.6812Z"
        fill="#DCE5F2"
      />
      <path
        d="M146.318 154.157H191.285C196.018 145.966 199.645 137.057 201.972 127.635H146.318V154.157Z"
        fill="#DCE5F2"
      />
      <path
        d="M142.289 127.635H73.2222V154.157H142.289V127.635Z"
        fill="#DCE5F2"
      />
      <path
        d="M178.837 171.316V157.59H109.771V183.127H166.321C170.816 179.544 175.003 175.592 178.837 171.316Z"
        fill="#DCE5F2"
      />
      <path
        d="M146.318 195.6C151.746 193.031 156.914 190.004 161.766 186.559H146.318V195.6Z"
        fill="#DCE5F2"
      />
      <path
        d="M182.866 124.202H202.759C204.2 117.361 204.963 110.27 204.963 103C204.963 101.215 204.909 99.4434 204.819 97.6812H182.866V124.202Z"
        fill="#DCE5F2"
      />
      <path
        d="M146.318 94.2484V67.7273H198.717C198.294 66.5726 197.846 65.4293 197.382 64.2945H182.866V39.4757C181.474 37.7181 180.031 36.0019 178.529 34.3406H166.128V37.2161C166.128 37.4042 166.11 37.5877 166.1 37.7734H178.836V64.2945H108.048V47.3691L104.018 47.3673V64.2945H60.3348V66.344C60.3348 66.8144 60.2917 67.2738 60.2299 67.7273H69.1926V94.2484H25.7754C23.9726 95.8718 21.5941 96.8684 18.977 96.8684H0.350436C0.334468 97.1384 0.327533 97.4108 0.313477 97.6812H32.645V124.202H2.36602C2.60855 125.354 2.87217 126.498 3.15303 127.635H69.1926V154.157H13.8398C14.5098 155.317 15.2049 156.46 15.9185 157.59H32.645V177.806C33.9564 179.032 35.3 180.224 36.6746 181.381V157.59H105.741V183.127H38.8046C40.2906 184.311 41.8072 185.458 43.359 186.559H69.1926V199.834C70.5234 200.292 71.8673 200.722 73.2223 201.127V186.559H142.289V197.407C143.648 196.834 144.99 196.229 146.318 195.6V186.559H161.766C163.318 185.458 164.834 184.311 166.32 183.127H109.77V157.59H178.836V171.316C180.226 169.765 181.571 168.172 182.866 166.538V157.59H189.207C189.92 156.46 190.615 155.317 191.285 154.157H146.318V127.635H201.972C202.253 126.498 202.517 125.354 202.759 124.202H182.866V97.6812H204.819C204.76 96.5322 204.686 95.3871 204.59 94.2484H146.318ZM73.2223 67.7273H142.289V94.2484H73.2223V67.7273ZM36.6746 124.202V97.6812H105.741V124.202H36.6746ZM142.289 154.157H73.2223V127.635H142.289V154.157ZM178.836 124.202H109.77V97.6812H178.836V124.202Z"
        fill="#F0F6FC"
      />
      <path
        d="M102.563 0.599958C48.9707 0.146558 3.08776 43.5265 0.350586 96.8684H18.9771C24.5695 96.8624 29.1461 92.317 29.152 86.6936C29.1519 81.0742 33.7074 76.5187 39.3268 76.5187H50.1601C55.8097 76.5474 60.3384 71.8495 60.3349 66.3439C60.3349 66.3439 60.3349 64.2945 60.3349 64.2945V57.5269C60.3349 51.9058 64.8931 47.3496 70.5143 47.3521C91.9855 47.3613 134.209 47.3814 155.949 47.3909C161.591 47.4092 166.107 42.7793 166.128 37.216C166.128 37.2161 166.128 34.3406 166.128 34.3406V22.7182C148.664 8.87207 126.581 0.599958 102.563 0.599958Z"
        fill="#EE399E"
      />
      <path
        d="M167.896 80.644H166.187V85.2554H167.896C170.439 85.2554 172.507 87.3233 172.507 89.8663V104.117C172.507 106.659 170.439 108.728 167.896 108.728H134.484C129.398 108.728 125.261 112.866 125.261 117.951V126.606H129.872V117.951C129.872 115.408 131.941 113.339 134.484 113.339H167.896C172.981 113.339 177.118 109.202 177.118 104.117V89.8663C177.118 84.7811 172.981 80.644 167.896 80.644Z"
        fill="#072462"
      />
      <path
        d="M166.187 75.6165C166.187 74.3431 165.154 73.3108 163.881 73.3108L85.4888 73.3108C84.2154 73.3108 83.1831 74.3431 83.1831 75.6165V98.6731C83.1831 99.9464 84.2154 100.979 85.4888 100.979H163.881C165.155 100.979 166.187 99.9464 166.187 98.673V75.6165Z"
        fill="#EE399E"
      />
      <path
        d="M167.801 181.45L155.215 168.865C154.985 166.759 143.007 164.952 143.007 164.952L114.186 168.688C110.816 167.912 109.086 172.167 111.97 174.482C117.12 178.617 118.349 181.071 118.349 181.071C118.349 181.071 124.568 185.478 128.862 187.589C131.735 189 136.17 189.979 139.096 189.732L145.392 196.028C145.701 195.885 146.011 195.746 146.318 195.6C151.746 193.031 156.913 190.004 161.766 186.559C163.318 185.458 164.834 184.311 166.32 183.126C166.89 182.673 167.44 182.196 167.999 181.73C167.933 181.637 167.874 181.539 167.796 181.455L167.801 181.45Z"
        fill="#F4BAAF"
      />
      <path
        d="M138.901 151.367C138.912 151.342 138.922 151.315 138.934 151.29V150.215C138.269 149.069 135.906 146.71 135.023 145.239C134.471 144.319 133.688 142.989 133.404 141.952C132.944 140.276 132.798 138.547 133.012 136.821C133.379 133.854 136.39 132.217 138.934 132.997V128.913C138.934 127.639 137.901 126.606 136.627 126.606H118.184C116.91 126.606 115.877 127.639 115.877 128.913V141.16L121.958 143.382C124.35 144.255 125.58 146.903 124.707 149.295C124.048 151.098 122.381 152.24 120.576 152.319C120.567 152.381 120.57 152.443 120.548 152.504C120.537 152.532 120.518 152.554 120.505 152.58C122.363 153.694 123.232 156.002 122.461 158.112C121.823 159.862 120.234 160.985 118.492 161.123C118.51 161.204 118.522 161.282 118.518 161.366L118.827 161.479C121.219 162.353 122.449 165 121.576 167.392C120.904 169.23 119.184 170.378 117.337 170.416C117.313 170.462 117.291 170.51 117.26 170.55C118.511 171.778 119.005 173.667 118.364 175.421C117.914 176.654 116.991 177.574 115.877 178.061V182.003C115.877 183.277 116.91 184.31 118.184 184.31H136.627C137.901 184.31 138.934 183.277 138.934 182.003V174.104C138.409 173.123 137.939 172.112 137.535 171.072C135.013 164.57 136.07 157.581 138.901 151.367Z"
        fill="#0D3880"
      />
      <path
        d="M155.692 158.938C152.351 153.235 145.431 141.928 141.592 135.209C140.923 134.017 139.966 133.314 138.934 132.997C136.39 132.217 133.379 133.854 133.012 136.821C132.798 138.547 132.944 140.276 133.404 141.952C133.688 142.989 134.471 144.319 135.023 145.24C135.906 146.71 138.269 149.069 138.934 150.215C139.053 150.421 139.124 150.592 139.115 150.707C139.1 150.911 139.018 151.102 138.934 151.29C138.922 151.316 138.913 151.342 138.901 151.367C136.07 157.581 135.014 164.57 137.535 171.072C137.939 172.112 138.409 173.123 138.934 174.104C140.427 176.897 142.378 179.441 144.627 181.672C145.515 182.554 146.765 183.338 147.966 184.18C151.143 181.742 154.326 179.087 156.126 175.493C158.486 170.784 157.552 162.119 155.692 158.938Z"
        fill="#F4BAAF"
      />
      <path
        d="M110.652 148.89C110.82 148.427 111.33 148.191 111.792 148.359L120.017 151.364C120.417 151.511 120.631 151.914 120.576 152.319C122.381 152.24 124.048 151.098 124.707 149.295C125.58 146.903 124.35 144.256 121.958 143.382L115.877 141.161L113.732 140.377C111.34 139.503 108.693 140.734 107.819 143.126C106.975 145.437 108.099 147.982 110.334 148.94C110.435 148.949 110.537 148.959 110.639 148.975C110.646 148.947 110.642 148.918 110.652 148.89Z"
        fill="#F6BCB1"
      />
      <path
        d="M109.506 158.148C109.685 157.691 110.2 157.466 110.657 157.643L117.964 160.491C118.249 160.602 118.428 160.847 118.492 161.123C120.234 160.985 121.822 159.861 122.461 158.112C123.232 156.002 122.363 153.694 120.505 152.58C120.355 152.892 120.047 153.088 119.712 153.088C119.611 153.088 119.508 153.071 119.407 153.034L111.182 150.03C110.749 149.871 110.527 149.412 110.639 148.975C110.537 148.959 110.435 148.949 110.334 148.939C108.297 148.748 106.309 149.933 105.574 151.944C104.7 154.335 105.931 156.983 108.323 157.856L109.265 158.201C109.341 158.205 109.417 158.215 109.493 158.223C109.5 158.198 109.497 158.173 109.506 158.148Z"
        fill="#F6BCB1"
      />
      <path
        d="M108.428 166.83C108.606 166.373 109.122 166.146 109.579 166.324L116.886 169.173C117.343 169.351 117.569 169.867 117.391 170.324C117.378 170.358 117.354 170.385 117.338 170.416C119.184 170.378 120.904 169.23 121.576 167.392C122.449 165 121.219 162.352 118.827 161.479L118.518 161.366C118.513 161.458 118.505 161.551 118.47 161.642C118.333 161.994 117.997 162.209 117.641 162.209C117.534 162.209 117.425 162.189 117.319 162.148L110.012 159.299C109.579 159.131 109.365 158.66 109.493 158.223C109.417 158.215 109.341 158.205 109.265 158.201C107.294 158.095 105.401 159.272 104.688 161.223C103.815 163.615 105.045 166.262 107.437 167.136L108.44 167.502C108.352 167.295 108.34 167.056 108.428 166.83Z"
        fill="#F6BCB1"
      />
      <path
        d="M118.364 175.421C119.005 173.667 118.511 171.779 117.26 170.55C117.091 170.765 116.833 170.891 116.562 170.891C116.455 170.891 116.346 170.871 116.239 170.83L108.933 167.98C108.902 167.969 108.882 167.944 108.854 167.93C107.452 168.305 106.248 169.333 105.712 170.8C104.838 173.191 106.069 175.839 108.461 176.712L112.451 178.17C113.611 178.594 114.829 178.519 115.877 178.061C116.991 177.574 117.914 176.654 118.364 175.421Z"
        fill="#F6BCB1"
      />
      <path
        d="M120.018 151.364L111.792 148.359C111.331 148.191 110.82 148.427 110.652 148.89C110.642 148.918 110.646 148.947 110.639 148.975C110.527 149.412 110.749 149.871 111.182 150.03L119.408 153.034C119.508 153.071 119.611 153.088 119.713 153.088C120.047 153.088 120.355 152.892 120.506 152.58C120.518 152.553 120.537 152.532 120.548 152.504C120.57 152.443 120.567 152.381 120.576 152.319C120.631 151.914 120.418 151.51 120.018 151.364Z"
        fill="#CE8071"
      />
      <path
        d="M117.964 160.491L110.657 157.643C110.199 157.466 109.685 157.691 109.506 158.148C109.496 158.173 109.5 158.198 109.493 158.223C109.365 158.66 109.578 159.131 110.011 159.299L117.318 162.148C117.424 162.189 117.533 162.208 117.641 162.208C117.997 162.208 118.333 161.994 118.469 161.642C118.505 161.551 118.513 161.458 118.518 161.366C118.522 161.282 118.51 161.203 118.492 161.123C118.427 160.847 118.248 160.602 117.964 160.491Z"
        fill="#CE8071"
      />
      <path
        d="M116.239 170.83C116.346 170.871 116.455 170.891 116.562 170.891C116.833 170.891 117.091 170.765 117.26 170.55C117.291 170.51 117.313 170.462 117.337 170.416C117.354 170.385 117.377 170.358 117.391 170.324C117.569 169.867 117.343 169.352 116.885 169.173L109.579 166.324C109.121 166.146 108.606 166.373 108.428 166.83C108.339 167.056 108.351 167.296 108.44 167.502C108.519 167.686 108.667 167.833 108.854 167.93C108.882 167.944 108.902 167.969 108.933 167.98L116.239 170.83Z"
        fill="#CE8071"
      />
    </g>
    <defs>
      <clipPath id="clip0_1744_4721">
        <rect width="205" height="206" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
