export const SuccessHiFiveImage = () => (
  <svg
    width="180"
    height="180"
    viewBox="0 0 180 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M96.0399 49.8153C96.0399 53.9755 92.6674 57.348 88.5072 57.348C84.3471 57.348 80.9746 53.9755 80.9746 49.8153C80.9746 45.6552 84.3471 42.2827 88.5072 42.2827C92.6674 42.2827 96.0399 45.6552 96.0399 49.8153ZM88.5072 47.2728C87.103 47.2728 85.9647 48.4111 85.9647 49.8153C85.9647 51.2195 87.103 52.3579 88.5072 52.3579C89.9114 52.3579 91.0498 51.2195 91.0498 49.8153C91.0498 48.4111 89.9114 47.2728 88.5072 47.2728Z"
      fill="#FDC221"
    />
    <path
      d="M60.4394 38.4663C60.4394 42.654 57.0446 46.0488 52.8569 46.0488C48.6692 46.0488 45.2744 42.654 45.2744 38.4663C45.2744 34.2786 48.6692 30.8838 52.8569 30.8838C57.0446 30.8838 60.4394 34.2786 60.4394 38.4663ZM52.8569 35.9069C51.4434 35.9069 50.2975 37.0528 50.2975 38.4663C50.2975 39.8798 51.4434 41.0257 52.8569 41.0257C54.2704 41.0257 55.4163 39.8798 55.4163 38.4663C55.4163 37.0528 54.2704 35.9069 52.8569 35.9069Z"
      fill="#56D3DF"
    />
    <path
      d="M73.4415 53.5815C67.2013 53.5815 62.1426 58.6402 62.1426 64.8805H73.4415V53.5815Z"
      fill="#6E56E6"
    />
    <path
      d="M122.404 27.2173C128.645 27.2173 133.703 32.276 133.703 38.5162H122.404V27.2173Z"
      fill="#FDC221"
    />
    <path
      d="M108.442 27.2175L100.452 35.207L103.115 37.8702L111.105 29.8807L108.442 27.2175Z"
      fill="#56D3DF"
    />
    <path
      d="M124.287 46.0941H120.52C120.52 48.5511 118.936 50.6402 116.737 51.4076V49.8517C116.737 46.7414 114.207 44.2109 111.096 44.2109C107.987 44.2109 105.456 46.7414 105.456 49.8517C105.456 52.9619 107.987 55.4919 111.096 55.4919H112.637C111.857 57.6676 109.78 59.2311 107.339 59.2311V62.9975C111.928 62.9975 115.754 59.6887 116.57 55.3329C120.951 54.537 124.287 50.7013 124.287 46.0941ZM109.222 49.8517C109.222 48.8182 110.063 47.9773 111.096 47.9773C112.13 47.9773 112.971 48.8182 112.971 49.8517V51.7256H111.096C110.063 51.7256 109.222 50.8852 109.222 49.8517Z"
      fill="#6E56E6"
    />
    <path
      d="M79.0912 23.443C79.0912 20.3327 76.5607 17.8022 73.4509 17.8022C70.3402 17.8022 67.8102 20.3327 67.8102 23.443V24.9828C65.6346 24.2029 64.071 22.1259 64.071 19.6854H60.3047C60.3047 24.2746 63.6136 28.0998 67.9693 28.9162C68.7651 33.2975 72.6008 36.6329 77.2081 36.6329V32.8666C74.7511 32.8666 72.662 31.2821 71.8947 29.0833H73.4509C76.5607 29.0833 79.0912 26.5532 79.0912 23.443ZM71.5765 23.443C71.5765 22.4095 72.4174 21.5686 73.4509 21.5686C74.4845 21.5686 75.3249 22.4095 75.3249 23.443C75.3249 24.4765 74.4845 25.3169 73.4509 25.3169H71.5765V23.443Z"
      fill="#EE399E"
    />
    <path
      d="M96.0398 72.413C98.1198 72.413 99.8061 70.7268 99.8061 68.6467C99.8061 66.5666 98.1198 64.8804 96.0398 64.8804C93.9597 64.8804 92.2734 66.5666 92.2734 68.6467C92.2734 70.7268 93.9597 72.413 96.0398 72.413Z"
      fill="#F470BD"
    />
    <path
      d="M96.0398 23.4511C98.1198 23.4511 99.8061 21.7649 99.8061 19.6848C99.8061 17.6047 98.1198 15.9185 96.0398 15.9185C93.9597 15.9185 92.2734 17.6047 92.2734 19.6848C92.2734 21.7649 93.9597 23.4511 96.0398 23.4511Z"
      fill="#FDC221"
    />
    <path
      d="M83.6378 73.0595L80.9746 75.7227L88.9642 83.7122L91.6273 81.049L83.6378 73.0595Z"
      fill="#56D3DF"
    />
    <path
      d="M81.9819 109.063C79.3607 110.957 74.8746 120.088 74.8746 120.088C74.8746 120.088 74.7858 120.224 74.6438 120.438C74.5843 113.916 75.1571 95.2492 75.2575 88.5875C75.3081 85.2376 75.5271 79.5344 70.0392 79.3062C65.8787 79.1341 64.805 84.4113 64.805 84.4113C64.805 84.4113 66.5654 74.9579 60.317 74.608C54.5952 74.2875 53.9268 80.8836 53.6749 83.5641C53.6092 82.0855 52.8345 79.2358 49.0437 79.1959C47.1473 79.1749 45.5347 80.8171 44.6408 83.1743C43.3559 86.5631 43.0407 91.3403 42.9812 94.3811C42.7233 92.1551 41.7597 89.4918 38.581 89.6106C33.7587 89.7904 33.4928 97.3621 33.307 101.426C33.0787 106.417 33.0503 110.962 32.8841 115.959C32.6848 121.963 32.4236 126.333 32.8439 130.519L23.5132 140.537C23.5127 140.537 23.5123 140.538 23.5119 140.538L23.4569 140.597L23.4601 140.601C20.8711 143.535 23.6936 150.828 29.8158 156.965C35.9083 163.071 42.9827 165.742 45.7398 163.013L45.7452 163.019L55.1554 152.915C55.7845 152.973 56.4197 153.022 57.0622 153.062C68.2847 153.753 78.1505 147.152 81.8322 135.978C84.2433 129.612 87.0974 121.908 89.3522 114.861C90.9247 109.947 85.6245 106.432 81.9819 109.063Z"
      fill="#EBAB7D"
    />
    <path
      d="M52.9381 106.81C52.923 106.81 52.9092 106.81 52.8955 106.81C52.3696 106.787 51.9603 106.341 51.9823 105.815L52.8749 84.8583C52.8983 84.3296 53.3831 83.9012 53.8706 83.9444C54.3965 83.9671 54.8058 84.4127 54.7838 84.9394L53.8912 105.896C53.8692 106.409 53.4462 106.81 52.9381 106.81Z"
      fill="#C17742"
    />
    <path
      d="M63.9121 106.765C63.9011 106.765 63.8901 106.765 63.8778 106.765C63.3504 106.745 62.9385 106.303 62.9577 105.776L63.6979 85.1937C63.7157 84.6781 64.1401 84.2729 64.6523 84.2729C64.6633 84.2729 64.6743 84.2729 64.6866 84.2736C65.214 84.2929 65.626 84.7351 65.6068 85.2624L64.8665 105.845C64.8487 106.36 64.4244 106.765 63.9121 106.765Z"
      fill="#C17742"
    />
    <path
      d="M42.6422 112.314H42.6216C42.0943 112.302 41.6754 111.865 41.6878 111.338L42.027 95.7539C42.038 95.2265 42.4266 94.8159 43.002 94.8194C43.5293 94.831 43.9482 95.2677 43.9358 95.7951L43.5966 111.379C43.5857 111.9 43.1599 112.314 42.6422 112.314Z"
      fill="#C17742"
    />
    <path
      d="M149.911 107.695C147.312 109.508 145.401 112.106 143.74 116.938C143.491 117.664 142.732 118.929 142.719 116.964C142.703 114.448 141.9 110.947 141.623 108.253C141.129 103.455 139.924 87.944 139.122 82.4031C138.016 74.7512 129.213 75.3892 128.381 81.5485C127.999 73.8257 117.826 73.2413 117.094 81.2809C115.375 75.374 107.639 76.9902 106.86 83.3213C106.388 87.1543 106.822 94.1719 106.822 94.1719C104.693 89.4585 98.084 91.2643 97.9488 96.7948C97.7067 106.673 97.9549 111.911 97.9549 111.911C97.9549 111.911 98.6649 126.842 100.048 133.864C101.559 141.538 104 144.045 110.626 146.596C113.22 147.595 115.001 147.665 117.255 147.666L132.111 163.064L132.117 163.059C134.85 165.671 141.864 163.114 147.904 157.27C153.973 151.396 156.771 144.416 154.205 141.607L154.208 141.604L154.153 141.547C154.153 141.547 154.153 141.547 154.152 141.546L148.819 136.018C150.946 129.455 154.723 120.772 157.218 113.245C158.777 108.541 153.522 105.177 149.911 107.695Z"
      fill="#A25F45"
    />
    <path
      d="M139.749 123.046C133.364 122.65 127.015 126.242 124.025 131.89C123.467 132.944 125.067 133.883 125.626 132.826C128.332 127.714 133.975 124.543 139.749 124.901C140.941 124.975 140.937 123.12 139.749 123.046Z"
      fill="#8A3E28"
    />
    <path
      d="M132.987 107.819C134.18 107.884 134.176 106.029 132.987 105.964C132.012 105.911 131.037 105.892 130.062 105.881C129.704 98.3483 129.695 90.8025 129.433 83.2662C129.392 82.0761 127.537 82.0706 127.579 83.2662C127.84 90.8064 127.85 98.3561 128.208 105.893C125.162 105.942 122.124 106.184 119.119 106.644C118.637 98.8794 118.554 91.0949 118.259 83.3224C118.214 82.1327 116.359 82.1268 116.405 83.3224C116.701 91.1401 116.782 98.97 117.271 106.779C117.275 106.848 117.304 106.899 117.319 106.959C114.401 107.486 111.515 108.19 108.685 109.1C108.448 104.771 108.031 100.454 107.822 96.1231C107.764 94.935 105.909 94.9281 105.967 96.1231C106.187 100.665 106.64 105.19 106.868 109.731C106.273 109.941 105.674 110.138 105.085 110.365C103.983 110.79 104.462 112.584 105.578 112.153C114.319 108.782 123.632 107.305 132.987 107.819Z"
      fill="#8A3E28"
    />
  </svg>
);
