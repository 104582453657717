export const slugifyCompany = (
  companyProfileName: string,
  companyProfileId: string,
) => slugify(`${companyProfileName}-${companyProfileId}`);

// This logic should kept the same as the logic in bx-backends
/**
 * Logic been copied from: https://github.com/SEEK-Jobs/cpp-helpers/blob/master/lib/general.js#L77
 * param: `value` string to be slugified
 * returns: slugified string
 */
export const slugify = (value: string) =>
  value
    .normalize('NFKD')
    .trim()
    .toLowerCase()
    .replace(/&/g, 'n')
    .replace(/coltd/g, 'co-ltd')
    .replace(/(?:\.com|\.co|\b(?:ltda|sdn|bhd|sa)\b)|[^a-zA-Z0-9/_|+ -]/g, '')
    .replace(/[/_|+ -]+/g, '-')
    .replace(/[ -]+$/, '')
    .replace(/^[ -]+/, '');

export const validateAndParseCompanySlugUrl = (
  url: string | null,
  ignorePaths?: string[],
) => {
  if (!url) {
    return null;
  }
  // using URL so was can take advantage of its parsing capabilities
  const parsedUrl = new URL(url, 'http://dummy');

  // This regex does just checks on the path not the query params
  const regex =
    /^(?:\/(en|id))?\/(companies)\/([^/]+)(?:\/(reviews|jobs|culture))?(?:\?[^/]*)?\/?$/;
  const match = parsedUrl.pathname.match(regex);
  if (!match) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_validUrl, language, version, urlCompanySlug, finalPath] = match;

  if (ignorePaths?.includes(urlCompanySlug)) {
    return null;
  }

  let childPath = getSearchParams(
    parsedUrl.searchParams,
    SEARCH_PARAMS_WHITELIST,
  );

  if (finalPath) {
    childPath = childPath ? `${finalPath}${childPath}` : finalPath;
  }

  return {
    language: language ? language : undefined,
    version,
    urlCompanySlug,
    childPath,
  };
};

export const SEARCH_PARAMS_WHITELIST = [
  'draft',
  'utm_campaign',
  'utm_content',
  'utm_source',
];

export const getSearchParams = (
  searchParams: URLSearchParams,
  whitelist: string[],
) => {
  let childPath: string | undefined;

  whitelist.forEach((param) => {
    const value = searchParams.get(param);

    if (value !== null) {
      const encoded = encodeURIComponent(value);
      childPath = childPath
        ? `${childPath}&${param}=${encoded}`
        : `?${param}=${encoded}`;
    }
  });

  return childPath;
};

export const generateCompanySlugUrl = ({
  language,
  version,
  companySlug,
  childPath,
}: {
  language: string | undefined;
  version: string;
  companySlug: string;
  childPath: string | undefined;
}): string => {
  const languagePath = language ? `/${language}` : '';
  const childPathWithSlash = childPath ? `/${childPath}` : '';
  return `${languagePath}/${version}/${companySlug}${childPathWithSlash}`;
};
