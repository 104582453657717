import {
  type ApolloClient,
  type NormalizedCacheObject,
  gql,
} from '@apollo/client';
import type { AppConfig } from '../../config';
import type {
  MkLocationSuggestion,
  QueryMkLocationSuggestionsArgs,
} from '@seek/ca-graphql-schema/types';

const LOCATION_SEARCH_QUERY = gql`
  query MKLocationSuggestions(
    $searchText: String!
    $session: String!
    $kind: [String!]!
    $zone: Zone!
    $languageCode: LanguageCodeIso
  ) {
    mkLocationSuggestions(
      searchText: $searchText
      session: $session
      kind: $kind
      zone: $zone
      languageCode: $languageCode
    ) {
      text
      id
      countryCode
    }
  }
`;

export type LocationSuggestion = Partial<MkLocationSuggestion>;

export type LocationService = ReturnType<typeof createLocationService>;

export const createLocationService = ({
  client,
  config,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  config: AppConfig;
}) => {
  const getLocations = async (
    query: string,
    sessionId: string,
  ): Promise<LocationSuggestion[]> => {
    const LOCATIONS_TO_SUGGEST = 10;

    // Different location kinds for different zones
    const locationKindDefault = ['Suburb', 'SEEKLocation'];
    const locationKindForJobsDB = ['Suburb', 'SEEKArea'];

    const response = await client.query<
      { mkLocationSuggestions: MkLocationSuggestion[] },
      QueryMkLocationSuggestionsArgs
    >({
      query: LOCATION_SEARCH_QUERY,
      variables: {
        searchText: query,
        session: sessionId,
        count: LOCATIONS_TO_SUGGEST,
        kind:
          config.zone === 'asia-1' || config.zone === 'asia-3'
            ? locationKindForJobsDB
            : locationKindDefault,
        zone: config.zone,
        languageCode: config.language,
      },
    });

    return response.data?.mkLocationSuggestions ?? [];
  };

  return { getLocations };
};
