import { useRouteLoaderData } from 'react-router';
import { useService } from '../../../';
import { PageLoader, type Jobs } from '@seek/libs-shared';

import { CompanyProfilesPage } from '../../../paths';
import type { JobsModel } from './jobsModel';
import { JobsPage } from '../../pages/jobs/JobsPage';

export function JobsPageContainer({
  trackJobsPaginationClicked,
}: {
  trackJobsPaginationClicked: ({
    searchTotalCount,
    pageNumber,
  }: {
    searchTotalCount?: number | undefined;
    pageNumber: number;
  }) => void;
}) {
  const model = useRouteLoaderData(CompanyProfilesPage.Jobs) as JobsModel;

  const { companyProfileService } = useService();

  const getPaginatedJobs = async (
    companyId: string,
    page?: number,
  ): Promise<Jobs> => {
    const res = await companyProfileService.getJobs(companyId, page);
    return res;
  };

  return model ? (
    <JobsPage
      model={model}
      getPaginatedJobs={getPaginatedJobs}
      trackJobsPaginationClicked={trackJobsPaginationClicked}
    />
  ) : (
    <PageLoader />
  );
}
