import type { Country } from '@seek/melways-sites';

export const getLanguageSwitcher = (path: string, country: Country) => {
  const pagePath = path.replace(/(\/(th|id))/g, '');

  switch (country) {
    case 'id':
      return {
        en: { url: pagePath },
        id: { url: `/id${pagePath}` },
      };
    case 'th':
      return {
        en: { url: pagePath },
        th: { url: `/th${pagePath}` },
      };
  }

  return {};
};
