import { Inline, Stack, Strong, Text } from 'braid-design-system';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import {
  StarSelector,
  type Rating,
} from '../../components/StarSelector/StarSelector';
import type { QuestionProps } from '../../type';

type RatingQuestionProps = QuestionProps<Rating> & {
  title: string;
  isRequired?: boolean;
};

export const RatingQuestion = ({
  title,
  value,
  message = '',
  isRequired = false,
  onChange,
}: RatingQuestionProps) => {
  const { t } = useTranslations(translations);
  return (
    <Stack space="small">
      <Inline space="none">
        <Text>
          <Strong>{title}</Strong>
        </Text>
        {!isRequired && <Text tone="secondary">({t('Optional')})</Text>}
      </Inline>
      <StarSelector
        onSelected={(value) => onChange(value)}
        value={value}
        message={message}
      />
    </Stack>
  );
};
