import { Autosuggest } from 'braid-design-system';
import type { QuestionProps } from '../../type';
import { useEffect, useState } from 'react';
import type { RoleTitle } from '../../../shared/services/roleService/roleService';
import { useDebounce } from 'use-debounce';

type JobTitleQuestionProps = QuestionProps & {
  getRoleTitles: (query: string) => Promise<RoleTitle[]>;
};

interface AutosuggestValue<T> {
  text: string;
  description?: string;
  value?: T;
}

export const JobTitleQuestion = ({
  getRoleTitles,
  onChange,
  message,
  tone,
  value,
}: JobTitleQuestionProps) => {
  const [suggestions, setSuggestions] = useState<AutosuggestValue<string>[]>(
    [],
  );
  const [searchTerm, setSearchTerm] = useState<AutosuggestValue<string>>({
    text: value || '',
  });
  const [debouncedSearchTerm] = useDebounce(searchTerm, 400);
  const MINIMUM_CHARACTERS_SEARCH = 3;

  const handleOnChangeAutosuggest = (event: AutosuggestValue<string>) => {
    setSearchTerm(event);
    setSuggestions([]);
    if (event.value) {
      onChange(event.value);
    } else if (value) {
      onChange('');
    }
  };

  useEffect(() => {
    if (
      !searchTerm.value &&
      searchTerm.text &&
      searchTerm.text.length >= MINIMUM_CHARACTERS_SEARCH
    ) {
      getRoleTitles(debouncedSearchTerm.text).then((res) => {
        setSuggestions(
          res.map((role) => ({
            text: role.normalisedText,
            value: role.normalisedText,
          })),
        );
      });
    }
  }, [debouncedSearchTerm]);

  return (
    <Autosuggest
      id="jobTitle"
      label="Job title"
      value={searchTerm}
      onChange={handleOnChangeAutosuggest}
      onClear={() => {
        setSearchTerm({ text: '' });
        setSuggestions([]);
        onChange('');
      }}
      suggestions={suggestions}
      tone={tone}
      message={message}
      reserveMessageSpace
    />
  );
};
