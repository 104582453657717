import {
  Box,
  Button,
  ButtonIcon,
  Card,
  Column,
  Columns,
  Divider,
  Heading,
  IconFlag,
  IconThumb,
  Inline,
  Loader,
  Stack,
  Text,
  TextLink,
} from 'braid-design-system';
import { absoluteDateFormatter } from '@seek/unified-display-date-time';
import { AUTHENTICATION_TYPES } from '@seek/apac-candidate-header-footer/types';

import {
  type ReviewRowModel,
  fromNow,
  useConfig,
  ReviewRowRating,
} from '@seek/libs-shared';
import { CompanyProfilesPage, type FlagReviewPayload } from '../../../../';
import translations from './.vocab';
import { useTranslations } from '@vocab/react';
import { useState } from 'react';
import { ReportReviewForm } from '../reportReviewForm/ReportReviewForm';
import { ReportConfirmedDialog } from '../reportConfirmedDialog/ReportConfirmedDialog';
import { useAuth } from '../../../../shared/providers/authProvider';

interface Props {
  model: ReviewRowModel;
  upvoteReview: ({
    reviewId,
    companyId,
  }: {
    reviewId: string;
    companyId: string;
  }) => Promise<void>;
  flagReview: (
    companyId: string,
    reviewId: string,
    payload: FlagReviewPayload,
  ) => Promise<boolean>;
  upvotedReviewsIds?: string[];
  newUpvotedReviewsIds?: string[];
  isOnReviewPage?: boolean;
  isReplyReviewAccessCodeValid?: boolean;
  index: number;
}

export const ReviewRow = ({
  model,
  upvoteReview,
  flagReview,
  isOnReviewPage = false,
  upvotedReviewsIds = [],
  newUpvotedReviewsIds = [],
  isReplyReviewAccessCodeValid = false,
  index,
}: Props) => {
  const { t } = useTranslations(translations);
  const { language } = useConfig();
  const { authenticationStatus, ssoLogin } = useAuth();
  const [showReportForm, setShowReportForm] = useState(false);
  const [showReportConfirmedDialog, setShowReportConfirmedDialog] =
    useState(false);

  const displayThankYouBox = () => {
    setShowReportForm(false);
    setShowReportConfirmedDialog(true);
  };

  const closeReportForm = () => {
    setShowReportForm(false);
  };

  const closeReportConfirmedDialog = () => {
    setShowReportConfirmedDialog(false);
  };

  if (!model) {
    return <Loader />;
  }

  const {
    companyId,
    createdAt,
    employmentStatus,
    id,
    jobTitle,
    title,
    workLocation,
    pros,
    cons,
    upvotes,
    reply,
  } = model;

  const reviewLinkUrlPath = `/companies/${companyId}/${CompanyProfilesPage.Reviews}/${id}`;
  const unifiedCreatedAt = absoluteDateFormatter({
    date: new Date(createdAt),
    format: 'shortMonthYear',
  });

  const onReviewLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
    // todo
    // history.push(reviewLinkUrlPath, {
    //   shouldJumpToTop: true,
    // });
  };

  const onFlagClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.currentTarget.blur();
    // if unathenticated, redirect to login page
    if (
      authenticationStatus === AUTHENTICATION_TYPES.UNAUTHENTICATED &&
      typeof ssoLogin === 'function'
    ) {
      ssoLogin();
    } else {
      setShowReportForm(true);
    }
  };

  const showUpvoteIcon = !isOnReviewPage && typeof upvoteReview === 'function';
  const upvoteCount =
    (upvotes?.count || 0) + (newUpvotedReviewsIds?.includes(id) ? 1 : 0);

  return (
    <Box id={`review-card-${index}`}>
      <Card>
        <Stack space="large">
          <Columns space="medium" collapseBelow="tablet">
            <Column width="1/4">
              <Stack space="medium">
                <Stack space="small">
                  {model && (
                    <ReviewRowRating
                      model={model}
                      showBreakdown={!isOnReviewPage}
                    />
                  )}
                  <Box display="inlineBlock" width="full">
                    <Text size="small" weight="strong">
                      {jobTitle}
                    </Text>
                  </Box>
                  <Text tone="secondary" size="small">
                    {unifiedCreatedAt.isValid
                      ? unifiedCreatedAt.formattedDate
                      : ''}
                  </Text>
                </Stack>
                <Stack space="xsmall">
                  <Text size="small" tone="neutral">
                    {workLocation}
                  </Text>
                  <Text size="small" tone="neutral">
                    {employmentStatus}
                  </Text>
                </Stack>
              </Stack>
            </Column>
            <Column width="3/4">
              <Stack space="large">
                <Heading level="4">
                  {isReplyReviewAccessCodeValid && !isOnReviewPage ? (
                    <TextLink
                      href={reviewLinkUrlPath}
                      onClick={onReviewLinkClick}
                    >
                      {title}
                    </TextLink>
                  ) : (
                    <>{title}</>
                  )}
                </Heading>
                <Stack space="medium">
                  <Text size="standard" weight="strong">
                    {t('The good things')}
                  </Text>
                  <Text>{pros}</Text>
                </Stack>
                <Stack space="medium">
                  <Text size="standard" weight="strong">
                    {t('The challenges')}
                  </Text>
                  <Text>{cons}</Text>
                </Stack>
                <Columns space="none" alignY="top">
                  <Column>
                    {showUpvoteIcon && (
                      <Inline
                        space="small"
                        alignY="center"
                        data={{ testid: `upvote-${id}` }}
                      >
                        <Button
                          variant={
                            upvotedReviewsIds?.includes(id) ? 'solid' : 'soft'
                          }
                          size="small"
                          onClick={() => {
                            if (upvotedReviewsIds?.includes(id)) return;
                            upvoteReview({ reviewId: id, companyId });
                          }}
                        >
                          <IconThumb /> {t('Helpful?')}
                        </Button>
                        {upvoteCount ? (
                          <Text size="small" tone="neutral">
                            {upvoteCount >= 1 &&
                              `${
                                upvoteCount === 1
                                  ? t('1 person')
                                  : `${upvoteCount} ${t('people')}`
                              } ${t('found this helpful')}`}
                          </Text>
                        ) : null}
                      </Inline>
                    )}
                  </Column>
                  <Column width="content">
                    <ButtonIcon
                      onClick={(e) => {
                        onFlagClick(e);
                      }}
                      icon={<IconFlag />}
                      id={`Flag review ${index}`}
                      data={{ testid: `flag-review-${id}` }}
                      label={t('Report this review')}
                    />
                  </Column>
                </Columns>
                {reply && (
                  <>
                    <Divider />
                    <Box paddingY="small">
                      <Columns space="gutter" collapseBelow="tablet">
                        <Column width="content">
                          <Box style={{ maxWidth: '80px' }}>
                            <img src={reply?.companyLogoUrl} width="100%" />
                          </Box>
                        </Column>
                        <Column>
                          <Stack space="small">
                            <Text weight="strong">{reply?.from}</Text>
                            <Text>{reply?.text}</Text>
                          </Stack>
                        </Column>
                        <Column width="content">
                          <Text tone="secondary" size="small">
                            {fromNow(reply?.createdAt, language)}
                          </Text>
                        </Column>
                      </Columns>
                    </Box>
                  </>
                )}
              </Stack>
            </Column>
          </Columns>
        </Stack>

        <ReportReviewForm
          model={{ companyId, reviewId: id }}
          displayThankYouBox={displayThankYouBox}
          onClose={closeReportForm}
          isOpen={showReportForm}
          flagReview={flagReview}
        />
        <ReportConfirmedDialog
          isOpen={showReportConfirmedDialog}
          onClose={closeReportConfirmedDialog}
        />
      </Card>
    </Box>
  );
};
