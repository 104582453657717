import { useTranslations } from '@vocab/react';
import { Alert, Text } from 'braid-design-system';
import translations from './.vocab';

export interface Props {
  onClose: () => void;
  text: string;
}

export const UpdateSuccessAlert = ({ onClose, text }: Props) => {
  const { t } = useTranslations(translations);

  return (
    <Alert tone="positive" onClose={onClose} closeLabel={t('Close info alert')}>
      <Text>{text}</Text>
    </Alert>
  );
};
