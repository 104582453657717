import {
  type ApolloClient,
  ApolloProvider,
  type NormalizedCacheObject,
} from '@apollo/client';
import {
  type Logger,
  LoggerProvider,
  ConfigProvider,
  AppContextProvider,
} from '@seek/libs-shared';
import { VocabProvider } from '@vocab/react';
import { BraidProvider } from 'braid-design-system';
import { MelwaysProvider } from '@seek/melways-react';
import theme from 'braid-design-system/themes/seekJobs';
import { Outlet } from 'react-router';

import { CustomLink } from './shared/components/CustomLink';
import {
  AuthProvider,
  type AuthContextProps,
} from './shared/providers/authProvider';
import { ServiceProvider } from './shared/providers/serviceProvider';
import type { Services } from './shared/services';
import type { AppConfig } from './shared/config';
import { FeatureFlagProvider } from './shared/providers/featureFlagProvider';
import { AUTHENTICATION_TYPES } from '@seek/apac-candidate-header-footer/types';

interface AppProps {
  client: ApolloClient<NormalizedCacheObject>;
  config: AppConfig;
  services: Services;
  logger: Logger;
}

export const App = ({ client, config, services, logger }: AppProps) => {
  let auth: AuthContextProps = undefined;
  if (config.environment === 'local') {
    auth = {
      authenticationStatus: AUTHENTICATION_TYPES.AUTHENTICATED,
      userProfile: {
        firstName: 'John',
        lastName: 'Doe',
        email: 'test@example.com',
      },
      userId: 123,
    };
  }
  return (
    <ConfigProvider config={config}>
      <MelwaysProvider.Server
        site={config.siteName as any}
        url={config.absoluteUrl}
        alternative={config.consolidated ? 'subDomainRegion' : undefined}
        staging={
          config.environment === 'staging' ||
          config.environment === 'development'
        }
      >
        <LoggerProvider logger={logger}>
          <ApolloProvider client={client}>
            <ServiceProvider services={services}>
              <AppContextProvider>
                <AuthProvider auth={auth}>
                  <FeatureFlagProvider>
                    <BraidProvider theme={theme} linkComponent={CustomLink}>
                      <VocabProvider
                        language={config.language}
                        locale={config.locale}
                      >
                        <Outlet context={{ client, config }} />
                      </VocabProvider>
                    </BraidProvider>
                  </FeatureFlagProvider>
                </AuthProvider>
              </AppContextProvider>
            </ServiceProvider>
          </ApolloProvider>
        </LoggerProvider>
      </MelwaysProvider.Server>
    </ConfigProvider>
  );
};
